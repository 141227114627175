import i18n from 'i18next'
import { IGroup, IWidget } from 'types/adminInterfaces'
import { IAllocation, IAllocationTranslation } from 'types/allocationInterfaces'
import {
  ICertificate,
  ICertificateTranslation,
  IContactInformation,
  ICourse,
  ICourseTranslation,
  ICv,
  ICvCopyTranslation,
  ICvTranslation,
  IEducation,
  IEducationTranslation,
  IEmployer,
  IEmployerTranslation,
  ILanguageSkill,
  IPersonSkillOrIndustryOrRole,
  IProject,
  IProjectTranslation,
  IReference,
  IReferenceTranslation,
  IUrl,
  IUrlTranslation,
} from 'types/cvsInterfaces'
import {
  INetwork,
  INetworkTranslation,
  INetworkRegister,
  INetworkRegisterTranslation,
  IUser,
  INetworkNotification,
} from 'types/networkInterfaces'
import { ITeam, ITeamTranslation } from 'types/teamInterfaces'
import { OrganizationId } from 'types/ids'
import { IAccount, IOrganization, IOrganizationTranslation, IPerson, ISite } from 'types/userInterfaces'
import { createTranslation, chooseDBTranslation } from 'utils/translations'
import { NewOrExisting } from '.'
import colors from 'constants/colors'
import {
  IAssignmentTranslation,
  IAssignment,
  IAssignmentRoleSkillRequirement,
  IAssignmentRoleLanguageRequirement,
  IAssignmentRoleTranslation,
  IAssignmentRole,
  IAssignmentRoleProposal,
  IAssignmentRoleProposalTranslation,
  IRoleQuestionnaire,
  IRoleQuestion,
  IWorkOrderResponse,
} from 'types/assignmentInterfaces'
import { IUserManualPage, IUserManualPageTranslation } from 'types/userManualInterfaces'
import { IAssignmentProposal } from 'Components/General/AssignmentProposalModal/AssignmentProposalModal'
import {
  IChangePassword,
  IForgotPassword,
  ILoginAccount,
  IResetPassword,
  IVerifyAccount,
} from 'types/accountInterfaces'
import { formatStartDate } from 'utils/utils'
import { IParseSetup } from 'types/cvParserInterfaces'
import { IReleaseNoteTranslation } from 'types/releaseNoteInterfaces'
import { ISystemConfig } from 'types/systemConfigInterfaces'
import { addWeeks } from 'date-fns'

/**
 * Project initial data.
 *
 * @param cvId CV ID.
 * @param project Project object.
 * @returns Initial data.
 * @notExported
 */
export const getProjectInitialData = async (cvId, project?: IProject) => {
  if (project) {
    return {
      cvId: project.cvId,

      startDate: project.startDate,
      endDate: null,
      dateResolution: 'day',

      translations: [
        await createTranslation<IProjectTranslation, 'projectId'>(i18n.language, {
          name: chooseDBTranslation(i18n, project).name,
          description: chooseDBTranslation(i18n, project).description,
          customerName: chooseDBTranslation(i18n, project).customerName,
          employerName: chooseDBTranslation(i18n, project).employerName,
          primaryRole: chooseDBTranslation(i18n, project).primaryRole,
        }),
      ],
      PersonSkills: [],
      ProjectRoles: [],

      createdAt: new Date(),
      updatedAt: new Date(),
    } as NewOrExisting<IProject>
  }
  return {
    cvId: cvId,

    startDate: formatStartDate(new Date()),
    endDate: null,
    dateResolution: 'day',

    translations: [
      await createTranslation<IProjectTranslation, 'projectId'>(i18n.language, {
        name: '',
        description: '',
        customerName: '',
        employerName: '',
        primaryRole: '',
      }),
    ],
    PersonSkills: [],
    ProjectRoles: [],

    createdAt: new Date(),
    updatedAt: new Date(),
  } as NewOrExisting<IProject>
}

/**
 * Skill initial data.
 *
 * @param cvId CV ID.
 * @returns Initial data.
 * @notExported
 */
export const getSkillsInitialData = cvId => {
  return {
    cvId: cvId,
    experienceMonths: 1,
    lastUsed: new Date().getFullYear(),
    skillId: null,
    Skill: null,
    level: 3,
    interestLevel: 3,
    createdAt: new Date(),
    updatedAt: new Date(),
  } as unknown as NewOrExisting<IPersonSkillOrIndustryOrRole>
}

/**
 * Employer initial data.
 *
 * @param cvId CV ID.
 * @param employer Employer object.
 * @returns Initial data.
 * @notExported
 */
export const getEmployerInitialData = async (cvId, employer?: IEmployer) => {
  if (employer) {
    return {
      cvId: cvId,

      startDate: employer.startDate,
      endDate: null,

      dateResolution: 'day',

      translations: [
        await createTranslation<IEmployerTranslation, 'employerId'>(i18n.language, {
          name: chooseDBTranslation(i18n, employer).name,
          primaryRole: chooseDBTranslation(i18n, employer).primaryRole,
          description: chooseDBTranslation(i18n, employer).description,
        }),
      ],

      EmployerRoles: [],

      createdAt: new Date(),
      updatedAt: new Date(),
    } as unknown as NewOrExisting<IEmployer>
  }
  return {
    cvId: cvId,

    startDate: formatStartDate(new Date()),
    endDate: null,

    dateResolution: 'day',

    translations: [
      await createTranslation<IEmployerTranslation, 'employerId'>(i18n.language, {
        name: '',
        primaryRole: '',
        description: '',
      }),
    ],

    EmployerRoles: [],

    createdAt: new Date(),
    updatedAt: new Date(),
  } as unknown as NewOrExisting<IEmployer>
}

/**
 * Education initial data.
 *
 * @param cvId CV ID.
 * @param education Education object.
 * @returns Initial data.
 * @notExported
 */
export const getEducationInitialData = async (cvId: number, education?: IEducation) => {
  if (education) {
    return {
      cvId: cvId,
      startDate: formatStartDate(new Date()),
      endDate: education.endDate,
      dateResolution: 'day',
      translations: [
        await createTranslation<IEducationTranslation, 'educationId'>(i18n.language, {
          degree: chooseDBTranslation(i18n, education).degree,
          school: chooseDBTranslation(i18n, education).school,
          description: '',
        }),
      ],
      createdAt: new Date(),
      updatedAt: new Date(),
    } as NewOrExisting<IEducation>
  }
  return {
    cvId: cvId,
    startDate: formatStartDate(new Date()),
    endDate: null,
    dateResolution: 'day',
    translations: [
      await createTranslation<IEducationTranslation, 'educationId'>(i18n.language, {
        degree: '',
        school: '',
        description: '',
      }),
    ],
    createdAt: new Date(),
    updatedAt: new Date(),
  } as NewOrExisting<IEducation>
}

/**
 * Certificate initial data.
 *
 * @param cvId CV ID.
 * @param certificate Certificate object.
 * @returns Initial data.
 * @notExported
 */
export const getCertificatesInitialData = async (cvId: number, certificate?: ICertificate) => {
  if (certificate) {
    return {
      cvId: cvId,
      licenceNumber: '',
      url: '',
      issueDate: new Date(),
      endDate: certificate.endDate,
      dateResolution: 'day',
      translations: [
        await createTranslation<ICertificateTranslation, 'certificateId'>(i18n.language, {
          certificate: chooseDBTranslation(i18n, certificate).certificate,
          certifier: chooseDBTranslation(i18n, certificate).certifier,
          description: '',
        }),
      ],
      createdAt: new Date(),
      updatedAt: new Date(),
    } as NewOrExisting<ICertificate>
  }
  return {
    cvId: cvId,
    licenceNumber: '',
    url: '',
    issueDate: new Date(),
    endDate: null,
    dateResolution: 'day',
    translations: [
      await createTranslation<ICertificateTranslation, 'certificateId'>(i18n.language, {
        certificate: '',
        certifier: '',
        description: '',
      }),
    ],
    createdAt: new Date(),
    updatedAt: new Date(),
  } as NewOrExisting<ICertificate>
}

/**
 * Course initial data.
 *
 * @param cvId CV ID.
 * @param course Course object.
 * @returns Initial data.
 * @notExported
 */
export const getCourseInitialData = async (cvId: number, course?: ICourse) => {
  if (course) {
    return {
      cvId: cvId,
      startDate: formatStartDate(new Date()),
      endDate: course.endDate,
      dateResolution: 'day',
      translations: [
        await createTranslation<ICourseTranslation, 'courseId'>(i18n.language, {
          course: chooseDBTranslation(i18n, course).course,
          organizer: chooseDBTranslation(i18n, course).organizer,
          description: '',
        }),
      ],
      createdAt: new Date(),
      updatedAt: new Date(),
    } as NewOrExisting<ICourse>
  }
  return {
    cvId: cvId,
    startDate: formatStartDate(new Date()),
    endDate: null,
    dateResolution: 'day',
    translations: [
      await createTranslation<ICourseTranslation, 'courseId'>(i18n.language, {
        course: '',
        organizer: '',
        description: '',
      }),
    ],
    createdAt: new Date(),
    updatedAt: new Date(),
  } as NewOrExisting<ICourse>
}

/**
 * Language initial data.
 *
 * @param cvId CV ID.
 * @returns Initial data.
 * @notExported
 */
export const getLanguageInitialData = cvId => {
  return {
    languageCode: null,
    level: 1,
    cvId: cvId,

    createdAt: new Date(),
    updatedAt: new Date(),
  } as unknown as NewOrExisting<ILanguageSkill>
}

/**
 * Reference initial data.
 *
 * @param cvId CV ID.
 * @returns Initial data.
 * @notExported
 */
export const getReferenceInitialData = async cvId => {
  return {
    cvId: cvId,
    firstName: '',
    lastName: '',
    company: '',
    translations: [
      await createTranslation<IReferenceTranslation, 'referenceId'>(i18n.language, {
        letter: '',
      }),
    ],
    createdAt: new Date(),
    updatedAt: new Date(),
  } as NewOrExisting<IReference>
}

/**
 * Allocation initial data.
 *
 * @param personal Whether the allocation is personal or project.
 * @returns Initial data.
 * @notExported
 */
export const getAllocationInitialData = async personal => {
  return {
    assignmentId: null,
    roleId: null,
    percent: '',
    type: personal ? 'personal' : 'project',
    state: 1,
    startDate: null,
    endDate: null,
    translations: [
      await createTranslation<IAllocationTranslation, 'allocationId'>(i18n.language, {
        information: '',
      }),
    ],
    createdAt: new Date(),
    updatedAt: new Date(),
  } as unknown as NewOrExisting<IAllocation>
}

/**
 * Admin config initial data
 *
 * @returns Initial data.
 * @notExported
 */
export const getAdminConfigInitialData = async () => {
  return {
    key: '',
    value: null,
  } as unknown as NewOrExisting<ISystemConfig>
}

/**
 * Admin employee initial data.
 *
 * @param initialOrganization Organization object.
 * @returns Initial data.
 * @notExported
 */
export const getAdminEmployeeInitialData = async (initialOrganization: IOrganization) => {
  return {
    email: '',
    Person: {
      isDefaultCv: true,
      organizationId: initialOrganization?.id,
      Organization: initialOrganization,
      siteId: null,
      Site: null,
      manager: null,
      firstName: null,
      lastName: null,
      visibility: 'network',
      telephone: '',
    } as unknown as NewOrExisting<IPerson>,
    translations: [
      await createTranslation<ICvTranslation, 'cvId'>(i18n.language, {
        about: '',
        name: 'CV',
        primaryRole: '',
      }),
    ],
    GroupMembers: [],
    notifications: false,
    language: initialOrganization ? initialOrganization.defaultLanguage : 'en',
    caleoOwner: false,
  } as unknown as NewOrExisting<IAccount>
}

/**
 * Organization initial data.
 *
 * @returns Initial data.
 * @notExported
 */
export const getAdminOrganizationInitialData = async () => {
  return {
    companyIdentifier: '',
    personnel: 0,
    turnover: 0,
    parseCredits: 10,
    defaultLanguage: 'en',
    color: colors.primary,
    accentColor: colors.secondary,
    contactEachExpert: false,
    translations: [
      await createTranslation<IOrganizationTranslation, 'organizationId'>(i18n.language, {
        description: '',
        name: '',
      }),
    ],
    createdAt: new Date(),
    updatedAt: new Date(),
    allowCreateNetworks: false,
  } as unknown as NewOrExisting<IOrganization>
}

/**
 * Site initial data.
 *
 * @returns Initial data.
 * @notExported
 */
export const getSiteInitialData = async () => {
  return {
    postalCode: '',
    countryCode: 'fi',
    isHq: false,
    streetAddress: '',
    city: '',
  } as unknown as ISite
}

/**
 * Group initial data.
 *
 * @param types Group types.
 * @returns Initial data.
 * @notExported
 */
export const getGroupInitialData = async types => {
  return {
    name: null,
    description: null,
    type: types[0].value,
  } as unknown as NewOrExisting<IGroup>
}

/**
 * Role initial data.
 *
 * @param orgId Organization ID.
 * @returns Initial data.
 * @notExported
 */
export const getTeamInitialData = async orgId => {
  return {
    organizationId: orgId as OrganizationId,
    translations: [
      await createTranslation<ITeamTranslation, 'teamId'>(i18n.language, {
        name: '',
        description: '',
      }),
    ],
    TeamMembers: [],
    limitAssignments: false,
  } as unknown as NewOrExisting<ITeam>
}

/**
 * Network initial data.
 *
 * @returns Initial data.
 * @notExported
 */
export const getNetworkInitialData = async () => {
  return {
    id: null,
    type: 'private',
    autoGenerated: false,
    assignmentsVisible: false,
    salesContactsVisible: false,
    pricesVisible: false,
    infoPageVisible: false,
    parserEnabled: false,
    chatEnabled: false,
    unsubNotificationEmail: '',
    assignmentCopyEmail: '',
    NetworkMembers: [],
    AccountMembers: [],
    NetworkBrokers: [],
    NetworkManagers: [],
    Owner: null,
    translations: [
      await createTranslation<INetworkTranslation, 'networkId'>(i18n.language, {
        name: '',
        description: '',
        infoPage: '',
        proposalGuide: '',
      }),
    ],

    createdAt: new Date(),
    updatedAt: new Date(),
  } as unknown as NewOrExisting<INetwork>
}

/**
 * Network notification initial data.
 *
 * @param translations Network translations.
 * @returns Initial data.
 * @notExported
 */
export const getNetworkNotificationInitialData = async (translations: INetworkTranslation[]) => {
  const headerFields = {}
  const messageFields = {}

  for (const tr of translations) {
    headerFields[`header${tr.Language.name}`] = ''
    messageFields[`message${tr.Language.name}`] = ''
  }

  return {
    salesOnly: false,
    skillBased: false,
    skills: [],
    header: {
      ...headerFields,
    },
    message: {
      ...messageFields,
    },
  } as unknown as NewOrExisting<INetworkNotification>
}

/**
 * Assignment initial data.
 *
 * @returns Initial data.
 * @notExported
 */
export const getAssignmentsInitialData = async () => {
  return {
    startDate: formatStartDate(new Date()),
    endDate: null,
    workloadEstimate: 0,
    deadline: addWeeks(new Date(), 1),
    remoteAllowed: false,
    subcontractorAllowed: false,
    fileProposalAllowed: true,
    publicView: true,
    AssignmentVisibilities: [],
    AssignmentContactPeople: [],
    teamId: null,
    ownerId: null as unknown,
    Owner: null as unknown,
    contactPersonId: null,
    Person: null,
    roles: [],
    attachments: [],
    translations: [
      await createTranslation<IAssignmentTranslation, 'assignmentId'>(i18n.language, {
        name: '',
        description: '',
        additionalInfo: '',
        country: 'fi',
        city: '',
        customer: '',
      }),
    ],
    createdAt: new Date(),
    updatedAt: new Date(),
  } as unknown as NewOrExisting<IAssignment>
}

/**
 * User manual page initial data.
 *
 * @returns Initial data.
 * @notExported
 */
export const getUserManualPageInitialData = async () => {
  return {
    id: null,
    url: '',
    translations: [
      await createTranslation<IUserManualPageTranslation, 'userManualPageId'>(i18n.language, {
        title: '',
        content: '',
      }),
    ],

    createdAt: new Date(),
    updatedAt: new Date(),
  } as unknown as NewOrExisting<IUserManualPage>
}

/**
 * Layout initial data.
 *
 * @returns Initial data.
 * @notExported
 */
export const getLayoutInitialData = () => {
  return {
    name: '',
  } as unknown as NewOrExisting<ILanguageSkill>
}

/**
 * Url initial data.
 *
 * @param cvId CV ID.
 * @returns Initial data.
 * @notExported
 */
export const getCvUrlInitialData = async cvId => {
  return {
    id: null,
    cvId: cvId,
    url: 'https://',
    translations: [
      await createTranslation<IUrlTranslation, 'urlId'>(i18n.language, {
        description: '',
      }),
    ],

    createdAt: new Date(),
    updatedAt: new Date(),
  } as unknown as NewOrExisting<IUrl>
}

/**
 * Assignment role initial data.
 *
 * @returns Initial data.
 * @notExported
 */
export const getAssignmentRoleInitialData = async () => {
  return {
    allocationPercentage: 100,
    hourlyPrice: null as unknown,
    AssignmentRoleSkillRequirements: [] as IAssignmentRoleSkillRequirement[],
    AssignmentRoleLanguageRequirements: [] as IAssignmentRoleLanguageRequirement[],
    RoleQuestionnaire: {
      questions: [] as IRoleQuestion[],
      required: false,
    } as IRoleQuestionnaire,
    translations: [
      await createTranslation<IAssignmentRoleTranslation, 'assignmentRoleId'>(i18n.language, {
        name: '',
        description: '',
      }),
    ],
    WorkOrderResponses: [] as IWorkOrderResponse[],
  } as IAssignmentRole
}

/**
 * Cv copy initial data.
 *
 * @param cv CV object.
 * @returns Initial data.
 * @notExported
 */
export const getCvCopyInitialData = async cv => {
  return {
    id: cv.id,
    translations: [
      await createTranslation<ICvCopyTranslation, 'cvId'>(i18n.language, {
        name: '',
        description: '',
      }),
    ],
    createdAt: new Date(),
    updatedAt: new Date(),
  } as NewOrExisting<ICv>
}

/**
 * Assignment proposal initial data.
 *
 * @returns Initial data.
 * @notExported
 */
export const getAssignmentProposalInitialData = () => {
  return {
    city: '',
    remoteAllowed: false,
    percent: null,
    pricePerHour: null,
    startDate: null,
    duration: null,
    extensionPossible: false,
    information: '',
  } as unknown as NewOrExisting<IAssignmentProposal>
}

/**
 * Account verification initial data.
 *
 * @returns Initial data.
 * @notExported
 */
export const getAccountVerificationInitialData = () => {
  return {
    password: '',
  } as unknown as NewOrExisting<IVerifyAccount>
}

/**
 * Login initial data.
 *
 * @returns Initial data.
 * @notExported
 */
export const getLoginInitialData = () => {
  return {
    email: '',
    password: '',
    remember: true,
  } as unknown as NewOrExisting<ILoginAccount>
}

/**
 * Contact information initial data.
 *
 * @returns Initial data.
 * @notExported
 */
export const getContactInformationInitialData = () => {
  return {
    telephone: '',
  } as unknown as NewOrExisting<IContactInformation>
}

/**
 * Forgot password initial data.
 *
 * @returns Initial data.
 * @notExported
 */
export const getForgotPasswordInitialData = () => {
  return {
    email: '',
  } as unknown as NewOrExisting<IForgotPassword>
}

/**
 * Reset password initial data.
 *
 * @returns Initial data.
 * @notExported
 */
export const getResetPasswordInitialData = () => {
  return {
    password: '',
    repeatPassword: '',
  } as unknown as NewOrExisting<IResetPassword>
}

/**
 * Returns the initial data for changing a password.
 *
 * @return {NewOrExisting<IChangePassword>} The initial data for changing a password,
 * including the current password, new password, and repeated new password.
 * @notExported
 */
export const getChangePasswordInitialData = () => {
  return {
    currentPassword: '',
    newPassword: '',
    confirmPassword: '',
  } as unknown as NewOrExisting<IChangePassword>
}

/**
 * Assignment role proposal initial data.
 *
 * @returns Initial data.
 * @notExported
 */
export const getAssignmentRoleProposalInitialData = async () => {
  return {
    allocations: [],
    attachments: [],
    maxPrice: 0,
    minPrice: 0,
    translations: [
      await createTranslation<IAssignmentRoleProposalTranslation, 'assignmentRoleProposalId'>(i18n.language, {
        additionalInformation: '',
      }),
    ],
  } as unknown as NewOrExisting<IAssignmentRoleProposal>
}

/**
 * Widget initial data.
 *
 * @returns Initial data.
 * @notExported
 */
export const getWidgetInitialData = () => {
  return {
    description: '',
    name: '',
    leadEmail: '',
    organizationId: null,
    createdAt: new Date(),
    updatedAt: new Date(),
    key: '',
    origin: 'https://',
  } as unknown as NewOrExisting<IWidget>
}

/**
 * Network register initial data.
 *
 * @returns Initial data.
 * @notExported
 */
export const getNetworkRegisterInitialData = async () => {
  return {
    organization: true,
    companyIdentifier: '',
    address: '',
    city: '',
    postalCode: '',
    country: 'fi',
    users: [],
    defaultLanguage: 'fi',
    language: 'fi',
    translations: [
      await createTranslation<INetworkRegisterTranslation, 'networkRegisterId'>(i18n.language, {
        name: '',
      }),
    ],
  } as unknown as NewOrExisting<INetworkRegister>
}

/**
 * Network user initial data.
 *
 * @returns Initial data.
 * @notExported
 */
export const getNetworkUserInitialData = () => {
  return {
    firstName: '',
    lastName: '',
    email: '',
    notifications: true,
    sales: true,
    companyAdmin: true,
    teamLeader: false,
    language: 'fi',
    sendActivation: true,
  } as unknown as NewOrExisting<IUser>
}

/**
 * Edit info page initial data.
 *
 * @returns Initial data.
 * @notExported
 */
export const getEditInfoPageInitialData = async () => {
  return {
    translations: [
      await createTranslation<INetworkTranslation, 'networkId'>(i18n.language, {
        proposalGuide: '',
        infoPage: '',
        name: '',
        description: '',
      }),
    ],
  } as unknown as NewOrExisting<INetwork>
}

/**
 * Edit proposal info initial data.
 *
 * @returns Initial data.
 * @notExported
 */
export const getEditProposalInfoInitialData = async () => {
  return {
    translations: [
      await createTranslation<INetworkTranslation, 'networkId'>(i18n.language, {
        proposalGuide: '',
        infoPage: '',
        name: '',
        description: '',
      }),
    ],
  } as unknown as NewOrExisting<INetwork>
}

/**
 * Parse setup initial data.
 *
 * @returns Initial data.
 * @notExported
 */
export const getParseSetupInitialData = () => {
  return {
    files: [],
    organizationId: null,
    Organization: null,
    siteId: null,
    Site: null,
    activation: true,
    networkVisible: true,
    parseCredits: 0,
  } as unknown as NewOrExisting<IParseSetup>
}

/**
 * Chat room initial data.
 *
 * @returns Initial data.
 * @notExported
 */
export const getChatRoomInitialData = () => {
  return {
    name: '',
    Members: [],
  }
}

/**
 * Release note initial data.
 *
 * @returns Initial data.
 * @notExported
 */
export const getReleseNoteInitialData = async () => {
  return {
    version: '',
    translations: [
      await createTranslation<IReleaseNoteTranslation, 'releaseNoteId'>(i18n.language, {
        note: '',
        title: '',
      }),
    ],
  }
}
