import { DictionaryType } from './en'

/**
 * Finnish translations for application text
 *
 * @notExported
 */
const fiValues: DictionaryType = {
  notifications: 'Ilmoitukset',
  accountMenu: 'Tilivalikko',
  required: 'pakollinen',
  noMobileView: 'Tämä näkymä ei saatavilla mobiili laitteilla, käytä tietokonetta!',
  dashboard: 'Dashboard',
  submit: 'Lähetä',
  send: 'Lähetä',
  sent: 'Lähetetty',
  createdAt: 'Luotu',
  forgotPassword: 'Unohditko salasanasi?',
  'forgotPassword.email': 'Sähköposti',
  emailIsNotValid: 'Sähköpostiosoitteen muoto on väärä!',
  'name-used-error': 'Nimi on jo käytössä!',
  'company-id-used-error': 'Y-tunnus on jo käytössä!',
  'same-password-error': 'Et voi käyttää vanhaa salasanaa uudestaan. Syötä uusi salasana!',
  'wrong-password-error': 'Virheellinen vanha salasana, yritä uudelleen!',
  loginheader: 'Tervetuloa',
  login: 'Kirjaudu sisään',
  logout: 'Kirjaudu ulos',
  back: 'Mene takaisin',
  email: 'Sähköposti',
  promoted: 'Nostettu',
  'login.email': 'Sähköposti',
  'login.password': 'Salasana',
  'login.remember': 'Pidä kirjautuneena',
  'azureLogin.button': 'Azure AD kirjautuminen',
  'azureLogin.tenantError': 'Yrityksellä ei oikeutta Azure AD kirjautumiseen!',
  'azureLogin.clientError': 'Applikaatiolla ei oikeutta Kirjautumiseen!',
  'azureLogin.claimsError': 'Virheeliset claim tiedot!',
  'azureLogin.error': 'Tapahtui virhe Azure AD kirjautumisessa!',
  'azureLogin.disabled': 'Tili on disabloitu!',
  or: 'TAI',
  settings: 'Tilin asetukset',
  profile: 'Profiili',
  organization: 'Organisaatio',
  person: 'Henkilö',
  myCV: 'Oma CV',
  resources: 'Työntekijät',
  noRights: 'Käyttäjällä ei ole oikeuksia resurssiin.',
  admin: 'Hallinta',
  allocation: 'Allokaatiot',
  allocations: 'allokaatiot',
  error: 'Kirjautuminen epäonnistui, ole hyvä ja tarkasta kirjautumistiedot',
  errorfield: 'Anna sähköpostiosoite ja salasana',
  errorfieldreset: 'Ole hyvä ja syötä tiliisi kytketty sähköpostiosoite ja valitse sen jälkeen toiminto uudelleen',
  errorrbackreset: 'Salasanan vaihtaminen epäonnistui. Yritä uudelleen.',
  resetpassword: 'Unohditko salasanasi?',
  contactSales: 'Ota yhteyttä myyntiin',
  officeAddress: 'Toimiston sijainti',
  'resetPassword.header': 'Vaihda salasana',
  'resetPassword.password': 'Uusi salasana',
  'resetPassword.repeatPassword': 'Uusi salasana uudestaan',
  startDateEndDateError: 'Tarkasta päivämäärät.',
  numberTypeError: 'Syötteen tulisi olla yli 0.',
  allocationPercentError: 'Anna prosentti 1-100 väliltä',
  select: 'Valitse',
  projectsWith: 'projektia:',
  projectWith: 'projekti:',
  hide: 'Piilota',
  show: 'Näytä',
  'resetasked-ok': 'OK',
  'resetasked-header': 'Tarkasta sähköpostisi',
  'resetasked-text':
    'Lähetämme sähköpostiin ohjeet salasanan palauttamiseksi jos syötetty tunnus löytyi järjestelmästä.',
  printpreview:
    'Valitse tai lisää mukautettu asettelu ja järjestä CV:n sisältöä tulostettavaksi sekä lataa tulostettava CV',
  frontpage: 'Etusivu',
  help: 'Ohjeita',
  team: 'Tiimi',
  view: 'Katso',
  accountSettings: 'Tilin asetukset',
  acceptFollowingTerms: 'Seuraaviin ehtoihin tarvitaan hyväksyntä',
  accountActivatedMessage:
    'Kiitos, että aktivoit tilisi. Sinut ohjataan nyt sisäänkirjautumissivulle, jossa voit kirjautua sisään sähköpostiosoitteellasi ja juuri asettamallasi salasanalla.',
  close: 'Sulje',
  company: 'Yritys',
  accessDenied: 'Oho! Päädyit teille tuntemattomille.',
  accessDeniedBody: 'Sivu, jota yrität etsiä on kadonnut bittiavaruudeen.',
  showMore: 'Näytä enemmän',
  showLess: 'Näytä vähemmän',
  emailError: 'Sähköposti on jo käytössä!',
  showAll: 'kaikki',
  showAllResults: 'Näytä kaikki tulokset',
  contactInformation: 'Yhteystiedot',
  'contactInformation.addTitle': 'Yhteystiedot',
  'contactInformation.editTitle': 'Yhteystiedot',
  'contactInformation.telephone': 'Puhelin',
  notificationSettings: 'Ilmoitusasetukset',
  'notificationSettings.editTitle': 'Ilmoitusasetukset',
  'notificationSettings.switch': 'Vastaanota sähköposteja Caleōsta',
  'notificationSettings.new-assignments': 'Vastaanota sähköposteja uusista toimeksiannoista',
  'notificationSettings.weekly-summary': 'Vastaanota viikottaisia toimeksianto yhteenvetoja',
  'notificationSettings.monthly-summary': 'Vastaanota kuukausittaisia toimeksianto yhteenvetoja',
  'notificationSettings.chat-messages': 'Vastaanota sähköposteja uusista chat-viesteistä',
  'notificationSettings.network-notifications': 'Vastaanota sähköposteja verkostoilmoituksista',
  apply: 'Käytä',
  ownOrganization: 'Oma yritys',
  yes: 'Kyllä',
  no: 'Ei',
  unsubscribed: 'Sinun tilauksesi on lopetettu!',
  fileUploaded: 'Tiedosto tallennettu!',
  notAvailable: 'Ei saatavilla',
  skillWarning: 'Lisää {{type}} yksittäin, älä lisää kaikkea yhtenä syötteenä.',
  skillLower: 'taidot',
  roleLower: 'roolit',
  industryLower: 'toimialat',
  toggleAll: 'Kaikki sarakkeet',
  hideColumns: 'Muokkaa sarakkeita',
  name: 'Nimi',
  skillsCount: 'Taitojen määrä',
  rolesCount: 'Rolien määrä',
  industriesCount: 'Toimiala määrä',
  workExperienceYears: 'Kokemusvuodet',
  workExperienceMonths: 'Kokemuskuukaudet',
  duplicate: 'Monista',
  'navigation.unsubscribe': 'Tilaus lopetettu',
  'placeholder.organization': 'Lisää organisaatio',
  'placeholder.skill': 'Lisää taito',
  'placeholder.industry': 'Lisää toimiala',
  'placeholder.role': 'Lisää rooli',
  'placeholder.person': 'Lisää henkilö',
  'translationImport.missing': 'Ei suomen kielistä tekstiä.',
  'translationImport.description': 'Lisäämäsi teksti näytetään kun tietoja tarkastellaan suomeksi.',
  'translationImport.addOld': 'Kopioi englanti käännös',
  'translationImport.addNew': 'Lisää tekstiä',
  'translationImport.dataMissing': 'Ei käännöksiä! Ota yhteyttä tukeen!',
  'translationImport.noTranslation': 'Täytä alkuperäinen kieli ensin!',
  'translationImport.helperText': 'Tiedot näytetään suomeksi.',
  'languages.fi': 'Suomi',
  'languages.en': 'Englanti',
  'languages.sv': 'Ruotsi',
  'controls.selectdialoglanguage': 'Valitse syötettävä kieli',
  'controls.selectgloballanguage': 'Valitse käyttöliittymän kieli',
  'controls.undo': 'Kumoa',
  'controls.undoCopy': 'Tiedot kopioitiin edellisestä kielestä.',
  'controls.save-copy': 'Puuttuvat käännökset kopioitiin aktiivisesta kielestä.',
  'controls.noOptionsText': 'Ei valintoja',
  'password.resetPassword': 'Unohditko salasanasi?',
  'password.reset': 'Vaihda',
  'password.newPassword': 'Uusi salasana',
  'password.verification': 'Verifiointi',
  'password.password': 'Salasana',
  'password.repeatPassword': 'Toista salasana',
  'password.error': 'Salasanan vaihto epäonnistui',
  'password.notmatch': 'Salasanat eivät vastaa toisiaan. Tarkista syöttämäsi salasanat!',
  'password.matchError': 'Et voi käyttää vanhaa salasanaa uudestaan. Syötä uusi salasana!',
  'password.empty': 'Anna uusi salasana',
  'password.passwordchange': 'Salasanan vaihto',
  'password.newpassword': 'Uusi salasana',
  'password.newpasswordagain': 'Uusi salasana uudestaan',
  'password.changepassword': 'Vaihda salasana',
  'password.activateAccount': 'Käyttäjätunnuksen aktivointi',
  'password.activateAccount.invalidToken': `Aktivointilinkki on jo käytetty. Jos unohdit salasanasi, palauta salasana "unohdin salasanan"-toiminnolla`,
  'password.activate': 'Aktivoi',
  'password.activated': 'Salasanan vaihto onnistui! Sinut siirretään kirjautumissivulle hetken kuluttua.',
  'password.alreadyActivated': 'Käyttäjätunnus on jo aktivoitu',
  'password.notFound': 'Käyttäjätunnusta ei löytynyt',
  'password.server': 'Palvelinvirhe',
  'password.tooShort': 'Salasanan täytyy olla vähintään 8 merkkiä pitkä.',
  'password.tooLong': 'Salasanan maksimipituus on {{passwordMaxLength}}.',
  'changePassword.header': 'Vaihda salasana',
  'changePassword.currentPassword': 'Nykyinen salasana',
  'changePassword.newPassword': 'Uusi salasana',
  'changePassword.confirmPassword': 'Vahvista uusi salasana',
  'navigation.dashboard': 'Dashboard',
  'navigation.404': '404',
  'navigation.403': '403',
  'navigation.submit': 'Lähetä',
  'navigation.emailIsNotValid': 'Sähköpostiosoitteen muoto on väärä!',
  'navigation.loginheader': 'Tervetuloa',
  'navigation.login': 'Kirjaudu sisään',
  'navigation.logout': 'Kirjaudu ulos',
  'navigation.email': 'Sähköposti',
  'navigation.password': 'Salasana',
  'navigation.profile': 'Omat tiedot',
  'navigation.assignments': 'Toimeksiannot',
  'navigation.myCV': 'Oma CV',
  'navigation.employees': 'Haku',
  'navigation.admin': 'Hallinta',
  'navigation.scout': 'Tutka',
  'navigation.scoutPlus': 'Tutka-plus',
  'navigation.scoutFAQ': 'Tutka FAQ',
  'navigation.arvoviisari': 'Arvoviisari',
  'navigation.allocation': 'Allokaatiot',
  'navigation.print': 'CV Tulostus',
  'navigation.statistics': 'Raportit',
  'navigation.scoutStatistics': 'Tutka',
  'navigation.scoutPlusStatistics': 'Tutka plus',
  'navigation.certificateStatistics': 'Sertifikaatit',
  'navigation.error': 'Kirjautuminen epäonnistui, ole hyvä ja tarkasta kirjautumistiedot',
  'navigation.errorfield': 'Anna sähköpostiosoite ja salasana',
  'navigation.errorfieldreset':
    'Ole hyvä ja syötä tiliisi kytketty sähköpostiosoite ja valitse sen jälkeen toiminto uudelleen',
  'navigation.errorrbackreset': 'Salasanan vaihtaminen epäonnistui. Yritä uudelleen.',
  'navigation.reset-password': 'Unohditko salasanasi?',
  'navigation.forgot-password': 'Unohditko salasanasi?',
  'navigation.change-password': 'Vaihda salasana',
  'navigation.activate': 'Tilin aktivointi',
  'navigation.confirm': 'Tilin aktivointi',
  'navigation.register': 'Registeröinti',
  'navigation.resetasked-ok': 'OK',
  'navigation.resetasked-header': 'Tarkasta sähköpostisi',
  'navigation.resetasked-text':
    'Lähetämme sähköpostiin ohjeet salasanan palauttamiseksi jos syötetty tunnus löytyi järjestelmästä.',
  'navigation.printpreview': 'Valitse ja järjestä CV:n sisältöä tulostettavaksi sekä lataa tulostettava PDF',
  'navigation.frontpage': 'Etusivu',
  'navigation.help': 'Ohjeita',
  'navigation.teams': 'Tiimit',
  'navigation.accountSettings': 'Tilin asetukset',
  'navigation.close': 'Sulje',
  'navigation.company': 'Yritys',
  'navigation.rememberMe': 'Muista minut',
  'navigation.company-admin': 'Yritys',
  'navigation.salespool': 'Myyntikorit',
  'profile.fillingWizard.selectSkills': 'Valitse taidot',
  'profile.fillingWizard.selectskill': 'Valitse taito',
  'profile.fillingWizard.selectrole': 'Valitse rooli',
  'profile.fillingWizard.selectindustry': 'Valitse toimiala',
  'profile.fillingWizard.skill': 'Taito',
  'profile.fillingWizard.role': 'Rooli',
  'profile.fillingWizard.industry': 'Toimiala',
  'profile.fillingWizard.skill.search': 'Hae taito',
  'profile.fillingWizard.role.search': 'Hae rooli',
  'profile.fillingWizard.industry.search': 'Hae toimiala',
  'profile.fillingWizard.role.recommendations': 'Roolisuositukset',
  'profile.fillingWizard.skill.recommendations': 'Taitosuositukset',
  'profile.fillingWizard.industry.recommendations': 'Toimialasuositukset',
  'profile.fillingWizard.skip': 'Ohita profiilin pikatäyttö',
  'profile.fillingWizard.save': 'Luo CV {{count}} elementillä',
  'profile.fillingWizard.update': 'Päivitä CV {{count}} elementillä',
  'profile.fillingWizard.title': 'Profiilin pikatäyttö',
  'profile.fillingWizard.loadMore': 'Lataa lisää',
  'profile.fillingWizard.welcome': 'TERVETULOA CALEOON',
  'profile.fillingWizard.instruction': 'Valitse rooli joka parhaiten vastaa omia taitojasi.',
  'profile.fillingWizard.defaultLevel': 'Taso',
  'profile.fillingWizard.defaultInterest': 'Kiinnostus',
  'profile.fillingWizard.defaultExperience': 'Kokemus (kuukautta)',
  'profile.fillingWizard.helperText':
    'Määritetyt oletusarvot asetetaan jokaiselle elementille. Voit muokata ja säätää arvoja erikseen profiilisivulta.',
  'profile.noCv': 'Käyttäjällä ei ole ansioluetteloa.',
  'profile.lastUpdate': 'Viimeksi päivittänyt',
  'profile.draft': 'Luonnos',
  'profile.published': 'Valmis',
  'profile.companyId': 'Y-tunnus',
  'profile.progressTitle': 'Profiilin edistyminen',
  'profile.progress.info':
    'Profiilin edistymisen merkinnät perustuvat eri osioiden suomen- ja englanninkielisiin käännöksiin. Osio voidaan merkitä täytetyksi jos kaikki tiedot on täytetty sekä suomeksi että englanniksi. ',
  'profile.progress.info2':
    'Koulutuksen osioista vain yhden pitää olla täydellisesti täytetty jotta koko koulutusosio merkitään täytetyksi.',
  'profile.parseCV': 'Parsi uusi CV',
  'profile.addToPool': 'Lisää myyntikoriin',
  'profile.print': 'Tulosta CV',
  'profile.parseModal': 'Parsi CV',
  'profile.about.addLinkTooltip':
    'Lisää profiiliisi henkilökohtainen linkki, kuten verkkosivustosi, portfoliosi tai sosiaalisen median linkki',
  'profile.about.noPrice': 'Hintaa ei ole määritelty.',
  'profile.modal.editTitle': 'Henkilötiedot',
  'profile.modal.firstName': 'Etunimi',
  'profile.modal.lastName': 'Sukunimi',
  'profile.modal.telephone': 'Puhelin',
  'profile.modal.telephoneError': 'Syötä oikea puhelinnumero.',
  'profile.modal.streetAddress': 'Katuosoite',
  'profile.modal.postalCode': 'Postinumero',
  'profile.modal.city': 'Kaupunki',
  'profile.modal.country': 'Maa',
  'profile.modal.about': 'Kuvaus',
  'profile.modal.save': 'Tallenna',
  'profile.modal.help': 'Ohjeita',
  'profile.modal.close': 'Sulje',
  'profile.modal.charLeft': 'Merkkiä jäljellä (suositus):',
  'profile.modal.pricingInformation': 'Vain myynnin roolilla näkee hintatiedot.',
  'profile.modal.ProfileRoles': 'Rooli',
  'profile.modal.role': 'Rooli',
  'allocation.personal': 'Poissaolo',
  'allocation.project': 'Toimeksianto',
  'allocation.other': 'Muu työ',
  'allocation.other.info': 'Käytä <1>muu työ</1>-tyyppiä kevyeen ja nopeaan resursointiin.',
  'allocation.assignmentId': 'Toimeksianto',
  'allocation.customer': 'Asiakas',
  'allocation.status': 'Tila',
  'allocation.percent': 'Allokaatio %',
  'allocation.expectedAllocation': 'Odotettu allokaatio',
  'allocation.totalAllocation': 'Allokaatio yhteensä',
  'allocation.information': 'Lisätietoja',
  'allocation.description': 'Allokaation kuvaus',
  'allocation.startDate': 'Aloitus pvm.',
  'allocation.endDate': 'Lopetus pvm.',
  'allocation.state': 'Tila',
  'allocation.type': 'Tyyppi',
  'allocation.state.0': 'Alustava',
  'allocation.state.1': 'Varmistettu',
  'allocation.own': 'Poissaolo',
  'allocation.own.info': 'Käytä <1>poissaolo</1>-tyyppiä kirjataksesi lomia tai poissaoloja.',
  'allocation.ownProject': 'Poissaolo',
  'allocation.roleId': 'Rooli',
  'allocation.showAllocatedRoles': 'Näytä allokoidut roolit',
  'allocation.addTitle': 'Lisää allokaatio',
  'allocation.editTitle': 'Muokkaa allokaatiota',
  'allocation.proposal.remove': 'Poista henkilö {{assignmentName}} toimeksiannon tarjouksesta.',
  'allocation.pending': 'Alustava allokaatio, odottaa toimia toimeksiantajalta.',
  'allocation.title': 'Allokaatiot',
  'allocation.ownAllocations': 'Omat allokaatiot',
  'allocation.user': 'Käyttäjä',
  'allocation.showOutdated': 'Näytä vanhentuneet',
  'allocation.toAssignment': 'Toimeksiantoon',
  'allocation.toWorkorder': 'Työtilaukseen',
  'allocation.toProposed': 'Ehdotuksiin',
  'allocation.showActive': 'Näytä aktiiviset',
  'allocation.ownCompany': 'Oma yritys',
  'allocation.selectScope': 'Valitse rajaus',
  'allocation.networkScope': 'Lisää rajaus',
  'allocation.notes': 'Muistiinpanot',
  'allocation.filter': 'Suodata',
  'allocation.scope': 'Rajaus',
  'allocation.results': 'Tulokset',
  'skills.title': 'Taidot',
  'profile.skill.level': 'Taso',
  'profile.skill.lastUsed': 'Viimeksi käytetty',
  'profile.skill.experience': 'Kokemus',
  'profile.skill.month': 'Kuukausi',
  'profile.skill.months': 'Kuukautta',
  'profile.skill.month.abbr': 'kk',
  'profile.skill.months.abbr': 'kk',
  'profile.skill.unspecified': 'ei määritetty',
  'profile.skill.interest': 'Kiinnostus',
  'profile.skill.fundamental.description': 'Henkilö on tutustunut taitoon ja käytännön osaaminen on heikkoa.',
  'profile.industry.fundamental.description':
    'Henkilöllä on kevyet tiedot toimialasta ja käytännön osaaminen on heikkoa.',
  'profile.role.fundamental.description': 'Henkilöllä on ohut tarttumapinta rooliin ja käytännön osaaminen on heikkoa.',
  'profile.skill.novice.description': 'Henkilöllä on rajoittunut käytännön osaaminen taidosta.',
  'profile.role.novice.description': 'Henkilöllä on rajoittunut käytännön osaaminen roolista.',
  'profile.industry.novice.description': 'Henkilöllä on rajoittuneet tiedot toimialasta.',
  'profile.skill.intermediate.description': 'Henkilöllä on todistettu käytännön osaaminen taidosta.',
  'profile.role.intermediate.description': 'Henkilöllä on todistettu käytännön osaaminen roolista.',
  'profile.industry.intermediate.description': 'Henkilöllä on todistettu käytännön osaaminen toimialasta.',
  'profile.skill.advanced.description':
    'Henkilöllä on vahva käytännön osaaminen taidosta ja hallitsee vaativat tehtävät.',
  'profile.role.advanced.description':
    'Henkilöllä on vahva käytännön osaaminen roolista ja hallitsee vaativat tehtävät.',
  'profile.industry.advanced.description': 'Henkilö tuntee toimialan hyvin ja hallitsee vaativat tehtävät.',
  'profile.skill.expert.description':
    'Henkilöllä on erittäin vahva käytännön osaaminen ja hän ymmärtää ja hallitsee taidon syvällisesti.',
  'profile.industry.expert.description':
    'Henkilöllä on erittäin vahva käytännön osaaminen ja hän tuntee ja hallitsee toimialan erinomaisesti.',
  'profile.role.expert.description':
    'Henkilöllä on erittäin vahva käytännön osaaminen ja hän ymmärtää ja hallitsee roolin tehtävät syvällisesti.',
  'profile.skill.fundamental': 'Alkeet',
  'profile.skill.novice': 'Perusteet',
  'profile.skill.intermediate': 'Keskitaso',
  'profile.skill.advanced': 'Edistynyt',
  'profile.skill.expert': 'Asiantuntija',
  'profile.skill.year': 'Vuosi',
  'profile.skill.years': 'Vuotta',
  'profile.skill.years.abbr': 'v',
  'profile.skill.levels': 'Taitojen osaamistasot',
  'profile.skill.card.nameColumn': 'Taito',
  'profile.skill.modal.addTitle': 'Lisää taitoja',
  'profile.skill.modal.editTitle': 'Muokkaa taitoa',
  'profile.skill.modal.skillId': 'Taidon nimi',
  'profile.skill.modal.experienceMonths': 'Kokemus',
  'profile.skill.modal.level': 'Taitotaso',
  'profile.skill.modal.lastUsed': 'Viimeksi käytetty',
  'profile.skill.modal.save': 'Tallenna',
  'profile.skill.modal.addSkill': 'Lisää taito',
  'profile.skill.modal.delete': 'Poista',
  'profile.skill.modal.update': 'Tallenna muutos',
  'profile.skill.modal.help': 'Ohjeita',
  'profile.skill.modal.deletionError': 'Taitoa ei voida poistaa, koska se on listattu Projekteissa.',
  'profile.skill.modal.deletionErrorHelp': 'Poista taito projektista ensin.',
  'profile.skill.modal.experience.months': 'Kuukausina',
  'profile.skill.modal.experience.years': 'Vuosina',
  'profile.skill.modal.experience.days': 'Päivä',
  'profile.skill.modal.experience.manDays': 'HTP',
  'profile.skill.modal.experience.manMonths': 'HTK',
  'profile.skill.modal.searchSkill': 'Hae ja lisää uusi taito',
  'profile.skill.interest.low': 'Olematon kiinnostus',
  'profile.skill.interest.slight': 'Matala kiinnostus',
  'profile.skill.interest.medium': 'Keskinkertainen kiinnostus',
  'profile.skill.interest.high': 'Vahva kiinnostus',
  'profile.skill.interest.veryHigh': 'Erittäin vahva kiinnostus',
  'profile.skill.massUpdate.addTitle': 'Lisää kokemusta',
  'profile.skill.massUpdate.lastUpdated': 'Viimeksi päivitetty',
  'profile.skill.massUpdate.experienceGained': 'Saatu kokemus',
  'profile.skill.massUpdate.searchSkills': 'Etsi taitoja',
  'profile.skill.massUpdate.newSkill': 'Uusi taito',
  'profile.skill.massUpdate.experienceMonthsGained': 'Saatu kokemus kuukausina',
  'profile.skill.massUpdate.updateLastUsed': 'Päivitä viimeksi käytetty',
  'profile.skill.info': 'Tasojen kuvaukset',
  'profile.skill.update': 'Lisää kokemusta',
  'profile.skills.add': 'Lisää taito',
  'industries.title': 'Toimialat',
  'profile.industry.level': 'Taso',
  'profile.industry.lastUsed': 'Viimeksi käytetty',
  'profile.industry.experience': 'Kokemus',
  'profile.industry.month': 'Kuukausi',
  'profile.industry.months': 'Kuukautta',
  'profile.industry.month.abbr': 'kk',
  'profile.industry.months.abbr': 'kk',
  'profile.industry.unspecified': 'ei määritetty',
  'profile.industry.interest': 'Kiinnostus',
  'profile.industry.year': 'Vuosi',
  'profile.industry.levels': 'Toimialojen osaamistasot',
  'profile.industry.card.nameColumn': 'Toimiala',
  'profile.industry.modal.addTitle': 'Lisää toimialoja',
  'profile.industry.modal.editTitle': 'Muokkaa toimialaa',
  'profile.industry.modal.skillId': 'Toimialan nimi',
  'profile.industry.modal.experienceMonths': 'Kokemus',
  'profile.industry.modal.level': 'Taitotaso',
  'profile.industry.modal.lastUsed': 'Viimeksi käytetty',
  'profile.industry.modal.save': 'Tallenna',
  'profile.industry.modal.addSkill': 'Lisää toimiala',
  'profile.industry.modal.delete': 'Poista',
  'profile.industry.modal.update': 'Tallenna muutos',
  'profile.industry.modal.help': 'Ohjeita',
  'profile.industry.modal.deletionError': 'Toimialaa ei voida poistaa, koska se on listattu Projekteissa.',
  'profile.industry.modal.deletionErrorHelp': 'Poista toimiala projektista ensin.',
  'profile.industry.modal.searchSkill': 'Hae ja lisää uusi toimiala',
  'profile.industry.massUpdate.addTitle': 'Lisää kokemusta',
  'profile.industry.massUpdate.lastUpdated': 'Viimeksi päivitetty',
  'profile.industry.massUpdate.experienceGained': 'Saatu kokemus',
  'profile.industry.massUpdate.searchSkills': 'Etsi toimialoja',
  'profile.industry.massUpdate.newSkill': 'Uusi toimiala',
  'profile.industry.massUpdate.experienceMonthsGained': 'Saatu kokemus kuukausina',
  'profile.industries.add': 'Lisää toimiala',
  'roles.title': 'Roolit',
  'profile.role.level': 'Taso',
  'profile.role.lastUsed': 'Viimeksi käytetty',
  'profile.role.experience': 'Kokemus',
  'profile.role.month': 'Kuukausi',
  'profile.role.months': 'Kuukautta',
  'profile.role.month.abbr': 'kk',
  'profile.role.months.abbr': 'kk',
  'profile.role.unspecified': 'ei määritetty',
  'profile.role.interest': 'Kiinnostus',
  'profile.role.year': 'Vuosi',
  'profile.role.levels': 'Roolien osaamistasot',
  'profile.role.card.nameColumn': 'Rooli',
  'profile.role.modal.addTitle': 'Lisää rooleja',
  'profile.role.modal.editTitle': 'Muokkaa roolia',
  'profile.role.modal.skillId': 'Roolin nimi',
  'profile.role.modal.experienceMonths': 'Kokemus',
  'profile.role.modal.level': 'Taitotaso',
  'profile.role.modal.lastUsed': 'Viimeksi käytetty',
  'profile.role.modal.save': 'Tallenna',
  'profile.role.modal.addSkill': 'Lisää rooli',
  'profile.role.modal.delete': 'Poista',
  'profile.role.modal.update': 'Tallenna muutos',
  'profile.role.modal.help': 'Ohjeita',
  'profile.role.modal.deletionError': 'Roolia ei voida poistaa, koska se on listattu Projekteissa.',
  'profile.role.modal.deletionErrorHelp': 'Poista rooli projektista ensin.',
  'profile.role.modal.searchSkill': 'Hae ja lisää uusi rooli',
  'profile.role.massUpdate.addTitle': 'Lisää kokemusta',
  'profile.role.massUpdate.lastUpdated': 'Viimeksi päivitetty',
  'profile.role.massUpdate.experienceGained': 'Saatu kokemus',
  'profile.role.massUpdate.searchSkills': 'Etsi rooleja',
  'profile.role.massUpdate.newSkill': 'Uusi rooli',
  'profile.role.massUpdate.experienceMonthsGained': 'Saatu kokemus kuukausina',
  'profile.roles.add': 'Lisää rooli',
  'project.title': 'Projektit',
  'profile.project.modal.addTitle': 'Luo projekti',
  'profile.project.modal.editTitle': 'Muokkaa projektia',
  'profile.project.modal.name': 'Projektin nimi',
  'profile.project.modal.startDate': 'Alku',
  'profile.project.modal.endDate': 'Loppu',
  'profile.project.modal.employerName': 'Työnantaja',
  'profile.project.modal.customerName': 'Asiakas',
  'profile.project.modal.role': 'Rooli-tägit',
  'profile.project.modal.description': 'Kuvaus',
  'profile.project.modal.save': 'Tallenna',
  'profile.project.modal.addskill': 'Lisää taito',
  'profile.project.modal.addindustry': 'Lisää toimiala',
  'profile.project.modal.addindustry.placeholder': 'Etsi toimialaa',
  'profile.project.modal.addskill.placeholder': 'Etsi taitoa',
  'profile.project.modal.addrole.placeholder': 'Etsi roolia',
  'profile.project.modal.addrole': 'Lisää rooli',
  'profile.project.modal.charLeft': 'Merkkiä jäljellä (suositus):',
  'profile.project.modal.skills': 'Taidot',
  'profile.project.modal.industries': 'Toimialat',
  'profile.project.modal.roles': 'Rooli-tägit',
  'profile.project.modal.primaryRole': 'Rooli',
  'profile.project.add': 'Lisää projekti',
  'profile.project.model.convertToEmployer': 'Muuta työkokemukseksi',
  'assignments.overview': 'Toimeksiannot',
  'assignment.modal.addTitle': 'Uusi toimeksianto',
  'assignment.modal.roles': 'Roolit',
  'assignment.modal.generalTitle': 'Yleinen',
  'assignment.modal.customerTitle': 'Asiakas',
  'assignment.modal.rolesTitle': 'Roolit',
  'assignment.modal.attachmentsTitle': 'Liitteet',
  'assignment.modal.editTitle': 'Muokkaa toimeksiantoa',
  'assignment.modal.shareTitle': 'Kopioi ja jaa toimeksianto',
  'assignment.modal.copyTitle': 'Kopioi toimeksianto',
  'assignment.modal.share': 'Jaa verkostoihin',
  'assignment.modal.copy.save': 'Luo uusi toimeksianto',
  'assignment.modal.share.save': 'Jaa ja tallenna',
  'assignment.modal.customerId': 'Asiakas',
  'assignment.modal.copy': 'Kopioi',
  'assignment.share.broker': 'Toimeksianto jaetaan ja julkaistaan verkoistoihin.',
  'assignment.share.info': 'Toimeksiannon jako odottaa verkoston ylläpitäjän hyväksyntää.',
  'assignment.internal': 'Sisäiset',
  'assignment.external': 'Ulkoiset',
  'assignment.shared': 'Jaetut',
  'assignment.outdated': 'Vanhentuneet',
  'assignment.favorite': 'Suosikit',
  'assignment.contactPeople': 'Filteröi kontaktihenkilön mukaan',
  'assignment.filterBySkill': 'Filteröi taitojen mukaan',
  'assignment.notifySelector': 'Valitse käyttäjät, joille ilmoitetaan',
  'assignment.internal.tooltip': 'Organisaation omistamat toimeksiannot, joita ei ole jaettu.',
  'assignment.external.tooltip': 'Organisaatiolle jaetut toimeksiannot verkon kautta',
  'assignment.shared.tooltip': 'Organisaation omistamat jaetut toimeksiannot.',
  'assignment.outdated.tooltip': 'Toimeksiannot joiden määräaika on umpeutunut.',
  'assignment.favorite.tooltip': 'Toimeksiannot jotka on asetettu suosikeiksi.',
  'assignment.description': 'Toimeksiannon kuvaus',
  'assignment.autoRejectEmail': '(Automaattisesti sulkee tarjoukset ja lähettää sulkemisilmoitukset)',
  'assignment.modal.view': 'Katso toimeksiantoa',
  'assignment.modal.viewPropose': 'Katsele ja tarjoa toimeksiantoon',
  'assignment.modal.next': 'Seuraava',
  'assignment.modal.save': 'Tallenna',
  'assignment.modal.name': 'Toimeksiannon nimi',
  'assignment.modal.startDate': 'Alku',
  'assignment.modal.endDate': 'Loppu',
  'assignment.modal.workloadEstimate': 'Työmäärä-Arvio (htp)',
  'assignment.modal.country': 'Maa',
  'assignment.modal.city': 'Kaupunki',
  'assignment.modal.subcontractorAllowed': 'Alihankkijat sallittu',
  'assignment.modal.remoteAllowed': 'Etätyö sallittu',
  'assignment.modal.deadline': 'Määräaika',
  'assignment.modal.description': 'Kuvaus',
  'assignment.modal.customer': 'Asiakas',
  'assignment.modal.customerContactId': 'Yhteyshenkilö',
  'assignment.modal.additionalInfo': 'Lisätietoja',
  'assignment.modal.attachments.description': 'Kuvaus',
  'assignment.modal.location': 'Sijainti',
  'assignment.modal.attachments.upload-action': 'Lähetä',
  'assignment.modal.attachments.download-action': 'Lataa',
  'assignment.modal.roles.hourlyPrice': 'Tuntihinta',
  'assignment.modal.roles.allocation': 'Allokaatio',
  'assignment.modal.roles.createCopy': 'Luo uusi rooli kopiosta',
  'assignment.modal.personInCharge': 'Vastuuhenkilö',
  'assignment.modal.contactPeople': 'Yhteyshenkilöt',
  'assignment.modal.contactName': 'Nimi',
  'assignment.modal.publicView': 'Nähtävissä ilman kirjautumista',
  'assignment.modal.contactPersonId': 'Yhteyshenkilö',
  'assignment.modal.notifyTo': 'Lähetä sähköposti ilmotus verkkoihin',
  'assignment.modal.publicUrl': 'Julkinen URL',
  'assignment.modal.copyToClipboard': 'Kopioi leikepöydälle',
  'assignment.modal.lastChangedBy': 'Tilan viimeksi muokannut: {{name}}',
  'assignment.modal.statusBySystem': 'Järjestelmä',
  'assignment.modal.template': 'Malli',
  'assignment.modal.templateName': 'Mallin nimi',
  'assignment.modal.templateCreate': 'Luo',
  'assignment.modal.templateUpdate': 'Päivitä',
  'assignment.modal.fileProposalAllowed': 'Tiedostolla tarjoaminen sallittu',
  'assignment.modal.basicInfo': 'Perustiedot',
  'assignment.modal.details': 'Lisätiedot',
  'assignment.modal.sharingOptions': 'Jakovalinnat',
  'assignment.modal.moreDetails': 'Kuvaus',
  'assignment.modal.attachments': 'Liitteet',
  'assignment.modal.useTemplate': 'Käytä mallia',
  'assignment.modal.helperTextContactPerson': 'Aseta kontaktihenkilöt jako valinnoissa.',
  'assignment.modal.status': 'Tila',
  'assignment.modal.info': 'Tiedot',
  'assignment.modal.contactInfo': 'Yhteystiedot',
  'assignment.modal.people': 'Allokoidut',
  'assignment.modal.favorite': 'Lisää suosikkeihin',
  'assignment.modal.unfavorite': 'Poista suosikeista',
  'assignment.modal.workOrder': 'Lisää haluttuja kandidaatteja rooliin',
  'assignment.modal.workOrderResponse.contactPerson': 'Kontaktihenkilö kandidaateille',
  'assignment.modal.workOrderResponse.selected': 'Valitut',
  'assignment.table.name': 'Nimi',
  'assignment.table.customer': 'Asiakas',
  'assignment.table.status': 'Tila',
  'assignment.table.created': 'Vastaanotetaan tarjouksia',
  'assignment.table.inProgress': 'Tarjous käynnissä',
  'assignment.table.inInterview': 'Haastattelussa',
  'assignment.table.resolved': 'Päättynyt',
  'assignment.table.roles': 'Roolit',
  'assignment.table.inCharge': 'Vastuussa',
  'assignment.table.startDate': 'Alku',
  'assignment.table.endDate': 'Loppu',
  'assignment.table.attachments': 'Liitteet',
  'assignment.table.deadline': 'Määräaika',
  'assignment.table.roleTooltip.header': 'Hae hakuehdoilla',
  'assignment.table.roleTooltip.location': 'Sijainti',
  'assignment.table.roleTooltip.skills': 'Taidot',
  'assignment.table.createdAt': 'Luotu',
  'assignment.roles.table.name': 'Roolin nimi',
  'assignment.roles.add': 'Lisää rooli',
  'assignment.roles.applyChanges': 'Lisää muutokset',
  'assignment.roles.table.allocationPercentage': 'Allokaatio %',
  'assignment.roles.table.negotiable': 'Neuvoteltavissa',
  'allocationPercentage.minValue': 'Varauksen on oltava suurempi kuin 0%',
  'allocationPercentage.maxValue': 'Varauksen suurin arvo on 100%',
  'assignment.roles.table.hourlyPrice': 'Tuntihinta €/h',
  'assignment.roles.table.skills': 'Taidot',
  'assignment.roles.table.languages': 'Kielitaidot',
  'assignment.roles.table.description': 'Kuvaus',
  'assignment.roles.table.assignedPeople': 'Allokoidut',
  'assignment.roles.table.assignedPeople.name': 'Nimi',
  'assignment.roles.table.assignedPeople.allocation': 'Allokaatio',
  'assignment.roles.table.assignedPeople.state': 'Tila',
  'assignment.roles.table.assignedPeople.startDate': 'Alkupäivä',
  'assignment.roles.table.assignedPeople.endDate': 'Loppupäivä',
  'assignment.roles.allocations': 'Roolin allokaatiot',
  'assignment.roles.allocation.helperText':
    'Roolia ei voida poistaa, koska sillä on olemassa olevia allokaatioita. Muokataksesi tai poistaaksesi roolin, poista siihen liittyvät allokaatiot.',
  'assignment.skill.name': 'Roolin nimi',
  'assignment.skill.allocationPercentage': 'Allokaatio %',
  'assignment.skill.hourlyPrice': 'Tuntihinta €/h',
  'assignment.skill.description': 'Kuvaus',
  'assignment.skill.editTitle': 'Roolin muokkaus',
  'assignment.skill.addTitle': 'Lisää rooli',
  'assignment.skill.role.view': 'Roolin tiedot',
  'assignment.role.requirement.skill.skillId': 'Taito',
  'assignment.role.requirement.skill.level': 'Taitotaso',
  'assignment.role.requirement.skill.experienceMonths': 'Kokemus',
  'assignment.role.requirement.industry.skillId': 'Toimiala',
  'assignment.role.requirement.industry.level': 'Taitotaso',
  'assignment.role.requirement.industry.experienceMonths': 'Kokemus',
  'assignment.role.requirement.role.skillId': 'Rooli',
  'assignment.role.requirement.role.level': 'Taitotaso',
  'assignment.role.requirement.role.experienceMonths': 'Kokemus',
  'assignment.role.requirement.language.languageCode': 'Kieli',
  'assignment.role.requirement.language.level': 'Taitotaso',
  'assignment.role.allocation.verified': 'Allokaatio vahvistettu',
  'assignment.role.allocation.multipleVerified': 'Monta vahvistettua tai alustavaa allokaatiota',
  'assignment.role.allocation.tentative': 'Monta alustavaa allokaatiota',
  'assignment.role.allocation.deleteConfirm': 'Haluatko varmasti poistaa allokaation?',
  'assignment.role.description': 'Roolin kuvaus',
  'assignment.propose.openProfile': 'Avaa profiili',
  'assignment.propose.noCandidates': 'Harmin paikka, Ei yhtään sopivaa kandidaattia.',
  'assignment.propose.noCandidates.description': 'Kandidaattien tulee olla saatavilla ja vaatimusten tulee täyttyä.',
  'assignment.propose.noCandidates.backButton': 'Takaisin toimeksiantoihin',
  'assignment.propose': 'Tarjoa kandidaatteja',
  'assignment.propose.proposed': 'Tarjotut',
  'assignment.propose.confirmed': 'Hyväksytty',
  'assignment.propose.addTitle': 'Luo tarjous',
  'assignment.propose.role': 'Rooli',
  'assignment.propose.criteria': 'Haku kriteerit',
  'assignment.propose.location': 'Paikka',
  'assignment.propose.remoteAllowed': 'Etätyö sallittu',
  'assignment.propose.start': 'Aloitus päivä',
  'assignment.propose.end': 'Lopetus päivä',
  'assignment.propose.allocationPercent': 'Allokointi prosentti',
  'assignment.propose.additionalInformation': 'Lisätiedot',
  'assignment.propose.minPrice': 'Minimi hinta €/h',
  'assignment.propose.maxPrice': 'Tavoitehinta €/h',
  'assignment.propose.added': 'Lisätty tarjoukseen',
  'assignment.propose.add': 'Lisää tarjoukseen',
  'assignment.propose.remove': 'Poista tarjouksesta',
  'assignment.propose.save': 'Lähetä tarjous',
  'assignment.propose.update': 'Päivitä tarjous',
  'assignment.propose.roleCandidates': 'Roolien kandidaatit',
  'assignment.propose.role.note.createdAt': 'Viimeksi päivittänyt: {{date}} ',
  'assignment.propose.role.note.showVersion': 'Näytä versiohistoria',
  'assignment.propose.role.note.versionHistory': 'Versiohistoria',
  'assignment.propose.role.note.version': 'Versio {{number}}',
  'assignment.propose.candidates': 'Näytä kandidaatit',
  'assignment.propose.role.header': 'Tarjoukset',
  'assignment.propose.role.requirements': 'Vaatimukset',
  'assignment.propose.role.location': 'Sijainti',
  'assignment.propose.role.remote': 'Etätyö sallittu',
  'assignment.propose.role.startDate': 'Alkupäivämäärä',
  'assignment.propose.role.endDate': 'Loppupäivämäärä',
  'assignment.propose.role.allocation': 'Allokaatio',
  'assignment.propose.role.downloadCV': 'Lataa CV',
  'assignment.propose.role.pricing': 'Hinta',
  'assignment.propose.role.match': 'Sopivuus',
  'assignment.propose.role.price': 'Tuntihinta',
  'assignment.propose.role.no-contact': 'Organisaatiolla ei ole kontaktihenkilöä ja tarjouksen tekijä on poistettu!',
  'assignment.propose.role.questions': 'Kysymykset ({{yes}}/{{count}})',
  'assignment.propose.role.status': 'Tila',
  'assignment.propose.role.createdAt': 'Luotu',
  'assignment.propose.role.additionalInfo': 'Lisätiedot',
  'assignment.propose.role.noEndDate': 'Ei loppua',
  'assignment.propose.role.contactPerson': 'Yhteyshenkilö',
  'assignment.propose.role.organization': 'Organisaatio',
  'assignment.propose.role.email': 'Sähköposti',
  'assignment.propose.role.telephone': 'Puhelin',
  'assignment.propose.price': 'Tuntihinta',
  'assignment.propose.bestOffer': 'paras tarjous',
  'assignment.propose.info':
    'tarjotuille käyttäjille luodaan alustava allokaatio toimeksiannon perusteella. Toimeksiantaja on yhteydessä tilanteen edetessä.',
  'assignment.propose.downloadAttachment': 'Lataa liite',
  'assignment.propose.freelancerCopy': 'Lähetä tarjouksen kopio sähköpostiini',
  'assignment.propose.sendCopiesTo': 'Lähetä tarjouksen kopio',
  'assignment.proposed.proposeToOwn': 'Tarjoa toimittajan puolesta',
  'assignment.propose.available': 'Näytä saatavilla olevat kandidaatit',
  'assignment.propose.showAll': 'Näytä kaikki',
  'assignment.propose.filterName': 'Hae nimellä',
  'assignment.propose.status.proposed': 'Tarjottu',
  'assignment.propose.status.accepted': 'Hyväksytty',
  'assignment.propose.status.rejected': 'Hylätty',
  'assignment.propose.status.closed': 'Suljettu',
  'assignment.propose.reject': 'Hylkää',
  'assignment.propose.accept': 'Hyväksy',
  'assignment.propose.rejectConfirm': 'Hylkää tarjous',
  'assignment.propose.rejectMessage': 'Hylkäys viesti',
  'assignment.propose.assignmentDescription': 'Toimeksiannon kuvaus',
  'assignment.propose.roleDescription': 'Roolin kuvaus',
  'assignment.propose.assingmentResolved': 'Toimeksianto päättynyt, tarjoaminen suljettu!',
  'assignment.propose.yesLabel': 'Hylkää',
  'assignment.propose.noLabel': 'Peruuta',
  'assignment.propose.details': 'Toimeksiannon tiedot',
  'assignment.propose.roles': 'Roolit',
  'assignment.propose.role.note': 'Muistio',
  'assignment.propose.role.rating': 'Suorituskykyarvio',
  'assignment.attachments.name': 'Nimi',
  'assignment.attachments.size': 'Koko',
  'assignment.attachments.description': 'Kuvaus',
  'assignment.view.tabs.list': 'Lista',
  'assignment.view.tabs.people': 'Allokoidut',
  'assignments.AssignmentResponses': 'Vastaukset',
  'assignment.workOrders.status': 'Tila',
  'assignment.workOrders.created': 'Luotu',
  'assignment.workOrders.responded': 'Vastattu',
  'assignment.workOrders.accepted': 'Hyväksytty',
  'assignment.workOrders.rejected': 'Hylätty',
  'assignment.workOrders.closed': 'Suljettu',
  'assignment.workOrders.rejectedBySupplier': 'Toimittajan hylkäämä',
  'assignment.workOrders.assignment': 'Toimeksianto',
  'assignment.workOrders.role': 'Rooli',
  'assignment.workOrders.responder': 'Toimittajan kontaktihenkilö',
  'assignment.workOrders.requested': 'Toimittajan kandidaatti',
  'assignment.workOrders.organization': 'Toimittaja',
  'assignment.workOrders.acceptedBySupplier': 'Hyväksytty toimittajalta',
  'assignment.workOrders.respondedAt': 'Vastauspäivä',
  'assignment.workOrders.contactPerson': 'kontaktihenkilö',
  'assignment.workOrder.view': 'Katsele vastausta',
  'assignment.workOrders.viewHeader': 'Vastaus',
  'assignment.workOrders.toProposed': 'Ehdotettuihin',
  'assignment.workOrders.supplierResponse': 'Vastaa kyselyyn',
  'assignment.workOrders.acceptSupplier.header': 'Vastaa kyselyyn',
  'assignmentResponses.info': 'Vastauksena tarvitsemme vastauksen näihin kysymyksiin:',
  'assignmentResponses.accepted': 'Hyväksytäänkö työtilaus kysely?',
  'assignmentResponses.subcontractor': 'Onko valittu henkilö alihankkija?',
  'assignmentResponses.accept': 'Lähetä',
  'assignment.workOrders.responder.name': 'Nimi',
  'assignment.workOrders.responder.phone': 'Puhelin',
  'assignment.workOrders.responder.email': 'Sähköposti',
  'assignment.workOrders.noResponse': 'Ei vastausta',
  'assignment.workOrders.responseBySupplier': 'Toimittajan vastaus',
  'assignment.workOrders.joinChat': 'Liity chattiin',
  'assignment.workOrders.noUser': 'Toimittaja ei ole valinnut käyttäjää',
  'assignment.workOrders.rejectMessage': 'Hylkäys viesti',
  'assignment.workOrders.changePerson': 'Vaihda henkilö',
  'assignment.workOrders.viewProfile': 'Katsele profiilia',
  'assignment.workOrders.acceptedAt': 'Hyväksymispäivä',
  'role.requirements': 'Vaatimukset',
  'role.questionnaire': 'Kysely',
  'role.RoleQuestionnaire.addTitle': 'Kysely',
  'role.RoleQuestionnaire.info': 'Kysymyksiin pitää pystyä vastaamaan "kyllä" tai "ei".',
  'role.RoleQuestionnaire.required': 'Kyselyyn vastaus pakollinen',
  'role.RoleQuestionnaire.question': 'Kysymys',
  'role.RoleQuestionnaire.addQuestion': 'Lisää kysymys',
  'role.RoleQuestionnaire.header': 'Kysely',
  'role.RoleQuestionnaire.required.info': 'Kyselyyn vastaus vaaditaan jokaiselle kandidaatille.',
  'role.RoleQuestionnaire.button': 'Kysely',
  'role.RoleQuestionnaire.currentlyanswering': 'Vastaamassa kandidaatille',
  'role.RoleQuestionnaire.withoutAnswer': 'Kysely vastaamatta: {{count}} kandidaatille',
  'role.RoleQuestionnaire.backToTop': 'Takaisin ylös',
  'role.subcontractor.header': 'Alihankkija',
  'role.subcontractor.switch': 'Onko kandidaatti alihankkija?',
  'role.subcontractor.submit': 'Tallenna',
  'assignments.proposals': 'tarjoukset',
  'assignments.proposals.outdated': 'Näytä vanhentuneet',
  'assignments.proposals.viewProposal': 'Katsele tarjousta',
  'assignments.proposals.openAssignment': 'Avaa toimeksianto',
  'assignments.proposals.viewHeader': 'Tarjouksen tiedot',
  'assignments.proposals.pricing': 'Hinta',
  'assignments.proposals.additionalInfo': 'Lisätiedot',
  'assignments.proposals.noInfo': 'Ei lisätietoja',
  'assignments.proposals.edit': 'Muokkaa tarjousta',
  'assignments.proposals.joinChat': 'Liity chattiin',
  'proposals.table.assignment': 'Toimeksianto',
  'proposals.table.created': 'Luotu',
  'proposals.table.role': 'Rooli',
  'proposals.table.startDate': 'Alkupäivä',
  'proposals.table.endDate': 'Loppupäivä',
  'proposals.table.deadline': 'Määräaika',
  'proposals.table.creator': 'Luoja',
  'proposals.table.contactPeople': 'Yhteyshenkilöt',
  'proposals.table.proposedCount': 'Tarjotut',
  'proposals.table.candidates': 'Kandidaatit',
  'proposals.table.status': 'Tila',
  'proposals.table.updated': 'Päivitetty',
  'navigation.chat': 'Keskuteluhuoneet',
  'chat.join': 'Liity',
  'chat.room': 'Keskusteluhuone',
  'chat.room.deleteText': 'Haluatko varmasti poistaa {{name}} huoneen?',
  'chat.rooms': 'Huoneet',
  'chat.invites': 'Kutsut',
  'chat.reconnect': 'Yhdistä uudelleen',
  'chat.users': 'Jäsenet',
  'chat.leave': 'Poistu huoneesta',
  'chat.invite': 'Kutsu',
  'chat.roomName': 'Keskusteluhuoneen nimi',
  'chat.members': 'Jäseniä',
  'chat.invited': 'Kutsuttuja',
  'chat.edit': 'Muokkaa',
  'chat.goto': 'Mene huoneeseen',
  'chat.newMessage': 'Uusi viesti huoneessa',
  'chat.title': 'Keskusteluhuoneet',
  'chat.modal.addTitle': 'Lisää keskusteluhuone',
  'chat.modal.editTitle': 'Muokkaa keskusteluhuonetta',
  'chat.modal.name': 'Huoneen nimi',
  'chat.modal.roomMembers': 'Huoneen jäsenet',
  'chat.modal.addMember': 'Lisää jäsen',
  'chat.modal.room-exists-error': 'Keskusteluhuone on jo olemassa!',
  'chat.modal.members-required': 'Keskusteluhuoneen jäsenet tarvitaan!',
  'chat.proposalConflict': 'Olet toimeksiannon lisääjä ja ehdottaja, ei löydy kenen kanssa keskustella!',
  'chat.cryto': 'Kaikki viestit tallennetaan tietokantaan salattuina.',
  'chat.type.assignment':
    'Tähän keskusteluhuoneeseen pääsevät kaikki toimeksiannon näkevät. Älkää lähettäkö mitään salassa pidettävää, kiitos!',
  'chat.type.proposal': 'Tähän keskusteluhuoneeseen pääsevät vain ehdottaja ja toimeksiannon omistaja.',
  'chat.type.workorder': 'Tähän keskusteluhuoneeseen pääsevät vain toimittaja ja työtilauksen omistaja.',
  'chat.type.general': 'Tämä on yleinen keskusteluhuone, johon pääsevät vain kutsutut käyttäjät.',
  'chat.scrollToBottom': 'Siirry loppuun',
  'chat.connect': 'Yhdistä',
  'chat.toAssignment': 'Toimeksiantoon',
  'chat.user': 'Käyttäjä ',
  'chat.left': ' poistui',
  'chat.joined': ' liittyi',
  'chat.assignmentName': 'Toimeksiannon nimi: {{name}}',
  'chat.anonUser': 'Käyttäjä',
  'chat.messages': 'Viestit',
  'attachments.description': 'Kuvaus',
  'attachments.description.placeholder': 'Lisää ensin tiedosto, kirjoita sitten kuvaus',
  'attachments.download-action': 'Lataa liite',
  'attachments.upload-action': 'Lataa tiedosto',
  'attachment.proposal.remove': 'Poista liite {{assignmentName}} toimeksiannon tarjouksesta.',
  'education.title': 'Koulutus',
  'degrees.title': 'Tutkinnot',
  'certificates.title': 'Sertifikaatit',
  'courses.title': 'Kurssit',
  'profile.education.modal.startDate': 'Alkupäivämäärä',
  'profile.education.modal.endDate': 'Loppupäivämäärä',
  'profile.education.modal.school': 'Koulu',
  'profile.education.modal.degree': 'Tutkinto',
  'profile.education.modal.description': 'Kuvaus',
  'profile.education.modal.editTitle': 'Muokkaa tutkintoa',
  'profile.education.modal.addTitle': 'Uusi tutkinto',
  'profile.education.modal.charLeft': 'Merkkiä jäljellä (suositus):',
  'profile.education.add': 'Lisää koulutus',
  'profile.course.modal.editTitle': 'Muokkaa kurssia',
  'profile.course.modal.addTitle': 'Uusi kurssi',
  'profile.course.modal.startDate': 'Alkupäivämäärä',
  'profile.course.modal.endDate': 'Loppupäivämäärä',
  'profile.course.modal.course': 'Kurssi',
  'profile.course.modal.organizer': 'Järjestäjä',
  'profile.course.modal.description': 'Kuvaus',
  'profile.course.modal.charLeft': 'Merkkiä jäljellä (suositus):',
  'profile.certificate.modal.editTitle': 'Muokkaa sertifikaattia',
  'profile.certificate.modal.addTitle': 'Uusi sertifikaatti',
  'profile.certificate.modal.certificate': 'Sertifikaatti',
  'profile.certificate.modal.certifier': 'Myöntäjä',
  'profile.certificate.modal.endDate': 'Erääntyy',
  'profile.certificate.modal.licenceNumber': 'Lisenssinumero',
  'profile.certificate.modal.url': 'URL',
  'profile.certificate.modal.issueDate': 'Myönnetty pvm.',
  'profile.certificate.modal.description': 'Kuvaus',
  'profile.certificate.modal.charLeft': 'Merkkiä jäljellä (suositus):',
  'profile.freefi.text': 'Tee työ, luo lasku, nosta palkkaa. Keskity olennaiseen.',
  'profile.freefi.button': 'Aloita',
  'url.modal.editTitle': 'Osoitteen muokkaus',
  'url.modal.addTitle': 'Osoitteen lisäys',
  'url.modal.url': 'Web-osoite',
  'url.modal.description': 'Kuvaus',
  'statistics.scoutTitle': 'Caleo Tutka tulokset',
  'statistics.scoutPlusTitle': 'Caleo Tutka Plus tulokset',
  'statistics.scoutUsers': 'Käyttäjät',
  'statistics.allUsers': 'Kaikki käyttäjät',
  'statistics.user': 'Käyttäjä',
  'statistics.scoreNormal': 'Normaali',
  'statistics.scorePressured': 'Paineen alla',
  'confirmation.yes': 'Kyllä',
  'confirmation.no': 'Ei',
  'confirmation.ok': 'Jatka',
  'confirmation.site.data': 'Toimipisteeseen on määritetty työntekijöitä',
  'confirmation.site.text': 'Siirrä heidät ensin!',
  'confirmation.text': 'Haluatko varmasti jatkaa?',
  'confirmation.organization.text': 'Haluatko varmasti poistaa organisaation?',
  'confirmation.user.data': 'Käyttäjällä {{name}} on sisältöä!',
  'confirmation.skill.data': 'Taitoa {{name}} käytetään projektissa',
  'confirmation.skill.text': 'Haluatko varmasti poistaa?',
  'confirmation.skill.deleteText': 'Haluatko varmasti poistaa {{name}} taidon?',
  'confirmation.industry.data': 'Toimialaa {{name}} käytetään projektissa',
  'confirmation.organization.data': 'Organisaatiolla on työntekijöitä, toimeksiantoja tai tiimejä',
  'confirmation.assignments.data': 'Seuraavat toimeksiannot tullaan poistamaan.',
  'confirmation.teams.data': 'Seuraavat tiimit tullaan poistamaan.',
  'confirmation.industry.text': 'Haluatko varmasti poistaa?',
  'confirmation.industry.deleteText': 'Haluatko varmasti poistaa {{name}} toimialan?',
  'confirmation.person.data': 'Seuraavat asiantuntijat tullaan jättämään Caleoon freelancereina.',
  'confirmation.role.data': 'Roolia {{name}} käytetään projektissa',
  'confirmation.role.text': 'Haluatko varmasti poistaa?',
  'confirmation.role.deleteText': 'Haluatko varmasti poistaa {{name}} roolin?',
  'no-undo': 'Toimintoa ei voi peruuttaa.',
  'confirmation.user.text': 'Kaikki käyttäjään liittyvät tiedot poistetaan lopullisesti.',
  'confirmation.networkManage': 'Käyttäjä poistetaan JÄRJESTELMÄSTÄ ei vain verkostosta!',
  'confirmation.networkManage.confirm': 'Varmista poisto organisaation omistajalta tai freelancerilta.',
  'site.person.name': 'Nimi',
  'site.person.email': 'Sähköposti',
  fieldRequiredLabel: '{{label}} -kenttä on pakollinen.',
  fieldRequired: 'Kenttä on pakollinen.',
  edit: 'Muokkaa',
  open: 'Avaa',
  'login.failed': 'Kirjautuminen epäonnistui!',
  'login.notActivated': 'Tiliä ei ole aktivoitu!',
  'login.wrongPassword': 'Väärä sähköposti tai salasana!',
  'footer.support': 'Tuki',
  'error.header': 'Oho! Jokin meni vikaan.',
  'error.ok': 'Jatka',
  'error.generic': 'Ota yhteyttä tukeen!',
  'error.postUserEmailInUse': 'Sähköpostiosoite on jo käytössä!',
  'error.general': 'Ota yhteyttä tukeen, liitä virhekoodi mukaan: {{id}}',
  'error.positive': 'Anna positiivinen numero',
  'error.biggerThanMin': 'Maksimihinnan on oltava vähimmäishintaa suurempi',
  'error.telephone.provideInInternationalFormat': 'Anna puhelinnumero kansainvälisessä muodossa',
  save: 'Tallenna',
  update: 'Tallenna muutos',
  delete: 'Poista',
  saveAndAdd: 'Tallenna ja lisää uusi',
  changesSaved: 'Muutokset tallennettu',
  add: 'Lisää',
  cancel: 'Peruuta',
  savePromote: 'Tallenna käännös',
  'language.title': 'Kielitaito',
  'language.editTitle': 'Muokkaa kieltä',
  'language.addTitle': 'Lisää kieli',
  'language.languageCode': 'Kieli',
  'language.name': 'Kieli',
  'language.level': 'Taso',
  'language.basic': 'Perusteet',
  'language.moderate': 'Kohtalainen',
  'language.good': 'Hyvä',
  'language.excellent': 'Erinomainen',
  'language.native': 'Äidinkieli',
  'language.addLanguage': 'Lisää kieli',
  'language.select': 'Valitse kieli',
  'language.description.a2':
    'Ymmärtää lauseita ja usein käytettyjä ilmauksia, jotka liittyvät tavallisimpiin arkipäivän tarpeisiin: kaikkein keskeisin häntä itseään ja perhettä koskeva tieto, ostosten teko, paikallistieto, työ. Pystyy viestimään yksinkertaisissa ja rutiininomaisissa tehtävissä, jotka edellyttävät yksinkertaista tiedonvaihtoa tutuista, jokapäiväisistä asioista. Pystyy kuvailemaan yksinkertaisesti omaa taustaansa, lähiympäristöään ja välittömiä tarpeitaan.',
  'language.description.b1':
    'Ymmärtää pääkohdat selkeistä yleiskielisistä viesteistä, joita esiintyy usein esimerkiksi työssä, koulussa ja vapaaaikana. Selviytyy useimmista tilanteista matkustaessaan kohdekielisillä alueilla. Pystyy tuottamaan yksinkertaista, johdonmukaista tekstiä tutuista tai itseään kiinnostavista aiheista. Pystyy kuvaamaan kokemuksia ja tapahtumia, unelmia, toiveita ja tavoitteita. Pystyy perustelemaan ja selittämään lyhyesti mielipiteitä ja suunnitelmia.',
  'language.description.b2':
    'Ymmärtää pääajatukset konkreetteja ja abstrakteja aiheita käsittelevistä monitahoisista teksteistä, myös oman erityisalansa käsittelystä. Viestiminen on niin sujuvaa ja spontaania, että hän pystyy säännölliseen vuorovaikutukseen syntyperäisten kanssa ilman että se vaatii kummaltakaan osapuolelta ponnisteluja. Pystyy tuottamaan selkeää, yksityiskohtaista tekstiä hyvinkin erilaisista aiheista, esittämään mielipiteensä jostakin ajankohtaisesta asiasta ja selittämään eri vaihtoehtojen edut ja haitat.',
  'language.description.c1':
    'Ymmärtää erityyppisiä vaativia, pitkähköjä tekstejä ja tunnistaa piilomerkityksiä. Pystyy esittämään ajatuksiaan sujuvasti ja spontaanisti ilman havaittavia vaikeuksia ilmausten löytämisessä. Käyttää kieltä joustavasti ja tehokkaasti sekä sosiaalisiin että myös opintoihin ja ammattiin liittyviin tarkoituksiin. Pystyy tuottamaan monimutkaisia aiheita käsittelevää selkeää, hyvin rakentunutta ja yksityiskohtia sisältävää tekstiä. Osaa jäsentää tekstiä ja edistää sen sidosteisuutta esimerkiksi käyttämällä sidesanoja.',
  'language.description.c2':
    'Ymmärtää yleensä vaikeuksitta kaikenlaista puhuttua ja kirjoitettua kieltä. Osaa yhdistellä tietoja erilaisista puhutuista ja kirjoitetuista lähteistä ja rakentaa niissä esitetyistä perusteluista ja selostuksista sisällöllisesti yhtenäisen esityksen. Pystyy ilmaisemaan ajatuksiaan spontaanisti, erittäin sujuvasti ja täsmällisesti. Pystyy erottamaan merkitysvivahteet mutkikkaissakin tilanteissa.',
  'assignments.title': 'Toimeksiannot',
  'employees.title': 'Työntekijät',
  'resources.searchEmployees': 'Etsi työntekijöitä',
  'resources.searchEmployees.simple': 'Etsi konsultteja',
  'resources.searchEmployees.simpleView': 'Yksinkertainen haku',
  'resources.searchEmployees.advancedView': 'Lisäasetukset',
  'resources.searchExperts': 'Etsi asiantuntijoita',
  'resources.searchEmployees.typeHere': 'Kirjoita tähän',
  'resources.searchEmployees.typeOrSelect': 'Kirjoita tai valitse',
  'resources.searchEmployees.disabled': '-- POIS KÄYTÖSTÄ --',
  'resources.searchEmployees.availableOnly': 'Naytä vain vapaat',
  'resources.searchEmployees.showDraftCVs': 'Näytä myös luonnokset',
  'resources.searchEmployees.aiAssisted': 'Tekoälyllä avustettu haku',
  'resources.searchEmployees.name': 'Nimi',
  'resources.searchEmployees.location': 'Sijainti',
  'resources.searchEmployees.networks': 'Haun kohdennus',
  'resources.searchEmployees.availability': 'Saatavuus',
  'resources.searchEmployees.startDate': 'Alkaen',
  'resources.searchEmployees.endDate': 'Saakka',
  'resources.searchEmployees.businessArea': 'Liiketoiminta-alue',
  'resources.searchEmployees.customer': 'Organisaatio',
  'resources.searchEmployees.skill.skillId': 'Tarvittavat taidot',
  'resources.searchEmployees.skill.experienceMonths': 'Vaadittavat kokemuskuukaudet',
  'resources.searchEmployees.skill.required': 'Vaaditaan',
  'resources.searchEmployees.certificate': 'Tarvittavat sertifikaatit',
  'resources.searchEmployees.help': 'Ohje',
  'resources.searchEmployees.clear': 'Tyhjennä',
  'resources.searchEmployees.search': 'Hae',
  'resources.searchEmployees.allOrganizations': 'Kaikki sallitut organisaatiot',
  'resources.searchEmployees.networkOrganizations': 'Kaikki verkoston organisaatiot',
  'resources.searchEmployees.userOrganizations': 'Vain oma organisaationi',
  'resources.searchEmployees.add': 'Lisää',
  'resources.searchEmployees.requiredField': 'Vaadittu kenttä',
  'resources.searchEmployees.degree': 'Tarvittavat tutkinnot',
  'resources.searchEmployees.course': 'Tarvittavat kurssit',
  'resources.searchEmployees.industry.skillId': 'Tarvittavat toimialat',
  'resources.searchEmployees.language.languageCode': 'Tarvittavat kielet',
  'resources.searchEmployees.role.skillId': 'Tarvittavat roolit',
  'resources.searchEmployees.project.count': 'Määra',
  'resources.searchEmployees.all.skillId': 'Taidot, toimialat, roolit',
  'resources.searchEmployees.otherCriterias': 'muuta hakuehtoja asetettu.',
  'resources.searchResults.perfectResults': '{{count}} Täydellistä osumaa',
  'resources.searchResults.allResults': '{{count}} Osumaa (osittaiset ja täydelliset osumat)',
  'resources.searchResults.allMatches': 'Näytä kaikki',
  'resources.searchResults.perfectMatches': 'Täydelliset tulokset',
  'resources.searchResults.partialMatches': 'Osittaiset tulokset',
  'resources.searchResults.aiMatches': 'AI tulokset',
  'resources.searchResults.allocations': 'Allokaatiot',
  'resources.searchResults.perfectMatch': 'Täydellinen hakutulos',
  'resources.searchResults.missingRole': 'Konsultti',
  'resources.searchResults.emptyResult.title': 'Eikö sopivaa tulosta löytynyt?',
  'resources.searchResults.emptyResult.text': 'Pyydä ilmainen shortlist sopivista kandidaateista',
  'resources.searchResults.emptyResult.request': 'Lähetä pyyntö tästä',
  'resources.searchResults.skill': 'Taito',
  'resources.searchResults.experience': 'Kokemus',
  'resources.searchResults.level': 'Taso',
  'resources.searchResults.interest': 'Kiinnostus',
  'resources.searchResults.lastUsed': 'Viimeksi käytetty',
  'resources.userOrganizationResults.title': 'Oma organisaatio',
  'resources.userOrganizationResults.available': 'Hae vain saatavilla olevat',
  'search.propose.addTitle': 'Tarjoa toimeksiantoa',
  'search.propose.city': 'Kaupunki',
  'search.propose.remoteAllowed': 'Etätyö sallittu',
  'search.propose.percent': 'Allokaatio %',
  'search.propose.pricePerHour': 'Tuntihinta €/h',
  'search.propose.startDate': 'Alkupäivämäärä',
  'search.propose.duration': 'Kesto (kuukausina)',
  'search.propose.extensionPossible': 'Jatko mahdollinen',
  'search.propose.information': 'Lisätietoja',
  'search.propose.submit': 'Lähetä',
  'search.propose.success': 'Tarjous lähetetty',
  'search.propose.save': 'Lähetä',
  'search.propose.moreOptions': 'Lisää vaihtoehtoja',
  'search.request.success': 'Pyynnös lähetetty',
  'search.request.addTitle': 'Pyydä shortlist',
  'search.request.city': 'Kaupunki',
  'search.request.remoteAllowed': 'Etätyö sallittu',
  'search.request.percent': 'Allokaatio %',
  'search.request.pricePerHour': 'Tuntihinta €/h',
  'search.request.startDate': 'Alkupäivämäärä',
  'search.request.duration': 'Kesto (kuukausina)',
  'search.request.extensionPossible': 'Jatko mahdollinen',
  'search.request.information': 'Lisätietoja',
  'search.request.submit': 'Lähetä',
  'search.request.save': 'Lähetä',
  'search.request.moreOptions': 'Lisää vaihtoehtoja',
  'search.skillAlreadyExists': 'Kyseinen taito on jo valittu',
  'search.roleAlreadyExists': 'Kyseinen rooli on jo valittu',
  'search.certificateAlreadyExists': 'Kyseinen sertifikaatti on jo valittu',
  'search.languageAlreadyExists': 'Kyseinen kieli on jo valittu',
  'search.degreeAlreadyExists': 'Kyseinen tutkinno on jo valittu',
  'search.courseAlreadyExists': 'Kyseinen kurssi on jo valittu',
  'search.industryAlreadyExists': 'Kyseinen toimiala on jo valittu',
  'search.loadMore': 'Lataa lisää',
  'search.noNetworks': 'Haun kohdennusta ei ole määritelty.',
  'search.experienceMonths': 'Kokemus kuukausina',
  'search.required': 'Merkitys',
  'search.industryMatch': 'Sopivat toimialat',
  'search.roleMatch': 'Sopivat roolit',
  'search.languageMatch': 'Sopivat kielet',
  'search.degreeMatch': 'Sopivat tutkinnot',
  'search.courseMatch': 'Sopivat kurssit',
  'search.projectCountMatch': 'sopivaa projektia.',
  'search.projectCount': 'Tarvittavat projektit',
  'search.sortingPerfect': 'Järjestä täydelliset',
  'search.allSkills': 'Etsi taitoja, toimialoja tai rooleja',
  'team.title': 'Tiimit',
  'team.noTeam': 'Käyttäjällä ei ole tiimiä.',
  'team.modal.addTitle': 'Luo tiimi',
  'team.modal.editTitle': 'Muokkaa tiimiä',
  'team.modal.delete': 'Poista',
  'team.modal.update': 'Tallenna muutos',
  'team.modal.save': 'Tallenna',
  'team.modal.name': 'Tiimin nimi',
  'team.modal.description': 'Kuvaus',
  'team.modal.manager': 'Tiiminvetäjä',
  'team.modal.hidden': 'Piilotettu',
  'team.modal.addMember': 'Lisää jäsen',
  'team.modal.organization': 'Organisaatio',
  'team.modal.charLeft': 'Merkkiä jäljellä (suositus):',
  'team.modal.showAll': 'Näytä kaikki tiimit',
  'team.modal.person': 'Henkilö',
  'team.modal.managerOnClick': 'Vaihda tiiminvetäjäksi painamalla nimestä.',
  'team.modal.limitAssignments': 'Rajoita toimeksiantojen näkyvyys vain tiimille määritettyihin toimeksiantoihin',
  'team.teamLeads': 'Tiiminvetäjät',
  'team.teamMembers': 'Tiimin jäsenet',
  'team.members': 'Jäsenet',
  'team.membersCount': 'Jäsentä',
  'team.openProfile': 'Avaa profiili',
  'team.allTeams': 'Kaikki tiimit',
  'team.filters': 'Suodata tiimejä',
  'team.skillPicker': 'Taidot',
  'team.rolePicker': 'Roolit',
  'team.industryPicker': 'Toimialat',
  'team.certificateField': 'Sertifikaatit',
  'team.skills': 'Taidot',
  'team.roles': 'Roolit',
  'team.industries': 'Toimialat',
  'team.certificates': 'Sertifikaatit',
  'team.noName': 'Ei nimeä',
  'team.listHeader': 'Tiimi lista',
  'team.name': 'Nimi',
  'team.description': 'Kuvaus',
  'team.info': 'Lisätiedot',
  'team.limitAssignments': 'Toimeksiantojen näkyvyys rajattu vain tiimille määritettyihin toimeksiantoihin',
  'team.limitAssignmentShort': 'Rajoitettu toimeksianto näkyvyys',
  'team.limitWorkOrders': 'Työtilausten näkyvyys rajattu vain tiimille määritettyihin työtilauksiin',
  'team.limitWorkOrderShort': 'Rajoitettu tyotilaus näkyvyys',
  'admin.title': 'Hallinta',
  'picker.selectAll': 'Valitse kaikki',
  'picker.deselectAll': 'Poista valinnat',
  'custom-table.all': 'Kaikki',
  'custom-table.labelRowsSelect': 'Rivejä sivulla:',
  'custom-table.labelDisplayedRows': '{{from}}-{{to}}, {{count}} rivistä',
  'custom-table.delete': 'Poista',
  'custom-table.edit': 'Muokkaa',
  'custom-table.labelSortBy': 'Järjestä sarake',
  'custom-table.emptyDataSourceMessage': 'Ei näytettävää sisältöä',
  'custom-table.searchTooltip': 'Hae',
  'custom-table.searchPlaceholder': 'Hae',
  'custom-table.nRowsSelected': '{0} riviä valittu',
  'custom-table.records': 'riviä...',
  'custom-table.rowsPerPage': 'Rivejä sivulla',
  'custom-table.clear': 'Tyhjennä',
  'monthYear.month': 'MM.YYYY',
  'monthYear.day': 'DD.MM.YYYY',
  'dateResolution.fullDate': 'Päivämäärä',
  'dateResolution.month': 'KK ja vuosi',
  'dateResolution.year': 'Vuosi',
  'reference.title': 'Suositukset',
  'profile.reference.modal.editTitle': 'Muokkaa suositusta',
  'profile.reference.modal.addTitle': 'Lisää suositus',
  'profile.reference.modal.firstName': 'Etunimi',
  'profile.reference.modal.lastName': 'Sukunimi',
  'profile.reference.modal.letter': 'Teksti',
  'profile.reference.modal.company': 'Yhtiö',
  'profile.reference.modal.charLeft': 'Merkkiä jäljellä (suositus):',
  'profile.reference.add': 'Lisää suositus',
  'employer.title': 'Työkokemus',
  'profile.employer.role': 'Rooli',
  'profile.employer.roles': 'Rooli-tägit',
  'profile.employer.modal.editTitle': 'Muokkaa työkokemusta',
  'profile.employer.modal.addTitle': 'lisää työkokemus',
  'profile.employer.modal.close': 'Sulje',
  'profile.employer.modal.name': 'Organisaatio',
  'profile.employer.modal.monthyear': 'Aika',
  'profile.employer.modal.startDate': 'Alku',
  'profile.employer.modal.endDate': 'Loppu',
  'profile.employer.modal.role': 'Rooli-tägit',
  'profile.employer.modal.primaryRole': 'Rooli',
  'profile.employer.modal.description': 'Kuvaus',
  'profile.employer.modal.help': 'Ohjeita',
  'profile.employer.modal.delete': 'Poista',
  'profile.employer.modal.save': 'Tallenna',
  'profile.employer.modal.makeHistory': 'Make History',
  'profile.employer.modal.charLeft': 'Merkkiä jäljellä (suositus):',
  'profile.employer.model.convertToProject': 'Muuta projektiksi',
  'profile.employer.add': 'Lisää työkokemus',
  'months.0': 'Tammikuu',
  'months.1': 'Helmikuu',
  'months.2': 'Maaliskuu',
  'months.3': 'Huhtikuu',
  'months.4': 'Toukokuu',
  'months.5': 'Kesäkuu',
  'months.6': 'Heinäkuu',
  'months.7': 'Elokuu',
  'months.8': 'Syyskuu',
  'months.9': 'Lokakuu',
  'months.10': 'Marraskuu',
  'months.11': 'Joulukuu',
  'months.short.0': 'Tammi',
  'months.short.1': 'Helmi',
  'months.short.2': 'Maalis',
  'months.short.3': 'Huhti',
  'months.short.4': 'Touko',
  'months.short.5': 'Kesä',
  'months.short.6': 'Heinä',
  'months.short.7': 'Elo',
  'months.short.8': 'Syys',
  'months.short.9': 'Loka',
  'months.short.10': 'Marras',
  'months.short.11': 'Joulu',
  'weekdaysShort.1': 'M',
  'weekdaysShort.2': 'T',
  'weekdaysShort.3': 'K',
  'weekdaysShort.4': 'T',
  'weekdaysShort.5': 'P',
  'weekdaysShort.6': 'L',
  'weekdaysShort.0': 'S',
  'admin.employee.addTitle': 'Lisää työntekijä',
  'admin.employee.editTitle': 'Muokkaa työntekijää',
  'admin.employee.firstName': 'Etunimi',
  'admin.employee.lastName': 'Sukunimi',
  'admin.employee.telephone': 'Puhelinumero',
  'admin.employee.email': 'Sähköposti',
  'admin.employee.organizationId': 'Organisaatio',
  'admin.employee.siteId': 'Toimipiste',
  'admin.employee.name': 'Nimi',
  'admin.employee.role': 'Rooli',
  'admin.employee.lastLogin': 'Viimeksi kirjautunut',
  'admin.employee.resetPassword':
    'Lähetä tilin aktivointi uudelleen (ei-aktivoitu tili) tai resetoi salasana sähköpostilla',
  'admin.employee.passwordReset': 'Aktivointi tai salasanan resetointi sähköposti lähetettiin!',
  'admin.employee.edit': 'Muokkaa työntekijän tietoja',
  'admin.employee.add': 'Lisää työntekijä',
  'admin.employee.visibility': 'Verkostonäkyvyys',
  'admin.employee.networkInfo':
    'Henkilö näkyy verkostoissa, joihin yrityksesi kuuluu. Lisätietoa löydät "omat-verkostot"-sivulta.',
  'admin.employee.remindCvUpdate': 'Muistuta käyttäjää päivittämään profiilinsa',
  'admin.employee.toProfile': 'Siirry käyttäjän profiiliin',
  'admin.employee.notifications': 'Aseta organisaation kontaktihenkilöksi',
  'admin.employee.language': 'Käyttöliittymän kieli',
  'admin.employee.disabled': 'Tili disabloitu',
  'admin.employee.enabled': 'Tili enabloitu',
  'admin.employee.disable': 'Disabloi tili',
  'admin.employee.enable': 'Enabloi tili',
  'isDefaultCv.header': 'Profiilin tietojen lisäys ja ylläpito',
  'isDefaultCv.unset': 'Asiantuntija itse',
  'isDefaultCv.set': 'Myynti ja yrityksen hallinto',
  'isDefaultCv.unset.helperText':
    'Oletuksena myynnillä ja yrityksen ylläpidolla ei ole oikeutta muuttaa profiilin tietoja. Asiantuntija hallitsee profiilin tietoja ensisijaisesti.',
  'isDefaultCv.set.helperText':
    'Myynti ja yrityksen ylläpito hoitaa ensisijaisesti asiantuntijan profiilin tietojen lisäyksen ja ylläpidon. Asiantuntijalla on myös omaan profiiliinsa muokkausoikeudet.',
  sendActivation: 'Lähetä aktivointi sähköposti',
  'admin.title.employees': 'Työntekijät',
  'admin.organization.site.streetAddress': 'Katuosoite',
  'admin.organization.site.title': 'Toimipisteet',
  'admin.organization.site.postalCode': 'Postinumero',
  'admin.organization.site.city': 'Kaupunki',
  'admin.organization.site.countryCode': 'Maa',
  'admin.organization.site.isHq': 'Päätoimipiste',
  'admin.organization.addTitle': 'Lisää organisaatio',
  'admin.organization.editTitle': 'Muokkaa organisaatiota',
  'admin.organization.countryLabel': 'Maa',
  'admin.organization.exportColors': 'Tulosteen värit',
  'admin.organization.color': 'CV-bannerin väri',
  'admin.organization.accentColor': 'CV-otsikoiden väri',
  'admin.organization.name': 'Nimi',
  'admin.organization.companyIdentifier': 'Y-tunnus',
  'admin.organization.addSite': 'Lisää toimipiste',
  'admin.organization.personnel': 'Henkilökunta',
  'admin.organization.turnover': 'Liikevaihto',
  'admin.organization.description': 'Kuvaus',
  'admin.organization.address': 'Osoite',
  'admin.organization.contactPerson': 'Yhteyshenkilö',
  'admin.organization.contactPersons': 'Yhteyshenkilöt',
  'admin.organization.eachExpert': 'Jokainen asiantuntija',
  'admin.organization.singleContact': 'Yksi yhteyshenkilö',
  'admin.organization.eachExpertSingleContact': 'Jokainen asiantuntija ja yksi yhteyshenkilö',
  'admin.organization.selectContact': 'Valitse yhteyshenkilö',
  'admin.organization.networkShare':
    'Verkostoon kuuluessa organisaation allokaatioista jaetaan vain koko ja kesto. Tiedot jaetaan vain verkostoille joissa organisaatio on jäsenenä.',
  'admin.organization.parseCredits': 'Parsimiskrediitit',
  'admin.organization.defaultLanguage': 'Käyttöliittymän oletuskieli',
  'admin.organization.caleoOwner': 'Caleon omistaja',
  'admin.organization.tenantId': 'TenentID Azure AD kirjautumiseen',
  'admin.organization.emailDomain': 'Sähköposti domain Azure AD tenantille',
  'admin.organization.logo': 'Logo',
  'admin.organization.exportSkillsExperience': 'Lataa taito ja kokemus tiedot',
  'admin.organization.allowCreateNetworks': 'Salli verkoston luonti',
  'admin.organization.assignmentEndReminder': 'Lähetä toimeksiannon 60 päivän loppu muistutus',
  'companyAdmin.organizationEdit.title': 'Yrityksen tiedot',
  'admin.title.organizations': 'Organisaatiot',
  'admin.title.userManual': 'Käyttöopas',
  'admin.title.widgets': 'Widgetit',
  'admin.widget.table.name': 'Nimi',
  'admin.widget.table.type': 'Tyyppi',
  'admin.widget.table.url': 'URL',
  'admin.widget.organizationId': 'Organisaatio',
  'admin.widget.name': 'Widgetin nimi',
  'admin.widget.type': 'Widgetin tyyppi',
  'admin.widget.description': 'Kuvaus',
  'admin.widget.showCode': 'Näytä koodi',
  'admin.widget.edit': 'Muokkaa asetuksia',
  'admin.widget.editTitle': 'Muokka widgettiä',
  'admin.widget.addTitle': 'Lisää uusi widget',
  'admin.widget.networks': 'Valitse hakuskaala',
  'admin.widget.leadEmail': 'Vihjesähköposti',
  'admin.widget.code': 'Widget koodi',
  'admin.widget.code.helperText': 'Lisää tämä koodi nettisivulle käyttääksesi widgettiä:',
  'admin.widget.head': 'Lisää tämä <head> osioon',
  'admin.widget.body': 'Lisää tämä siihen mihin widgetti halutaan',
  'admin.widget.embeddedSearch': 'Upotettu haku',
  'admin.widget.somethingElse': 'Something else listed',
  'admin.widget.leadEmail.helperText': 'Hakua käyttävien asiakkaiden jättämien vihjeiden vastaanotto sähköpostiosoite.',
  'admin.widget.origin': 'URL johon widget liitetään',
  'admin.organization.site.addTitle': 'Lisää toimipiste',
  'admin.organization.site.editTitle': 'Muokkaa toimipistettä',
  'admin.organization.site.countryLabel': 'Maa',
  'admin.organization.downloadAllCVs': 'Lataa kaikki CV:t',
  'admin.organization.exportAllocations': 'Lataa organisaation allokaatiodata',
  'admin.organization.featureset': 'Toiminnot',
  'admin.organization.contactPeople.info': 'Käyttäjät saavat tiedon jaetuista toimeksiannoista.',
  'admin.organization.organizationDetails': 'Organisaation tiedot',
  'organizationExtraField.operationalControlSystem': 'Toiminnanohjausjärjestelmä',
  'organizationExtraField.usedApplications': 'Käytettävät ohjelmat',
  'organizationExtraField.helpText': 'Kirjoita ja paina enter lisätäksesi puuttuva',
  'organizationExtraField.header': 'Organisaation lisäkentät',
  'admin.permission.groupType.admin': 'Hallinta',
  'admin.permission.groupType.inputDataManager': 'Sisältömanageri',
  'admin.permission.groupType.companyAdmin': 'Yrityshallinta',
  'admin.permission.groupType.sales': 'Myynti',
  'admin.permission.groupType.freelancer': 'Freelancer',
  'admin.permission.groupType.recruit': 'Rekrytoitava',
  'admin.permission.groupType.teamLeader': 'Tiiminvetäjät',
  'admin.permission.group.addTitle': 'Lisää ryhmä',
  'admin.permission.group.editTitle': 'Muokkaa ryhmää',
  'admin.permission.group.name': 'Nimi',
  'admin.permission.group.description': 'Kuvaus',
  'admin.permission.memberName': 'Nimi',
  'admin.permission.removeMember': 'Poista jäsen',
  'admin.permission.removeMemberConfirm': 'Haluatko varmasti poistaa {{name}} jäsenen ryhmästä?',
  'admin.permission.groupDelete': 'Poista oikeusryhmä',
  'admin.permission.removeGroupConfirm': 'Haluatko varmasti poistaa {{name}} oikeusryhmän käyttäjältä?',
  'admin.permission.permissionCRUD': 'CRUD',
  'admin.permission.permissionObject': 'Objekti',
  'admin.permission.permissionType': 'Tyyppi',
  'admin.permission.editPermission': 'Muokkaa oikeutta',
  'admin.permission.removePermission': 'Poista oikeus',
  'admin.permission.group.title': 'Ryhmä',
  'admin.permission.group.membersTitle': 'Jäsenet',
  'admin.permission.addMember': 'Lisää jäsen',
  'admin.permission.group.permissionsTitle': 'Oikeudet',
  'admin.permission.addPermission': 'Lisää oikeus',
  'admin.permission.user': 'Käyttäjä',
  'admin.permission.addMemberTitle': 'Lisää jäsen',
  'admin.permission.account': 'Tili',
  'admin.permission.allocation': 'Allokaatio',
  'admin.permission.assignment': 'Toimeksianto',
  'admin.permission.cv': 'CV',
  'admin.permission.organization': 'Organisaatio',
  'admin.permission.person': 'Henkilö',
  'admin.permission.site': 'Konttori',
  'admin.permission.group': 'Ryhmä',
  'admin.permissions.type': 'Tyyppi',
  'admin.permission.network': 'Verkosto',
  'admin.permission.article': 'Artikkeli',
  'admin.permission.addPermissionTitle': 'Lisää oikeuksia',
  'admin.permission.addPermission.create': 'Create',
  'admin.permission.addPermission.update': 'Update',
  'admin.permission.addPermission.read': 'Read',
  'admin.permission.addPermission.delete': 'Delete',
  'admin.permission.groupType': 'Tyyppi',
  'admin.permission.description': 'Kuvaus',
  'admin.permission.editGroup': 'Muokkaa ryhmää',
  'admin.permission.organizationId': 'Organisaatio',
  'admin.permission.personId': 'Henkilö',
  'admin.permission.group.organizationId': 'Organisaatio',
  'admin.permission.other': 'Muu',
  'admin.skills.name': 'Nimi',
  'admin.skills.rename': 'Uusi nimi',
  'admin.skills.nameTaken': 'Nimi on jo käytössä. Käytä toista nimeä tai yhdistä taidot.',
  'admin.skills.actions': 'Toiminnot',
  'admin.skills.promote': 'Nosta',
  'admin.skills.promote.confirmationText': 'Oletko varma että haluat nostaa taidon?',
  'admin.skills.merge': 'Yhdistä',
  'admin.skills.merging': 'Yhdistä (mistä)',
  'admin.skills.mergeTo': 'Yhdistä (mihin)',
  'admin.skills.split': 'Pilko',
  'admin.skills.splitting': 'Pilko (mistä)',
  'admin.skills.splitTo': 'Pilko (mihin)',
  'admin.skills.split.toLengthError': 'Valitse ainakin kaksi taitoa joihin taito pilkotaan.',
  'admin.skills.delete': 'Poista',
  'admin.skills.languages': 'Kielet',
  'admin.skills.promoteSelected': 'Nosta valitut',
  'admin.skills.mergeSelected': 'Yhdistä valitut',
  'admin.skills.deleteSelected': 'Poista valitut',
  'admin.skills.delete.confirmationText':
    'Valittu taito/rooli/toimiala poistetaan myös kaikkista sitä käyttävistä CV:stä!',
  'admin.skills.showAll': 'Näytä kaikki',
  'admin.skills.showNew': 'Näytä uudet',
  'admin.skills.showPromoted': 'Näytä nostettu',
  'admin.skills.all': 'Kaikki',
  'admin.skills.new': 'Uudet',
  'admin.skills.translate': 'Käännä',
  'admin.skills.translate.fi': 'Suomenkielinen käännös',
  'admin.skills.translate.eng': 'Englanninkielinen käännös',
  'admin.skill': 'Taito',
  'admin.industry': 'Toimiala',
  'admin.role': 'Rooli',
  'admin.title.permissions': 'Oikeudet',
  'admin.title.skills': 'Taidot',
  'admin.title.industries': 'Toimialat',
  'admin.title.roles': 'Roolit',
  'admin.title.dataManagement': 'Syötteenhallinta',
  'admin.userManual.pageTitle': 'Otsikko',
  'admin.userManual.url': 'Sivun osoite',
  'admin.userManual.modal.title': 'Otsikko',
  'admin.userManual.modal.url': 'URL slug',
  'admin.userManual.modal.addTitle': 'Lisää käyttöohje',
  'admin.userManual.modal.editTitle': 'Muokkaa käyttöohjetta',
  'admin.title.featureToggles': 'Ominaisuusvalinnat',
  'admin.feature.reports': 'Raportit',
  'admin.feature.teams': 'Tiimit',
  'admin.feature.dashboard': 'Dashboard',
  'admin.feature.scout': 'Tutka',
  'admin.feature.assignments': 'Toimeksiannot',
  'admin.feature.allocations': 'Allokaatiot',
  'admin.feature.search': 'Haku',
  'admin.feature.companyAdmin': 'Yrityksen hallinta',
  'admin.feature.profile': 'Profiili',
  'admin.feature.networks': 'Verkostot',
  'admin.feature.widgets': 'Widgetit',
  'admin.feature.allocationOverview': 'Allokaatioyhteenveto',
  'admin.feature.salespool': 'Myyntikorit',
  'admin.feature.parse': 'CV-parsinta',
  'admin.feature.chat': 'Keskustelut',
  'admin.feature.workOrders': 'Työtilaukset',
  'admin.feature.organization': 'Organisaatio',
  'admin.title.active': 'Aktiiviset käyttäjät',
  'admin.activeStatistics.header': 'Statistiikka päivän mukaan',
  'admin.activeStatistics.type': 'Tyyppi',
  'admin.activeStatistic.login': 'Kirjautuminen',
  'admin.activeStatistic.refresh': 'Uudistus',
  'admin.activeStatistic.logout': 'Ulos kirjautuminen',
  'admin.title.auditlog': 'Tarkastusloki',
  'admin.auditlog.userId': 'Käyttäjä ID',
  'admin.auditlog.action': 'Toiminto',
  'admin.auditlog.actionData': 'Toiminto data',
  'admin.auditlog.timestamp': 'Aikaleima',
  'admin.auditlog.actionType': 'Toiminto tyyppi',
  'admin.auditlog.all': 'Kaikki',
  'admin.auditlog.assignment': 'Toimeksianto',
  'admin.auditlog.assignmentroleproposal': 'Toimeksiantoroolin tarjous',
  'admin.auditlog.assignmentvisibility': 'Toimeksiannon näkyvyys',
  'admin.auditlog.cv': 'CV',
  'admin.auditlog.allocation': 'Allokaatio',
  'admin.auditlog.team': 'Tiimi',
  'admin.auditlog.account': 'Tili',
  'admin.auditlog.person': 'Henkilö',
  'admin.auditlog.group': 'Oikeusryhmä',
  'admin.auditlog.network': 'Verkosto',
  'admin.auditlog.organization': 'Organisaatio',
  'admin.auditlog.search': 'Haku',
  'admin.auditlog.proposalsearch': 'Tarjoushaku',
  'admin.auditlog.searchproposal': 'Haun ehdotus',
  'admin.auditlog.feature': 'Ominaisuus',
  'admin.auditlog.sendingemail': 'Sähköpostin lähetys',
  'admin.auditlog.refreshtoken': 'Kirjautumisen päivitystunnus',
  'print.noCV': 'Ei tulostettavaa CV:ä!',
  'print.layout.addTitle': 'Lisää tulostusasettelu',
  'print.layout.title': 'Asetukset',
  'print.layout.closeAll': 'Sulje kaikki',
  'print.layout.about': 'Esittely',
  'print.layout.education': 'Koulutus',
  'print.layout.workhistory': 'Työkokemus',
  'print.layout.projects': 'Projektit',
  'print.layout.references': 'Suositukset',
  'print.layout.skills': 'Osaaminen',
  'print.layout.industries': 'Toimialat',
  'print.layout.roles': 'Roolit',
  'print.layout.languages': 'Kielitaito',
  'print.layout.degrees': 'Tutkinnot',
  'print.layout.courses': 'Kurssit',
  'print.layout.certificates': 'Sertifikaatit',
  'print.layout.links': 'Linkit',
  'print.layout.name': 'Nimi',
  'print.layout.add': 'Lisää asettelu',
  'print.layout.showContactInfo': 'Näytä yhteystiedot',
  'print.layout.useOwnCompanyBranding': 'Käytä oman yrityksen brändäystä',
  'print.reference.name': 'Nimi',
  'print.reference.company': 'Yritys',
  'print.reference.title': 'Suositukset',
  'print.reference.referee': 'Suosittelija',
  'print.employment.roles': 'Roolit',
  'print.project.role': 'Rooli',
  'print.project.customer': 'Asiakas',
  'print.project.employer': 'Työnantaja',
  'print.project.skills': 'Taidot',
  'print.project.industries': 'Toimialat',
  'print.project.roles': 'Roolit',
  'print.skills.expert': 'Asiantuntija',
  'print.skills.advanced': 'Edistynyt',
  'print.skills.intermediate': 'Keskitaso',
  'print.skills.novice': 'Perusteet',
  'print.skills.fundamentals': 'Alkeet',
  'print.skills.noLevel': 'Ei tasoa',
  'print.controls.layout': 'Valitse asettelu:',
  'print.controls.addLayout': 'Lisää asettelu',
  'print.controls.deleteLayout': 'Poista asettelu',
  'print.controls.selectCV': 'Valitse CV',
  'print.preview.title': 'Esikatselu',
  'print.preview.pdf': 'PDF esikatselu',
  'print.about.title': 'Esittely',
  'print.education.title': 'Koulutus',
  'print.degrees.title': 'Tutkinnot',
  'print.courses.title': 'Kurssit',
  'print.certificates.title': 'Sertifikaatit',
  'print.education.licence': 'Lisenssinumero',
  'print.skills.title': 'Osaaminen',
  'print.PersonSkills.title': 'Osaaminen',
  'print.industries.title': 'Toimialat',
  'print.Industries.title': 'Toimialat',
  'print.roles.title': 'Roolit',
  'print.Roles.title': 'Roolit',
  'print.employments.title': 'Työkokemus',
  'print.Employers.title': 'Työkokemus',
  'print.projects.title': 'Projektit',
  'print.languages.title': 'Kielitaito',
  'print.visibilityOn': 'Piilota',
  'print.visibilityOff': 'Näytä',
  'print.download': 'Lataa PDF',
  'print.downloadDocx': 'Lataa DOCX',
  'print.degree': 'Tutkinto',
  'print.course': 'Kurssi',
  'print.certificate': 'Sertifikaatti',
  'print.organizer': 'Järjestäjä',
  'print.certifier': 'Myöntäjä',
  'print.hideProfileImage': 'Piilota profiilikuva',
  'print.hideName': 'Piilota nimi',
  'print.links.title': 'Linkit',
  'print.hideLastUsed': 'Piilota taitojen viimeksi käytetty',
  'print.showCertificateExpiry': 'Näytä sertifikaatin päättymispäivä',
  'print.certificateExpiry': 'Päättymispäivä',
  'print.url': 'URL',
  'tos.title': 'Käyttöehdot',
  loading: 'Ladataan',
  loadingMore: 'Ladataan lisää tuloksia...',
  'navigation.networks': 'Verkostot',
  'network.title': 'Verkostot',
  'network.name': 'Nimi',
  'network.owner': 'Omistaja',
  'network.brokers': 'Verkoston välittäjät',
  'network.managers': 'Managerit',
  'network.members': 'Jäsenet',
  'network.cvCount': 'CV määrä',
  'network.joinRequests': 'Pyynnöt',
  'network.visibility': 'Näkyvyys',
  'network.type.private': 'yksityinen',
  'network.type.public': 'julkinen',
  'network.editTitle': 'Muokkaa verkostoa',
  'network.addTitle': 'Lisää verkosto',
  'network.type': 'Tyyppi',
  'network.description': 'Kuvaus',
  'network.charLeft': 'Merkkiä jäljellä',
  'network.NetworkBrokers': 'Verkoston välittäjät',
  'network.NetworkManagers': 'Verkoston jäsenmanagerit',
  'network.salesContactsVisible': 'Myynnin yhteyshenkilöt näkyvissä',
  'network.addMemberOrganization': 'Lisää jäsenyritys',
  'network.addMemberAccount': 'Lisää jäsentili',
  'network.add': 'Lisää',
  'network.pending': 'Avoimia',
  'network.confirmBack': 'Oletko varma että haluat palata takaisin? Kaikki muutokset hävitetään!',
  'network.pending.title': 'Avoimet',
  'network.pending.name': 'Nimi',
  'network.pending.accept': 'Salli',
  'network.pending.deny': 'Evää',
  'network.shareCV': 'Jaa CV',
  'network.shareDialog': 'Jaa CV',
  'network.share.organizationId': 'Yritys',
  'network.share.expiration': 'Umpeutumispäivä',
  'network.unsubNotificationEmail':
    'Sähköpostitilaus ilmoituksien sähköpostiosoitteet (useampi osoite puolipisteellä erotettuna)',
  'network.assignmentCopyEmail':
    'Lähetä kopiot verkossa jaetuista toimeksiannoista (useampi osoite puolipisteellä erotettuna)',
  'network.source': 'Valitse lähdeverkko',
  'network.overwrite': 'Korvaa/populoi',
  'network.manage.title': 'Hallinnoi verkostoa',
  'network.manage.infoPage': 'Verkosto edut',
  'network.infoPageVisible': 'Verkosto edut näkyvissä',
  'network.parserEnabled': 'CV-parsinta',
  'network.chatEnabled': 'Chat toiminto',
  'editInfoPage.editTitle': 'Verkosto edut',
  'editInfoPage.infoPage': 'Etujen sisältö',
  'network.manage.proposalInfo': 'Tarjouksien tietosivu',
  'network.manage.details': 'Tietojen muokkaus',
  'network.manage.notifications': 'Ilmoitukset',
  'network.manage.remove': 'Poista verkostosta',
  'network.manage.removeConfirm': 'Poistetaanko {{name}} verkostosta?',
  'network.manage.accountsWarning': 'Organisaatiossa on käyttäjiä joten sitä ei voi poistaa.',
  'network.notification.salesOnly': 'Lähetä vain myyntihenkilöille',
  'network.notification.skillBased': 'Lähetä vain taidot omaaville',
  'network.notification.skills': 'Taidot',
  'network.notification.headerfi': 'Ilmoituksen otsikko fi',
  'network.notification.headeren': 'Ilmoituksen otsikko en',
  'network.notification.messagefi': 'Viesti fi',
  'network.notification.messageen': 'Viesti en',
  'network.deleteTitle': 'Oletko varma, että haluat poistaa {{networkName}} verkoston?',
  'network.onlyBrokerShare': 'Vain välittäjät voivat jakaa toimeksiantoja',
  'network.autoAssignmentStatus': 'Automaattinen toimeksiantojen/työtilauksien tilan päivitys',
  'network.workOrderEnabled': 'Työtilaus toimeksiannon tilalle',
  'editProposalInfo.editTitle': 'Tarjouksen tietosivu',
  'editProposalInfo.proposalGuide': 'Tietosivun sisältö',
  'navigation.benefits': 'Verkosto edut',
  'dashboard.title': 'Dashboard',
  'dashboard.quickAssignments': 'Näytä pikatoimeksiantoja',
  'dashboard.information': 'Lisätietoja',
  'dashboard.proposed': 'Toimeksiantoihin tarjotut',
  'dashboard.allocated': 'Allokoitu',
  'dashboard.available': 'Vapaana',
  'dashboard.becomingAvailable': 'Vapautumassa',
  'dashboard.name': 'Nimi',
  'dashboard.assignment': 'Toimeksianto',
  'dashboard.startDate': 'Aloituspäivä',
  'dashboard.assignmentRole': 'Rooli',
  'dashboard.skills': 'Taidot',
  'dashboard.location': 'Sijainti',
  'dashboard.lastAllocation': 'Edellinen toimeksianto',
  'dashboard.weeks': 'viikossa',
  'dashboard.months': 'kuukaudessa',
  'dashboard.toProfile': 'Profiiliin',
  'dashboard.viewProfile': 'Katsele profiilia',
  'dashboard.toAssignment': 'Toimeksianto',
  'dashboard.ownAssignments': 'Näytä vain omat',
  'dashboard.ownAssignmentsTooltip': 'Näytä vain toimeksiannot, joissa olen vastuuhenkilönä',
  'network.visibility.title': 'Verkoston toimeksianto näkyvyydet',
  'network.acceptVisibilities': 'Hyväksy toimeksiannon näkyvyyksiä',
  'network.assignment': 'Toimeksianto',
  'network.visibility.status': 'Tila',
  'network.visibility.accepted': 'Hyväksytty',
  'network.visibility.notAccepted': 'Ei hyväksytty',
  'network.visibility.acceptedAt': 'Hyväksymis päivä',
  'network.assignmentsVisible': 'Toimeksiannot näkyvissä',
  'network.workordersVisible': 'Työtilaukset näkyvissä',
  'network.teamsVisible': 'Tiimit näkyvissä',
  'network.pricesVisible': 'Hinnat näkyvissä',
  'network.statistics': 'Verkostostatistiikka',
  'network.statistics.location': 'Sijainti',
  'network.statistics.unsubscribedMembers': 'Tilaus lopetettu',
  'network.statistics.showFreelancers': 'Näytä freelancerit',
  'network.statistics.showOrganizations': 'Näytä yritykset',
  'network.statistics.contactPersons': 'Kontaktihenkilöt',
  'network.statistics.memberName': 'Jäsenen nimi',
  'network.statistics.visibleUserCount': 'Näkyvä käyttäjämäärä',
  'network.statistics.totalUserCount': 'Käyttäjämäärä yhteensä',
  'network.statistics.lastLoggedIn': 'Viimeksi kirjautunut',
  'network.statistics.freelancerCount': 'Freelancer määrä:',
  'network.statistics.organizationCount': 'Yritys määrä:',
  'network.statistics.activeOrganizations': 'Aktiiviset yritykset:',
  'network.statistics.nonActiveOrganizations': 'Ei aktiiviset yritykset:',
  'network.statistics.activeOrganizationRatio': 'Aktiivisia yrityksiä:',
  'network.statistics.activeUsers': 'Aktiiviset käyttäjät:',
  'network.statistics.nonActiveUsers': 'Ei aktiiviset käyttäjät:',
  'network.statistics.activeUsersRatio': 'Aktiivisia käyttäjiä:',
  'network.statistics.activeFreelancers': 'Aktiiviset freelanserit:',
  'network.statistics.nonActiveFreelancers': 'Ei aktiiviset freelanserit:',
  'network.statistics.activefreelancersRatio': 'Aktiivisia freelancereitä:',
  'network.statistics.export.contacts': 'Tulosta verkon kontaktit',
  'network.statistics.export.include': 'Sisällytä',
  'network.statistics.export.freelancers': 'Freelancerit',
  'network.statistics.export.organizations': 'Organisaatiot',
  'network.statistics.export': 'Tulosta',
  'assignment.modal.AssignmentVisibilities': 'Jaa verkostoille',
  'assignment.modal.team': 'Tiimi',
  'scoutFAQ.title': 'Tutka-testien esittely',
  'scoutFAQ.introduction':
    'Tutka on kyselytyökalu asiantuntijoille, tiimeille ja yrityksille parantamaan ymmärrystä vallitsevista pehmeistä arvoista perustuen C.G Jungin tutkimuksien tuloksiin. Kysely tehdään kerran.',
  'scoutFAQ.question1': 'Kuinka Tutkan tuloksia käytetään?',
  'scoutFAQ.question2': 'Kuinka Tutkan tuloksia tulisi tulkita?',
  'scoutFAQ.question3': 'Kuka voi nähdä tulokseni?',
  'scoutFAQ.answer1':
    'Tutkan tuloksia käytetään vain yrityksen sisäiseen raportointiin. Tuloksien avulla voidaan jakaa tietoa yrityksen sisällä, jotta yrityksen johto, tiimien vetäjät ja henkilöstö ymmärtävät paremmin yrityksessä tai tiimeissä vallitsevia arvoja. Tulevaisuudessa Tutkan tulokset voidaan lisätä hakukriteeriksi Caleon hakuun.',
  'scoutFAQ.answer2':
    'Tulokset ovat aina suuntaa-antavia, eivät totuuksia. Tuloksiin tulee suhtautua kriittisesti pohtien ja reflektoiden onko samaa mieltä tuloksista. Tulosten tarkkuus vaihtelee yksilökohtaisesti.',
  'scoutFAQ.answer3':
    'Tiimin vetäjäsi ja yrityksen hallinto voivat nähdä täyttämäsi Tutkan tulokset. Yhteenveto Tutkan tuloksista on jaettu. Vain sinä näet tarkat vastaukset kysymyksiin.',
  'scoutPlus.guide':
    'Kartoitus käsittää 18 erilaista väittämäkokonaisuutta, joissa on neljä valintavaihtoehtoa.\n' +
    'Jokaisen valintavaihtoehdon kohdalla kartoituksen suorittaja arvottaa pisteillä 6, 4, 3 ja 1 oman näkemyksensä toimia ja/tai ajatella ko. tilanteissa.\n' +
    'Arvioija antaa kuusi (6) pistetä sille neljästä vaihtoehdosta, jota pitää parhaiten itseään kuvaavana eri työelämän tilanteissa. Seuraavalle neljä (4), sitten kolme (3) ja lopuksi vähiten itseään kuvaavalle yhden (1) pisteen.',
  'scoutPlus.welcome': 'Tutka-Plus Opas',
  'scoutPlus.start': 'Aloita',
  'scoutPlus.results': 'Tutka-Plus tulokset',
  'scoutPlus.question1': 'Muiden mielestä vaikutan todennäköisesti',
  'scoutPlus.question2': 'Työskennellessäni jonkin tehtävän työn parissa',
  'scoutPlus.question3': 'Keskustellessani muiden kanssa saatan',
  'scoutPlus.question4': 'Kun en jostakin syystä voi tehdä sitä mitä haluan, katson parhaimmaksi',
  'scoutPlus.question5': 'Joskus epäilen, että muut saattavat pitää minua',
  'scoutPlus.question6': 'Kun kirjoitan bisnespostia henkilölle, jota en tunne, pyrin tavallisesti',
  'scoutPlus.question7': 'Kun muut ovat kanssani eri mieltä, pääsen tavallisesti asiassa eteenpäin',
  'scoutPlus.question8': 'Tarkastellessani asioita aikaulottuvuuden kannalta keskityn todennäköisesti eniten',
  'scoutPlus.question9': 'Suhteessani vapaa-aikana tapaamiini ihmisiin harkitsen todennäköisesti',
  'scoutPlus.question10':
    'Puhuessani ihmisille, joiden kanssa en ole ollut säännöllisesti yhteydessä, toivoisin jättäväni itsestäni kuvan',
  'scoutPlus.question11': 'Tiukoissa neuvottelutilanteissa saatan joskus',
  'scoutPlus.question12': 'Jos en ole varovainen, toisista saattaa ajoittain tuntua, että olen',
  'scoutPlus.question13': 'Olen tyytyväinen itseeni, kun',
  'scoutPlus.question14': 'Mielestäni on helppo olla vakuuttava, kun',
  'scoutPlus.question15': 'Nautin kun muut pitävät minua',
  'scoutPlus.question16': 'Kun tiukka paikka tulee, katson viisaammaksi',
  'scoutPlus.question17': 'Kun toiset painostavat minua, olen',
  'scoutPlus.question18':
    'Ajatellessani suhtautumistani vaikeisiin tilanteisiin, on mahdollista, että sotkeudun liikaa',
  'scoutPlus.answer1a': 'käytännölliseltä ja asialliselta',
  'scoutPlus.answer1b': 'tunteelliselta ja piristävältä',
  'scoutPlus.answer1c': 'terävältä ja johdonmukaiselta',
  'scoutPlus.answer1d': 'älykkäältä ja hieman vaikeaselkoiselta',
  'scoutPlus.answer2a':
    'haluan, että tehtävä on virikkeitä antava ja että siihen liittyy runsaasti kanssakäymistä muiden kanssa',
  'scoutPlus.answer2b': 'keskityn varmistamaan, että tehtävä on systemaattisesti ja loogisesti suunniteltu',
  'scoutPlus.answer2c':
    'haluan varmistaa, että tehtävästä on konkreettista hyötyä niin, että minun kannattaa käyttää aikaa ja energiaa siihen',
  'scoutPlus.answer2d': 'olen lähinnä kiinnostunut siitä,onko tehtävä ”uutta luovaa” vai edistääkö se tietoa',
  'scoutPlus.answer3a': 'tahattomasti ilmaista kyllästymiseni puheeseen, joka on liian yksityiskohtainen',
  'scoutPlus.answer3b':
    'osoittaa kärsimättömyyttä niitä kohtaa, jotka esittävät ideoita, joita ei ole ilmeisesti riittävästi harkittu',
  'scoutPlus.answer3c': 'osoittaa laimeaa mielenkiintoa ajatuksiin ja ideoihin, joista puuttuu omaperäisyyttä',
  'scoutPlus.answer3d':
    'herkästi jättää huomaamatta ne, jotka puuhaavat pitkän tähtäimen vaikutuksia ja kiinnittää huomioita asioihin, jotka pitäisi tehdä juuri nyt',
  'scoutPlus.answer4a':
    'tarkastella ajattelutavassani mahdollisesti ilmeneviä puutteita tai heikkoja kohtia ja tehdä muutoksia sen mukaisesti',
  'scoutPlus.answer4b': 'miettiä uudelleen kaikki, mitä on tapahtunut ja kehittää uusi oletusmalli',
  'scoutPlus.answer4c':
    'pitää mielessä perusasiat, määrittää tärkeimmät esteet ja muuttaa ”pelisuunnitelmaa” niin, että nämä seikat on huomioitu',
  'scoutPlus.answer4d': 'analysoida muiden motivaatiot ja kehittää uusi tuntuma asiaan',
  'scoutPlus.answer5a': 'liian tunteellisena tai jopa kiihkeänä',
  'scoutPlus.answer5b': 'liian varautuneena ja liian loogisena',
  'scoutPlus.answer5c': 'liian kiinnostuneena yksityiskohdista ja kysymyksistä ”miten”?',
  'scoutPlus.answer5d': 'ihmisenä, jolla on runsaasti ideoita ja jota on hieman vaikea ymmärtää',
  'scoutPlus.answer6a':
    'selvittämään yhteydenoton taustalla olevia syitä ja liittämään ne siihen, miksi nyt otan yhteyttä',
  'scoutPlus.answer6b': 'kertomaan yksinkertaisin sanoin, mitä haluan, tarvitsen tai odotan tältä henkilöltä',
  'scoutPlus.answer6c': 'osoittamaan, miten tärkeimmät esittämäni asiat liittyvät laajempaan kokonaisuuteen',
  'scoutPlus.answer6d': 'antamaan ainakin jonkinlaisen ”näytteen” itsestäni ja tyylistäni',
  'scoutPlus.answer7a': 'saamalla ainakin pariin kohtaan sitoutumisen, jolle voimme myöhemmin rakentaa',
  'scoutPlus.answer7b': 'pyrkimällä tarkastelemaan asioita muiden näkökulmasta',
  'scoutPlus.answer7c':
    'säilyttämällä malttini ja auttamalla muita näkemään asiat yksinkertaisesti ja johdonmukaisesti',
  'scoutPlus.answer7d': 'turvautumalla kykyyni hahmottaa ja yhdistää asiat toisiinsa',
  'scoutPlus.answer8a': 'siihen, jääkö se, mitä teen, merkityksellisenä asiana muistiin',
  'scoutPlus.answer8b':
    'varmistamaan, että kaikki suorittamani toimenpiteet ovat johdonmukaisia ja kuuluvat osana systemaattiseen järjestelmään',
  'scoutPlus.answer8c':
    'välittömiin toimenpiteisiin ja tapahtumiin, ja siihen, mitä merkitystä niillä on juuri tällä hetkellä',
  'scoutPlus.answer8d':
    'merkittäviin pitkän tähtäimen toimenpiteisiin, joihin aion ryhtyä, ja siihen, kuinka ne liittyvät ylipäänsä elämänkulkuun',
  'scoutPlus.answer9a': 'voivatko he antaa ideoita ja haasteita',
  'scoutPlus.answer9b': 'näyttävätkö he harkitsevilta ja pohdiskelevilta',
  'scoutPlus.answer9c': 'ovatko he kiinnostavia ja onko heidän kanssaan hauska olla',
  'scoutPlus.answer9d': 'tietävätkö he, mitä he tekevät, ja voivatko he saada jotakin aikaan',
  'scoutPlus.answer10a':
    'systemaattisena ajattelijana, joka kykenee analysoimaan ongelmia, joita tällä kyseisellä ryhmällä on ratkottavanaan',
  'scoutPlus.answer10b': 'laaja-alaisena ajattelijana, joka pystyy löytämään uusia ratkaisumalleja',
  'scoutPlus.answer10c':
    'kokeneena ja neuvokkaana henkilönä, joka voi auttaa ryhmää määrittelemään huolenaiheet, ja joka pystyy auttamaan heitä ongelmien ratkaisemisessa',
  'scoutPlus.answer10d': 'eloisana ja vaikuttavana henkilönä, joka ymmärtää heidän tarpeensa ja mielialansa',
  'scoutPlus.answer11a':
    'kertoa ajatukseni liian vapaasti ja ilmaista tunnepitoisesti asioita, jotka olisi ehkä ollut parempi jättää sanomatta',
  'scoutPlus.answer11b': 'olla liian varovainen ja välttää yhteyksiä, jotka olisivat ehkä osoittautuneet antoisiksi',
  'scoutPlus.answer11c': 'kadottaa ”metsän puilta”, juuttua toisarvoisiin asioihin',
  'scoutPlus.answer11d':
    'joutua sellaisten henkilöiden ohjattaviksi, jotka ovat kyllä lahjakkaita, mutta joilta saattaa puuttua selkeä päämäärä',
  'scoutPlus.answer12a': 'erittäin tunteeton ja taipuvainen olemaan etäinen ja epäpersoonallinen',
  'scoutPlus.answer12b': 'pinnallinen tai itsekeskeinen raataja',
  'scoutPlus.answer12c': 'vähän keikaileva, älyllisesti etevämpi tai muita alentava',
  'scoutPlus.answer12d': 'oikullinen, helposti kiihottuva tai arvaamaton',
  'scoutPlus.answer13a': 'saan enemmän asioita tehdyksi kuin olin suunnitellut',
  'scoutPlus.answer13b': 'ymmärrän muiden tunteet ja voin auttaa heitä',
  'scoutPlus.answer13c': 'ratkaisen jonkin ongelman käyttäen hyväkseni loogisia tai systemaattisia menetelmiä',
  'scoutPlus.answer13d': 'kehitän uusia ajatuksia ja ideoita, joita voidaan yhdistellä toisiinsa',
  'scoutPlus.answer14a': 'olen omien tunteitteni tasalla ja tiedän myös muiden tunteet',
  'scoutPlus.answer14b': 'olen johdonmukainen, kärsivällinen ja kestävä',
  'scoutPlus.answer14c': 'olen asiallinen ja käytännönläheinen',
  'scoutPlus.answer14d': 'olen älyllisesti asioiden tasalla ja kykenen ottamaan huomioon kaikki vaikuttavat tekijät',
  'scoutPlus.answer15a': 'älyllisesti lahjakkaana ja avarakatseisena',
  'scoutPlus.answer15b': 'henkilönä, joka tietää, mihin on menossa ja jolla on pätevyys saavuttaa tavoitteensa',
  'scoutPlus.answer15c': 'luovana ja virikkeitä antavana',
  'scoutPlus.answer15d': 'luotettavana henkilönä, joka saa asiat hoidettua ja joka pääsee eteenpäin',
  'scoutPlus.answer16a':
    'pitäytyä järjestelmälliseen menetelmään, joka on aikaisemmin osoittautunut tehokkaaksi, vaikka enemmän riskejä ottamalla saattaisin saavuttaa useampia voittoja',
  'scoutPlus.answer16b': 'tulla pidetyksi omaperäisenä, vaikka se minulle lyhyellä tähtäimellä maksaisikin',
  'scoutPlus.answer16c':
    'keskittyä siihen, minkä haluan juuri nyt saada tehdyksi, vaikka näin menetellen ei saataisikaan mitään suuria aikaan',
  'scoutPlus.answer16d': 'olla spontaani ja sanoa, mitä todella ajattelee',
  'scoutPlus.answer17a': 'ylitunteellinen, impulsiivinen tai joudun helposti tunteiden vietäväksi',
  'scoutPlus.answer17b': 'liian taipuvainen tarkastelemaan heitä analyyttisesti ja kriittisesti',
  'scoutPlus.answer17c': 'liian herkkä todistamaan kantani suoralla toiminnalla',
  'scoutPlus.answer17d': 'taipuvainen vetäytymään omaan ajatusmaailmaan',
  'scoutPlus.answer18a': 'järjen ”mittelöihin” ja ongelman ratkaisuun, jopa niiden itsensä vuoksi',
  'scoutPlus.answer18b': 'välittömään ”tässä ja nyt” –tilanteeseen, jossa toimin niin kuin itse haluan',
  'scoutPlus.answer18c': 'käsitteiden, arvojen ja ideoiden maailmaan',
  'scoutPlus.answer18d': 'muiden tunteisiin',
  'scoutPlus.paiResults': 'PAI-Tulokset',
  'scoutPlus.norResults': 'NOR-Tulokset',
  'scoutPlus.norResults.description': 'Normaali ympäristö',
  'scoutPlus.paiResults.description': 'Paineympäristö',
  'scoutPlus.result.veryLow': 'Erittäin matala',
  'scoutPlus.result.low': 'Matala',
  'scoutPlus.result.mid': 'Keskiverto',
  'scoutPlus.result.high': 'Korkea',
  'scoutPlus.result.veryHigh': 'Erittäin korkea',
  'scoutPlus.opportunities': 'Mahdollisuudet',
  'scoutPlus.risks': 'Riskit',
  'scoutPlus.resultPossibilitiesText': 'Tuloksesi voi vaihdella 9-54 välillä.',
  'scoutPlus.norScore': 'NOR-tuloksesi on ',
  'scoutPlus.paiScore': 'PAI-tuloksesi on ',
  'scoutPlus.veryHighScoreText':
    'Erittäin korkealla tuloksella mahdollisuudet ja riskit ovat voimakkaasti esillä arvomaailmassasi.',
  'scoutPlus.highScoreText': 'Korkealla tuloksella mahdollisuudet ja riskit voimistuvat.',
  'scoutPlus.midScoreText': 'Keskivertoisella tuloksella mahdollisuudet ja riskit ovat olemassa.',
  'scoutPlus.lowScoreText': 'Matalalla tuloksella mahdollisuuksien ja riskien vaikutus on pieni.',
  'scoutPlus.veryLowScoreText': 'Erittäin matalalla tuloksella mahdollisuuksien ja riskien vaikutus on olematon.',
  'scoutPlus.normal': 'Normaali',
  'scoutPlus.presured': 'Paine',
  'scout.affectiveness.pros':
    'spontaanit mukaan tulemiset\n' +
    'kyky kuunnella syvällisesti tarvittaessa\n' +
    'pitää perinteitä kunniassa\n' +
    '”haistaa” ihmisten tunnelman loogisuuden\n' +
    'tunnistaa omatkin vahvuudet ja heikkoudet\n' +
    'taitava saamaan muutkin ilmaisemaan tunteensa\n' +
    'vuorovaikutustaitoinen, luonteva esiintyjä',
  'scout.affectiveness.cons':
    'yli-impulsiivinen tilanteittain\n' +
    'manipulointitaitoinen\n' +
    'kriittiselle palautteelle herkkä\n' +
    'tunneryöpyt vievät\n' +
    'lykkää mielellään asioita\n' +
    'syyllistää itseään turhan usein\n' +
    'murehtii ”kaikkia asioita”',
  'scout.affectiveness.conclusion': `Näkyy käytännön arjessa ihmisten ymmärtäjänä, ”olkapäänä”, kuuntelijana,
  pahimmillaan ihmissuhteiden vatvojana.`,
  'scout.pragmatism.pros':
    'jalat ”tukevasti maassa” asioissa\n' +
    'itsevarma ote kaikkeen tekemiseen\n' +
    'tuloskeskeinen kaikessa tekemisessä\n' +
    'ei usko ennen kuin näkee\n' +
    'suora käyttäytyminen, ei ”pelaa”\n' +
    'kilpailukykyinen myönteisessä mielessä\n' +
    'itseluottamus kohdillaan',
  'scout.pragmatism.cons':
    'ajattelee vain tätä päivää\n' +
    'hakee arvostusta epäterveesti\n' +
    'itsekeskeinen, rakastaa itseään\n' +
    'toimii ensin, ajattelee sitten\n' +
    'ei luota muiden osaamiseen\n' +
    'hallitseva kanssaihmisiä kohtaan\n' +
    'oikeassa olemisen tarve voimakas',
  'scout.pragmatism.conclusion': `Näkyy käytännön arjessa nopeana, konkreettisiin tavoitteisiin pyrkivänä ja myös
  pääsevänä, mutta laadusta pahastikin tinkivänä.`,
  'scout.pervasiveness.pros':
    'omaperäisiä, luovia ajatuksia\n' +
    'mielikuvitus hallussa vahvasti\n' +
    'pitkäjänteistä luovuutta ajatella\n' +
    'vaistonvaraisuus ongelmittain hienoa\n' +
    'huokuu karismaattista säteilyä\n' +
    'se, mitä ihailee, siihen sitoutuu\n' +
    'luovalla säkenöivällä älyllä varustettu\n' +
    'aatesuunta, jonka hyväksyy, siihen sitoutuu',
  'scout.pervasiveness.cons':
    'jalat ”irti maasta, leijuu”\n' +
    'henkisesti etäinen, vaikea tavoittaa\n' +
    'elää omissa ”sfääreissään”\n' +
    'asiat ”leväällään”, auki koko ajan\n' +
    'monimutkaisia kannanottoja\n' +
    'haaveilee myös epätodellisesta\n' +
    'dogmaattinen eli henkisesti jäykkä\n' +
    'epäkäytännöllinen tekemisissään',
  'scout.pervasiveness.conclusion':
    'Näkyy käytännön arjessa laajojen ongelmien ratkaisijana, uusien näkökulmien etsijänä, visionäärinä, helposti turhautuvana ja etäisenä, älykkäänä ihmisenä.',
  'scout.prudence.pros':
    'viestii laadukkaasti ja asiallisesti\n' +
    'neuvottelijana johdonmukainen\n' +
    'pohdiskeleva toimintatapa\n' +
    'erilaisten vaihtoehtojen vertailutaito\n' +
    'laskennallinen järki mot.\n' +
    'objektiivinen suhtautumisessaan\n' +
    'tunnuslukuja taitava ja rakastava',
  'scout.prudence.cons':
    'päättämätön tärkeissä asioissa\n' +
    'jarruttaa neuvotteluja liikaakin\n' +
    'ylikorostunut analyyttisyys\n' +
    'tunteeton, asiat edellä\n' +
    'varovaisuus silmiinpistävää\n' +
    'varautunut ihmissuhteissa\n' +
    'liian asiakeskeinen, jäykkä',
  'scout.prudence.conclusion':
    'Näkyy käytännön arjessa syvällisenä ajattelijana, loogikkona, laadun tekijänä, kuitenkin aikaa vievästi.',
  'scout.guide':
    'Kartoitus käsittää 10 erilaista väittämäkokonaisuutta, joissa on neljä valintavaihtoehtoa.\n' +
    'Jokaisen valintavaihtoehdon kohdalla kartoituksen suorittaja arvottaa pisteillä 6, 4, 3 ja 1 oman näkemyksensä toimia ja/tai ajatella ko. tilanteissa.\n' +
    'Arvioija antaa kuusi (6) pistetä sille neljästä vaihtoehdosta, jota pitää parhaiten itseään kuvaavana eri työelämän tilanteissa. Seuraavalle neljä (4), sitten kolme (3) ja lopuksi vähiten itseään kuvaavalle yhden (1) pisteen.\n' +
    'Näin syntyy lopuksi kartoitettavan itseanalyysin perusteella "oma kuva", jonka pisteiden vaihteluväli on min 10 pistettä ja max 60 pistettä. Neljän alueen väliset suhteet ovat arvioinnin perustana.',
  'scout.finish': 'Lopeta',
  'scout.next': 'Seuraava',
  'scout.start': 'Aloita',
  'scout.results': 'Tutka tulokset',
  'scout.showDetails': 'Näytä yksityiskohdat',
  'scout.hideDetails': 'Piilota yksityiskohdat',
  'scout.welcome': 'R-Scout opas',
  'scout.questions': 'Kysymykset',
  'scout.affectiveness': 'Tunnevaltaisuus',
  'scout.prudence': 'Harkitsevuus',
  'scout.pragmatism': 'Käytännönläheisyys',
  'scout.pervasiveness': 'Laaja-alaisuus',
  'scout.affectiveness.short': 'TU',
  'scout.prudence.short': 'HA',
  'scout.pragmatism.short': 'KÄ',
  'scout.pervasiveness.short': 'LA',
  'scout.total': 'Yhteensä: ',
  'scout.question1': 'Hyvät työkaverit ovat tärkeitä, koska',
  'scout.answer1a': 'heidän kanssaan on mukavaa ja rentoa olla',
  'scout.answer1b': 'heidän seurassaan voi keskustella syvällisesti',
  'scout.answer1c': 'heillä on varma ja itsenäinen ote tekemiseen',
  'scout.answer1d': 'heidän seurassaan oppii oivaltamaan uutta',
  'scout.question2': 'Töissä ollessani',
  'scout.answer2a': 'otan muut työkaverit huomioon',
  'scout.answer2b': 'harkitsen ennen kuin sanon mielipiteeni',
  'scout.answer2c': 'tarkastelen asioita käytännön näkökulmasta',
  'scout.answer2d': 'tuotan uusia ideoita vaivattomasti',
  'scout.question3': 'Töissä ollessani',
  'scout.answer3a': 'osallistun aktiivisesti ja innostun helposti',
  'scout.answer3b': 'toimin asiallisesti ja määrätietoisesti',
  'scout.answer3c': 'saan aikaan helposti käytännön tuloksia',
  'scout.answer3d': 'älyllinen osaamiseni auttaa minua laajoissakin ongelmissa',
  'scout.question4': 'Töissä hankalissa tilanteissa',
  'scout.answer4a': 'reagoin heti ja innostan muitakin selvittämään ko. tilanteet',
  'scout.answer4b': 'kriittisyyteni tuottaa laadukkaita tuloksia',
  'scout.answer4c': 'aistin asioita välittömästi ja toimin nopeasti',
  'scout.answer4d': 'haluan vetäytyä hetkeksi pohtimaan laajemmin ko. tilannetta',
  'scout.question5': 'Töissä ollessani minua pidetään',
  'scout.answer5a': 'idearikkaana ja motivoituneena',
  'scout.answer5b': 'tavoitteellisena ja ammattitaitoisena',
  'scout.answer5c': 'luotettavana ja sisukkana',
  'scout.answer5d': 'avarakatseisena ja uudistushenkisenä',
  'scout.question6': 'Keskustellessani muiden kanssa töissä arvostan',
  'scout.answer6a': 'heiltä tulevia uusia raikkaita ajatuksia',
  'scout.answer6b': 'heiltä tulevia huolellisesti pohdittuja näkemyksiä',
  'scout.answer6c': 'erityisesti yksityiskohtien esilletuomista',
  'scout.answer6d': 'omaperäisiä persoonallisia näkemyksiä',
  'scout.question7': 'Töissä ollessani minusta huokuu',
  'scout.answer7a': 'tunteellisuus',
  'scout.answer7b': 'johdonmukaisuus',
  'scout.answer7c': 'käytännöllisyys',
  'scout.answer7d': 'laaja-alaisuus',
  'scout.question8': 'Ahdistavissa tilanteissa töissä',
  'scout.answer8a': 'tuon mielipiteeni avoimesti esiin',
  'scout.answer8b': 'olen viisaan varovainen ja pohdiskeleva',
  'scout.answer8c': 'puran tilanteen huumoria käyttäen',
  'scout.answer8d': 'näen useita ratkaisuvaihtoehtoja ko. tilanteissa',
  'scout.question9': 'Viihdyn työssä, joka on',
  'scout.answer9a': 'ihmisläheistä',
  'scout.answer9b': 'järjestelmällistä',
  'scout.answer9c': 'näkyviä aikaansaannoksia tuottavaa',
  'scout.answer9d': 'haastavaa ja virikkeellistä',
  'scout.question10': 'Töissä ollesani vahvuuteni on',
  'scout.answer10a': 'suostuttelukyky',
  'scout.answer10b': 'harkitsevuus',
  'scout.answer10c': 'nopeus',
  'scout.answer10d': 'kyky nähdä tulevaisuuteen',
  'scout.result.aaaa':
    ' on suuressa määrin hyvä kuuntelija ja vuorovaikuttaja, hyvä tunnistamaan muiden tunnetilat, motivaattori ja mukaan innostaja. Neuvottelijana johdonmukainen, järjen ja tunteen yhdistäjä, aisherkkä sekä ihmisherkkä. Saa käytännössäkin aikaan, itseluottamus kohdillaan, tuloksiin pääsevä, luova, pitkäjänteinen, sitoutunut. Riskinä on yli-innokkuus, manipulointihalukkuus, herkkä reagoimaan kielteiseen palautteeseen, liiallinen itsekeskeisyys, hallitsevuus, henkinen jäykkyys',
  'scout.result.baaa':
    ' on suuressa määrin järki-ihminen, hyvä viestijä, konkretiaa arvostava, terveesti itsevarma, tuloskeskeinen, karismaakin löytyy sekä sitoutuneisuutta. Riskinä tunteettomuus, asiakeskeisyys, ihmistä jossain määrin ymmärtämätön. Jossain määrin hän on vuorovaikutustaitoinen, ainakin mitattavissa caseissa.',
  'scout.result.caaa':
    ' on suuressa määrin hyvä viestijä, neuvottelutaitoinen, objektiivinen, aikaansaava, konkretiaan uskova, itseensä luottava, laaja-alainen, sitoutunut.  Hyvin vähäisessä määrin ihmissuhdetaitaja, kyky asettua toisen asemaan puuttuu, negatiiviselle palautteelle yliherkkä, lykkää asioita.',
  'scout.result.abab':
    ' on suuressa määrin tunneherkkä, empaattinen, hyvä kuuntelija, motivaattori. Riskinä yli-impulsiivisuus, lyhytjänteisyys, manipulointihalukkuus. Jossain määrin hän on johdonmukainen, objektiivinen, hyvin suuressa määrin aikaansaava konkreettisesti ja suorana, riskinä liiallinen nopeus toimissa, jossain määrin  palautteen antaja, luova, laaja-alainen ja sitoutunut.',
  'scout.result.baba':
    ' on jossain määrin tunneherkkä ihmisten ymmärtäjä, empaattinenkin. Hyvin suuressa määrin harkitseva, asiakeskeinen tarvittaessa, riskinä tietty varovaisuus ja päättämättömyys.  Jossain määrin myös toteuttaja, itsevarma, aikaansaava. Hyvin suuressa määrin luova, omaperäinen, sitoutunut, älykäs, riskinä henkinen jäykkyys ja monimutkaisuus ilmaisuissa.',
  'scout.result.abba':
    ' on suuressa määrin vuorovaikutustaitoinen, empaattinen, ihmisten kuuntelija. Riskinä kriittisyys kielteiselle palautteelle, itsensä syyllistäminen sekä asioiden lykkääminen. Jossain määrin harkitseva ja looginen, miksei myös pitkäjänteinen. Hän on jossain määrin tuloskeskeinen, kilpailukykyinen ja -haluinen.  Suuressa määrin  on idearikas, luova, visionääri, älykäs. Riskinä asioiden kesken jättäminen, jalat "irti maasta" oleminen sekä henkinen jäykkyys',
  'scout.result.baab':
    ' on jossain määrin tunneherkkä ihmisten suhteen, kuuntelija, empaattinenkin, suuressa määrin harkitseva, pitkäjänteinen, analyyttinen, riskinä liiallinen objektiivisuus, "mittari"painotteisuus.  Suuressa määrin myös aikaan saava, nopeakin, riskinä itsekeskeisyys, epäluottamus muihin, oikeassa olemisen tarve.  Jossain määrin hän on intuitiivinen, tulevaisuuspainotteinen, järkipainotteinen.',
  'scout.result.acba':
    ' on suuressa määrin ihmissuhdeosaaja, hyvä kuuntelija, empaattinen, riskinä lyhytjänteisyys ja liiallinen subjektiivisuus.  Hän on hyvin vähäisessä määrin  harkitseva, neuvottelutaitoinen, tunnuslukutaitaja. Jossain määrin käytännönläheinen, nopeakin tarvittaessa tilanteittain, suorittaja. Suuressa määrin laaja-alainen, karismaattinenkin, sitoutunut, riskinä haaveilevuus, epäkäytännöllisyys ja monimutkaisuus.',
  'scout.result.abca':
    ' on suuressa määrin tunneherkkä, empaatinen ihmisten ymmärtäjä, syvällinen kuuntelija, riskinä tunne"vellonta" sekä aikaan saamattomuus. Jossain määrin hän on neuvottelutaitoinen harkitsija, objektiivinenkin sekä analyyttinen. Hyvin vähäisessä määrin käytännönläheinen suorittaja, itseluottamusta puhkuva tuloskeskeinen tyyppi. Suuressa määrin hänellä on vaistonvaraisuutta, visionäärin taitoja sekä luovuutta, riskinä etäisyys kanssaihmisiin henkisesti sekä henkinen jäykkyys.',
  'scout.result.bcab':
    ' on jossain määrin empaatinen kuuntelija, ihmisherkkä, motivaattorikin, hyvin vähäisessä määrin harkitseva, analyyttinen sekä pitkäjänteinen. Suuressa määrin käytännönläheinen, nopeakin tekemisissään, itseluottamusta puhkuva , riskinä  muiden huomioonottamattomuus sekä hallitsevuus. Jossain määrin laaja-alainen, sitoutunut sekä älykäs.',
  'scout.result.bacb':
    ' on jossain määrin tunneherkkä, ihmisiä ymmärtävä, empaattinenkin, suuressa määrin harkitseva, asiakeskeinen, analyyttinen, riskinä 110% laatuun pyrkivyys. Hyvin vähäisessä määrin tuloskeskeinen nopea suorittaja, ei myöskään henkisesti voimakas. Jossain määrin kuitenkin laaja-alainen, asiaherkkä, älykäskin sekä sitoutunut',
  'scout.result.aabb':
    ' on suuressa määrin tunneihminen, empaattinen, motivaattori, riskinä manopuloivuus, kriittiselle palautteelle herkkyys. Suuressa määrin myös harkitseva, laaduntekijä, järjen ja tunteen tasapainottaja, riskinä järjen ja tunteen "sekamelska", päättämättömyys, ylikorostunut analyyttisyys. Jossain määrin hän on käytännönläheinenkin, tuloksiakin syntyy konkreettisesti, suora toiminnan henkilö, laaja-alainenkin, asiaherkkä, intuitiivinenkin.',
  'scout.result.bbac':
    ' on jossain määrin tunneihminen, empaattinenkin, kuuntelija, myönteisestä palautteesta pitäväkin, jossain määrin myös laatua aikaansaava, pohtija, pitkäjänteinenkin. Suuressa määrin tuloksentekijä ja nopeakin, käytännönläheinen, riskinä itsekeskeisyys ja liiallinen suoruus,  Hyvin vähäisessä määrin laaja-alainen, asiaherkkä, synteesin tekijä, omaperäinen, luova.',
  'scout.result.baca':
    ' on jossain määrin ihmistä ymmärtävä, empaattinenkin, kuuntelija, muiden tunteita ymmärtävä,  Suuressa määrin harkitseva, pitkäjänteinen, johdonmukainen laaduntekijä, riskinä varovaisuus päätöksenteossa, henkinen etäisyys.  Hyvin vähäisessä määrin käytännönläheinen, tuloksekas, konkreettinen, jalat maassa. Suuressa määrin laaja-alainen, intuitiivinen, asiaherkkä, luova, riskinä henkinen etäisyys ja sisäänpäinsuuntautuneisuus.',
  'scout.result.caba':
    ' on hyvin vähäisessä määrin tunnherkkä, eikä ihmistä oikein ymmärrä, ei kuuntele kehon kieltä.  Suuressa määrin hän on looginen, asiakeskeinen, neuvottelutaitoinen, riskinä päättämättömyys ja varautuneisuuskin. Jossain määrin hän on käytännönläheinen, aikaansaavakin, itsevarmakin, suuressa määrin omaperäinen, luova, vaistonvarainen, asiaherkkä, riskinä henkisesti etäinen, dogmaattisuus',
  'scout.result.bbaa':
    ' on jossain määrin tunneherkkä, ihmisiä ymmärtävä ja empaattinen kuuntelija, jossain määrin myös harkitseva, pitkäjänteinen, vaihtoehtojen vertailija. Hän on suuressa määrin käytännönläheinen, toteuttaja, tuloskeskeinen, noipea, riskinä itsekeskeisyys, toimii ensin, ajattelee sitten, hallitseva. Hän on suuressa määrin laaja-alainen, visionääri, vaistonvarainen, älykäs, riskinä henkinen jäykkyys, monimutkaisuus joissakin ajatusmalleissa.',
  'scout.result.aaab':
    ' on suuressa määrin tunneherkkä ihmisen ymmärtäjä, hyvä kuuntelija, empaattinen, riskinä pitkäjänteisen luovuuden puute, omaperäisyyden vähyys.  Suuressa määrin myös harkitseva järki-ihminen, vaihtoehtojen vertailija, riskinä tunne-järki sekamelska, neuvottelutaidon manipuloimishalukkuus. Suuressa määrin myös käytännönläheinen,  aikaansaava, laadun toteuttaja, riskinä hallitsevuus ja itsekeskeisyys.  Jossain määrin hän on laaja-alainen, luovasti älykäskin, visionäärikin.',
  'scout.result.bcba':
    ' on jossain määrin tunneherkkä, ihmistä ymmärtävä, miksei kohtuullinen kuuntelijakin, vuorovaikutusosaajakin. Hyvin vähäisessä määrin harkitsija, pitkäjänteinen laadun tekijä, neuvottelutaitoinen, looginen, jossain määrin käytännönläheinen, tuloskeskeinenkin, sosiaalinenkin. Hyvin suuressa määrin laaja-alainen, visionääri, älyllisesti luova, omaperäinen, laajojen ongelmien ratkaisija, riskinä haaveilevuus, henkinen jäykkyys, jalat irti maasta oleminen.',
  'scout.result.abac':
    ' on suuressa määrin tunneherkkä ihmisen ymmärtäjä, empaattinen, hyvä kuuntelija ja muiden tunteiden tulkitsija, motivaattorikin, riskinä asioiden lykkääminen, itsesyytökset, kriittiselle palautteelle herkkyys. Jossain määrin hän on harkitseva, neuvottelutaitoinenkin, järki-ihminenkin, viestijä. Suuressa määrin hän on käytännönläheinen, tuloksekas, ripeä otteissaan, itsenäinen, riskinä laadun tekeminen, hallitsevuus, kapea-alaisuus mielipiteissä. Hyvin vähäisessä määrin hän on laaja-alainen, pitkäjänteisen luova, mielikuvitusrikas, laajojen ongelmien ratkaisija.',
  'scout.result.baac':
    ' on jossain määrin tunneherkkä, empaattinenkin ja kuuntelija, motivaattorikin, suuressa määrin harkitseva, pitkäjänteinen,analyyttinen, järki-ihminen, riskinä aikaansaamisen paine sisäisesti. Hän on myös suuressa määrin käytännönl-äheinen, tuloksekas, itsevarma, riskinä liiallinen itsekeskeisyys ja hallitsevuus.  Hyvin vähäisessä määrin laaja-alainen synteesin tekijä, visionääri, luova.',
  'scout.result.aacb':
    ' on suuressa määrin tunneherkkä, empaattinen, toisen ihmisen asemaan asettuva, riskinä tunne ja järjen sekamelskaan juuttuminen, aikaansaamattomuus.  Hän on suuressa määrin harkitseva, pitkäjänteinen, analyyttinen, eri vaihtoehtojen vertailija, riskinä varovaisuus, hitaus päätöksenteossa.  Hyvin vähäisessä määrin hän on aikaansaava, tuloksekas, nopea, hallitseva. Jossain määrin kyllä vaistonvarainen, luovakin, omaperäinenkin.',
  'scout.result.babc':
    ' on jossain määrin tunneherkkä ihmisen ymmärtäjä, kuuntelijakin, "haistaa" ihmisten tunnelman. Suuressa määrin harkitsija, analyyttinen, laaduntekijä, vaihtoehtojen vertailija, riskinä varovaisuus, päättämättömyys.liika lukuihin paneutuminen. Hän on jossain määrin käytännönläheinen, tilanteittain aikaansaavakin, itsenäinen, itseluottamustakin löytyy. Hyvin vähäisessä määrin hän on laaja-alainen, visionääri, luova, älyllisesti sitkeä.',
  'scout.result.aabc':
    ' on suuressa määrin tunneherkkä, ihmisen ymmärtäjä, toisen ihmisen asemaan asettuva, hyvä kuuntelija, riskinä kriittiselle palautteelle herkkyys, asioiden lykkääminen. Hän myös suuressa määrin  harkitseva, analyyttinen, järki-ihminen, laatuun pyrkivä, pitkäjänteinen, riskinä tunteen ja järjen sekamelska, liiallinen varovaisuus, hallitsevuuskin. Jossain määrin käytännönläheinen, aikaansaavakin, jalat tukevasti maassa-tyyppi, sosiaalinen. Hyvin vähäisessä määrin laaja-alainen, visionääri pitkäjänteisesti luova, omaperäinen.',
  'scout.result.cbba':
    ' on hyvin vähäisessä määrin tunneherkkä ihmisten suhteen, empaattinen, vuorovaikutustaitoinen.  Jossain määrin harkitseva, pitkäjänteinenkin, syvällinen, analyyttinen, jossain määrin myös aikaansaava, itsenäinen, hallitseva, asiakeskeinen. Suuressa määrin laaja-alainen, visionääri, pitkäjänteisesti luova, sitoutunut, laajojen ongelmien ratkaisija, riskinä henkinen etäisyys, dogmaattisuus, monimutkaisuus.',
  'scout.result.aaca':
    ' on suuressa määrin tunneherkkä,ihmiusen ymmärtäjä, toisen ihmisen asemaan asettuva, ilmapiirin tunnistaja, riskinä liiallinen tunne- ja järkimaailman sekoittuminen, itsensä syyllistäminen, asioiden lykkääminen, suuressa määrin myös harkitsija, pitkäjänteinen, analyyttinen eri vaihtoehtojen vertailija, riskinä varovaisuus, päättämättömyys, varautuneisuus. Hän on hyvin vähäisessä määrin konkreettinen tekijä, aikaansaava, nopea, itsenäinen. Suuressa määrin laaja-alainen, luova, sitoutunut, omaperäinen, älyllinen visionääri, riskinä henkinen etäisyys, omissa sfääreissä oleminen, dogmaattisuus.',
  'scout.result.abbc':
    ' on suuressa määrin tunneherkkä ihmisten ymmärtäjä, empaattinen kuuntelija, ilmapiirin "haistava", riskinä liiallinen ihmisherkkyys, herkkyys kriittiselle palautteelle, asioiden lykkääminen. Hän on jossain määrin objektiivinen, pitkäjänteinen, neuvottelutaitoinenkin, myös jossain määrin käytännönläheinen, tuloksiakin aikaansaava, itsevarmakin, ripeäkin. Hyvin vähäisessä määrin laaja-alainen, pitkäjänteisesti luova, visionääri, vaistonvarainen.',
  'scout.result.bbab':
    ' on jossain määrin tunneherkkä ihmisten suhteen, ihmisten tunteuden tulkitsija, motivaattorikin, kuuntelija. Jossain määrin myös harkitseva, analyyttinenkin, objektiivinenkin . Suuressa määrin käytännönläheinen suorittaja, aikaansaava, ripeä, itsenäinen, tuloksentekijä, riskinä laadunteko, muiden ihmisten huomioonotto, hallitsevuus, itsepäisyys. Jossain määrin hän on laaja-alainen, luovakin, visonäärikin, omaperäinen.',
  'scout.result.acab':
    ' on suuressa määrin tunneherkkä muiden tunteiden huomioja, motivaattori, henkinen tukija, ilmapiirin "haistaja", riskinä tunnepohjalta tekeminen, järjen vähyys, tunnepohjalta päättäminen. Hyvin vähäisessä määrin hän on harkitsija, pitkäjänteinen, analyyttinen, eri vaihtoehtojen vertailija. Suuressa määrin hän on tekijä, konkreettisuuteen pyrkivä, itsevarma, ripeä, tuloksentekijä, riskinä tunnepohjalta tehdyt päätökset, itsekeskeisyys, luottamus muiden osaamiseen. Jossain määrin hän on laaja-alainen, visionäärikin, sitoutunut, luovakin.',
  'scout.result.bbba':
    ' on jossain määrin tunneherkkä, ihmisten ymmärtäjä, empaattinenkin, ilmapiirin "haistaja", motivaattorikin. Jossain määrin myös harkitsija, pitkänteinenkin, järki-ihminen, erilaisten vaihtoehtojen vertailija, jossain määrin käytännönläheinen, tuloksia aikaansaavakin, suorituskeskeinen.  Suuressa määrin laaja-alainen, visionääri, laajojen ongelmien ratkaisija, älyllisesti sitkeä, riskinä dogmaattisuus, henkinen etäisyys, monimutkaisuus.',
  'scout.result.aaac':
    ' on suuressa määrin tunneherkkä, empaattinen, muiden tunnetilat tunnistava, motivaattori, ilmapiirin "haistelija", riskinä kriittiselle palautteelle herkkyys, asioiden lykkääminen, syyllisyyden tunteet. Myös suuressa määrin harkitsija, looginen, pitkäjänteinen, analyyttinen, riskinää päättämättömyys ja liiallinen pohdinta. Hän on suuressa määrin käytännönläheinen, aikaansaava tunteen ja järjen perusteella, tuloskeskeinen ajattelussaan, riskinä oikeassa olemisen tarve ja hallitsevuus. Hyvin vähäisessä määrin hän on laaja-alainen, asiaherkkä, laajojen ongelmien ratkaisija, synteesin tekijä.',
  'scout.result.cbaa':
    ' on hyvin vähäisessä määrin tunneherkkä, empaattinen, ilmapiiriä "haistava, vuorovaikutustaitoinen, jossain määrin harkitseva, pitkäjänteinen, järki-ihminen, looginenkin. Hän on suuressa määrin käytännönläheinen," jalat maassa", itsevarma, tuloskeskeinen, riskinä itsekeskeisyys, muiden kriittinen arvostelu, oikeassa olemisen tarve. Suuressa määrin myös laaja-alainen, visionääri, vaistonvarainen, luova, riskinä henkinen etäisyys, asiaherkkyys ihmisherkkyyden sijaan.',
  'scout.result.caab':
    ' on hyvin vähäisessä määrin tunneherkkä, ihmisen ymmärtäjä, empaattinen, kuuntelija, ilmapiirin "haistaja". Suuressa määrin hän on harkitsija, järkipainotteinen, looginen, analyyttinen, riskinä liiallinen asiakeskeisyys, tunteettomuus. Suuressa määrin myös käytännönläheinen toteuttaja, itsenäinen, aikaansaava, ripeä otteissaan, riskinä tulos edellä ihminen perässä-ajattelu, tunnevähäisyys. Jossain määrin asiaherkkä, intuitiivinen, visionääri, synteesin tekoon pystyvä, luova.',
  'scout.result.abaa':
    ' on suuressa määrin tunneherkkä ihmisen ymmärtäjä, empaattinen kuuntelija, ilmapiirin "haistaja", motivaattori, riskinä järjen käytön vähäisyys, tunneryöpyt, manipulointitaidot. Jossain määrin hän on harkitseva, looginen, analyyttinen, objektiivinenkin, neuvottelutaitoinen. Hän on suuressa määrin käytännönläheinen tuloksia tekevä, ripeä, sosiaalinenkin, riskinä laadusta tinkiminen, itsekeskeisyys, hallitsevuus. Suuressa määrin myös laaja-alainen, visionääri, asiaherkkä, vaistonvarainen, riskinä omiin sfääreihin nouseminen, dogmaattisuus, monimutkaisuus.',
  'scout.result.acaa':
    ' on suuressa määrin tunneherkkä, ihmistä ymmärtävä, motivaattori, hyvä kuuntelija, empaattinen, riskinä tunteen ylivoima järkeen nähden, subjektiivisuus ja herkkyys kriittiselle palautteelle. Hän hyvin vähäisessä määrin harkitseva ajattelija, analyyttinen, järki-ihminen, looginen. Hän on suuressa määrin käytännönläheinen, nopea, tuloskeskeinen, itsevarma, itseluottamus kohdillaan, riskinä tunteen kautta tekeminen, laatu kärsii, dominoivuus.  Hän on suuressa määrin myös laaja-alainen, luova, visionääri, synteesin tekijä, asiaherkkä, riskinä dogmaattisuus, henkinen etäisyys.',
  'scout.result.abbb':
    ' on suuressa määrin hyvä kuuntelija ja vuorovaikuttaja, hyvä tunnistamaan muiden tunnetilat, motivaattori ja mukaan innostaja. Riskinä manipuloivuus, herkkyys kielteiselle palautteelle, lyhytjänteisyys,liiallinen tunneherkkyys. Hän on jossain määrin asiakeskeinen, kohtuullinen loogikko, objektiivisuutta löytyy, mittareita jossain määrin rakastava, jossain määrin tuloskeskeinen, itsevarma. Myös jossain määrin karismaattinen, sitoutunut, luovuuttakin löytyy.',
  'scout.result.abcb':
    ' on suuressa määrin vuorovaikutustaitaja,ihmisten ymmärtäjä, empaatinen, ilmapiirin tulkitsija, riskinä tunne- ja järkimaailman sekoittuminen, itsensä syyllistäminen, asioiden lykkääminen, jossain määrin myös  asioihin paneutuva, syvällinen, analyyttinenkin. Jossain määrin hän on myös harkitseva, looginen, hyvin vähäisessä määrin käytännön ihminen kuitenkin, enemmänkin teoreettinen, sisäänpäinsuuntautuneempi, ei hallitseva. Hän on jossain määrin laaja-alainen, luovakin, asioita kauempaa katsova, omissa oloissaan viihtyvä.',
  'scout.result.acbb':
    ' on suuressa määrin vuorovaikutustaitaja, ihmisten ymmärtäjä, ilmeiden ja eleiden lukija, motivaattori, riskinä lyhytjänteisyys, syyllistäminen, manipuloivuus. Hän on hyvin vähäisessä määrin harkitseva,  pitkäjänteinen, analyyttinen.  on jossain määrin käytännönläheinen suorittaja, nopeakin toimissaan, jossain määrin myös laaja-alainen, luovakin, idealisti ja tarvittaessa sitoutunutkin.',
  'scout.result.babb':
    ' on jossain määrin tunnevaltainen, empaattinenkin, ilmapiirin "haistaja", vuorovaikutteinen. Hän on suuressa määrin harkitsevainen, analyyttinen, neuvottelutaitoinen, looginenkin, riskinä päättämättömyys, asioihin juuttuja, varautunut. Hän on jossain määrin käytännönläheinen, tuloskeskeinenkin, laaja-alaisuuttakin löytyy jossain määrin, samoin jossain määrin sitoutumista tehtäviin sekä luovuuttakin.',
  'scout.result.bbca':
    ' on jossain määrin tunnevaltainen, ihmistä ymmärtävä, vuorovaikutustaitoinen, empaattinenkin. Hän on jossain määrin harkitseva, pitkäjänteinen loogikkokin sekä neuvottelutaitoinen, hyvin vähäisessä määrin käytännönläheinen ja tuloskeskeinen, ei myöskään kovin nopea toimissaan. Hän on suuressa määrin laaja-alainen, mielikuvitusrikas, laajoja ongelmia ratkova, riskinä kuitenkin henkinen etäisyys ja hieman "jalat irti maasta oleminen".',
  'scout.result.bcaa':
    ' on jossain määrin tunnevaltainen, ihmisten kanssa toimeentuleva, empaattinenkin, spontaanikin. Hän on hyvin vähäisessä määrin harkitseva, enemmänkin subjektiivinen kuin objektiivinen, lyhytjännitteinen.  on suuressa määrin käytännönläheinen suorittaja, nopeakin, aikaansaava toteuttaja, riskinä jopa hosuminen ja pinnallisuus sekä itsekeskeisyys. Hän on suuressa määrin myös laaja-alainen, omaperäinen,vaistonvarainen, riskinä "jalat irti maasta oleminen", dogmaattisuus sekä asioiden levällään oleminen.',
  'scout.result.cabb':
    ' on hyvin vähäisessä määrin tunneherkkä, ei niinkään ihmisten kanssa osaaja, ei myöskään kovin empaattinen. Hän on suuressa määrin asiakeskeinen, sisäänpäinsuuntautuva, pohdiskeleva, neuvottelutaitoinen, riskinä tunteettomuus, liiallinen objektiivisuus sekä päättämättömyys. Hän on jossain määrin käytännönläheinen, itsevarmakin, tuloskeskeinen tilanteittain. Hän on jossain määrin laaja-alainen, luovuuttakin jossain määrin löytyy, tulevaisuuspainotteinen myös.',
  'scout.result.cbab':
    ' on hyvin vähäisessä määrin tunneherkkä ihmisosaaja, ei paras mahdollinen kuuntelija ihmisten huolissa, ei kovin empaattinen. Hän on jossain määrin harkitseva, pohdiskelevakin, laskennallisen järjen omaava. Suuressa määrin hän on käytännönläheinen suorittaja, tekijä, ei kovin pitkäjänteinen toimissaan, sosiaalinenkin, riskinä hosuminen ja päämäärätietoisuus ihmisistä kovasti välittämättä. Hän on jossain määrin laaja-alainen, luovakin, se, mihin ihastuu, siihen myös useasti sitoutuu.',
  'scout.result.aaba':
    ' on suuressa määrin vuorovaikutustaitaja,ihmisten ymmärtäjä, empaattinen, ilmapiirin tulkitsija, riskinä tunne- ja järkimaailman sekoittuminen, itsensä syyllistäminen, asioiden lykkääminen, suuressa määrin myös  asioihin paneutuva, syvällinen, analyyttinen, eri vaihtoehtoja tarkasteleva, riskinä varovaisuus, päättämättömyys, varautuneisuus. Hän on jossain määrin käytännön osaaja, itsevarmakin, sosiaalinen tarvittaessa. Suuressa määrin hän on laaja-alainen, luova, tulevaisuuden näkijä, intuitiivinen, riskinä dogmaattisuus ja haaveellisuus',
  'arvoviisari.question1': 'Yhteisöllisyys',
  'arvoviisari.question2': 'Muutosjoustavuus',
  'arvoviisari.question3': 'Suvaitsevaisuus',
  'arvoviisari.question4': 'Oikeudenmukaisuus',
  'arvoviisari.question5': 'Innovatiivisuus',
  'arvoviisari.question6': 'Oma-aloitteisuus',
  'arvoviisari.question7': 'Vastuullisuus',
  'arvoviisari.question8': 'Kehityshalukkuus',
  'arvoviisari.question9': 'Palkitsevuus',
  'arvoviisari.question10': 'Viestintätaitoisuus',
  'arvoviisari.mostImportant': 'Tärkein',
  'arvoviisari.lessImportant': 'Vähiten tärkein',
  'arvoviisari.explanation': 'Aseta arvot tärkeysjärjestykseen.',
  'dropbox.dragOrganizationLogo': 'Raahaa yrityksen logo tähän',
  'dropbox.dragProfilePicture': 'Raahaa profiilikuvasi tähän',
  'dropbox.dragAttachment': 'Raahaa liitetiedosto tähän',
  'dropbox.dragCV': 'Raahaa CV-tiedosto tähän',
  'dropbox.dropOrganizationLogo': 'Pudota logo tähän',
  'dropbox.dropProfilePicture': 'Pudota kuva tähän',
  'dropbox.dropAttachment': 'Pudota liite tähän',
  'dropbox.dropCV': 'Pudota CV tähän',
  'dropbox.cropInfo': 'Rajaa kuva haluamaksesi',
  'profile.selectedCV': 'Valittu CV',
  'profile.setDefault': 'Aseta oletukseksi',
  'profile.isDefault': 'Valittu oletukseksi',
  'profile.copyCV': 'Luo uusi kopio',
  'profile.cvDescription': 'Kopion kuvaus',
  'profile.copyDialogTitle': 'CV kopio',
  'profile.editTitle': 'Muokkaa CV kopiota',
  'profile.addTitle': 'Luo uusi kopio',
  'profile.cvInfo': 'Kopion tiedot',
  'profile.name': 'Nimi',
  'profile.description': 'Kuvaus',
  'profile.selectTeamMember.placeholder': 'Vaihda tiimiläiseen',
  'profile.historyButton': 'CV:n päivityshistoria',
  'profile.backButton': 'Takaisin edelliselle sivulle',
  'register.title': 'Rekisteröinti',
  'register.email': 'Sähköposti',
  maxDigitsAfterDecimal: 'kenttä sallii 2 desimaalia tai vähemmän',
  'profile.modal.minPrice': 'Minimi hinta €/h',
  'profile.modal.maxPrice': 'Maksimi hinta €/h',
  'profile.modal.remoteWorkAvailable': 'Etätyö on mahdollista',
  'profile.modal.remoteWorkNotAvailable': 'Etätyö ei ole mahdollista',
  'profile.modal.priceInfo':
    'Vain laajoilla "Myynnin" käyttöoikeuksilla näkee laskutustiedot. Oletusoikeuksilla ei näe tai voi muokata laskutustietoja.',
  'profile.modal.priceNetwork': 'Laskutustiedot ovat näkyvillä {{networks}} -verkostoja hallinnoivalle taholle.',
  'resources.searchResults.lastFetched': 'Viimeksi haettu {{time}} sitten',
  'userManual.title': 'Käyttöopas',
  reminderSent: 'Muistutus lähetetty!',
  degrees: 'tutkintoa',
  courses: 'kurssia',
  certificates: 'sertifikaattia',
  'reports.skillsStatistics.header': 'Taitotilastoja',
  'reports.skillTotals': 'Kaikki taidot',
  'reports.skillsStatistics.total': 'Kaikki',
  'reports.skillsStatistics.interest': 'Kiinnostus',
  'reports.skillsStatistics.level': 'Osaamistaso',
  'reports.skillsStatistics.experience': 'Kokemus',
  'reports.skillsStatistics.totals.experience': 'Kokemus',
  'reports.skillsStatistics.totals.interest': 'Kiinnostuksen keskiarvo',
  'reports.skillsStatistics.totals.level': 'Osaamisen keskiarvo',
  'reports.skillsStatistics.totals.projectExperience': 'Projektikokemus',
  'reports.skillsStatistics.totals.userCount': 'Osaajien määrä',
  'reports.skillsStatistics.charts.upToHalfYear': '6 Kuukautta tai vähemmän',
  'reports.skillsStatistics.charts.upToOneYear': 'Vähemmän kuin 1 vuosi',
  'reports.skillsStatistics.charts.upToThreeYears': '1-2 vuotta',
  'reports.skillsStatistics.charts.upToFiveYears': '3-4 vuotta',
  'reports.skillsStatistics.charts.moreThanFiveYears': '5+ vuotta',
  'reports.skillsStatistics.charts.count': 'määrä',
  'reports.skillsStatistics.charts.skillsLimit': 'Näytä kerralla',
  'reports.skillsStatistics.charts.EducationInterest': 'Koulutuksen kiinnostus',
  'reports.skillsStatistics.charts.EducationInterest.averageInterest': 'Keskimääräinen kiinnostus',
  'reports.skillsStatistics.charts.EducationInterest.averageLevel': 'Keskimääräinen osaamistaso',
  'reports.skillsStatistics.charts.EducationInterest.difference': 'Erotus',
  'reports.skillsStatistics.charts.EducationInterest.countInCvs': 'Määrä CV:ssä',
  'reports.skillsStatistics.charts.EducationInterest.description':
    'Taidon keskimääräinen kiinnostus verrattuna keskimääräiseen osaamistasoon. Tällä graafilla voi arvioida koulutustarpeita, esimerkiksi taidon keskimääräinen osaamistaso on alhainen mutta kiinnostus on suuri.',
  'reports.skillStatistics.charts.interest.threeOrNull': 'Normaali kiinnostus (tai ei määritetty)',
  'reports.skillStatistics.charts.level.threeOrNull': 'Todistettu käytännön osaaminen. (tai ei määritetty)',
  'reports.skillsStatistics.charts.demand.averageLevel': 'Keskimääräinen osaamistaso',
  'reports.skillsStatistics.charts.demand.averageRequiredLevel': 'Keskimääräinen vaadittu taso',
  'reports.skillsStatistics.charts.demand.requiredCount': 'Vaadittu määrä',
  'reports.skillsStatistics.charts.demand.foundCount': 'Löytyvä määrä',
  'reports.skillsStatistics.charts.demand': 'Kysyntä',
  'reports.skillsStatistics.charts.demand.description':
    'Viimeisen vuoden toimeksiantojen kysytyt taidot verrattuna valitun tiimin/organisaation (rajaus) taitojen osaamistasoon.',
  'reports.certificateStatistics.users': 'Käyttäjät',
  'reports.certificateStatistics.totals': 'Kaikki',
  'reports.certificateStatistics.nameColumn': 'Käyttäjä',
  'reports.certificateStatistics.certificateColumn': 'Sertifikaatti',
  'reports.certificateStatistics.certifierColumn': 'Myöntäjä',
  'reports.certificateStatistics.issueDate': 'Myönnetty',
  'reports.certificateStatistics.expirationDate': 'Päättyy',
  'reports.certificateStatistics.licenseNumber': 'Lisenssinumero',
  'reports.certificateStatistics.active': 'Aktiivisia',
  'reports.certificateStatistics.expired': 'Päättyneitä',
  'reports.certificateStatistics.userCount': 'Käyttäjämäärä',
  'reports.certificateStatistics.count': 'Määrä',
  'reports.certificateStatistics.organization': 'Organisaatio',
  'reports.statistics.exportXLSX': 'Lataa excelinä',
  'reports.certificateStatistics.exportJSON': 'Lataa jsonina',
  'reports.certificateStatistics.certificates': 'Sertifikaatit',
  'reports.certificateTotals': 'Kaikki sertifikaatit',
  'reports.certificateUsers': 'Käyttäjien sertifikaatit',
  'reports.certificateCount': 'Määrä',
  'reports.certificateCount.title': 'Sertifikaatti määrä',
  'reports.certificateCount.name': 'Nimi',
  'reports.certificateCount.organization': 'Organisaatio',
  'reports.certificateCount.certificateCount': 'Määrä',
  'reports.skillsStatistics.skillType.placeholder': 'Valitse tyyppi',
  'reports.skillsStatistics.skillScope.placeholder': 'Valitse rajaus',
  'reports.skillsStatistics.showUnverified': 'Näytä varmistamaton data',
  'reports.skillsStatistics.type.skills': 'Taidot',
  'reports.skillsStatistics.type.industries': 'Toimialat',
  'reports.skillsStatistics.type.roles': 'Roolit',
  'reports.skillsStatistics.skillsList': 'Taitolista',
  'reports.skillsStatistics.filters': 'Suodattimet',
  'navigation.hitRateReport': 'Hit rate',
  'navigation.suppliersReport': 'Toimittaja',
  'reports.suppliers.name': 'Toimittaja',
  'reports.suppliers.roles': 'Roolit',
  'reports.suppliers.role': 'Rooli',
  'reports.suppliers.personCount': 'Allokoidut',
  'reports.suppliers.openDetails': 'Avaa lisätiedot',
  'reports.suppliers.title': 'Toimittaja',
  'reports.suppliers.details.title': 'Lisätiedot',
  'reports.suppliers.workorder.allocated': 'Allokoidut',
  'reports.suppliers.workorder.leadtime': 'Lead time (työpäiviä)',
  'reports.suppliers.workorder.name': 'Työtilaus',
  'reports.suppliers.workorder.hourlyRate': 'Tuntihinta',
  'reports.suppliers.workorder.duration': 'Kesto (työpäiviä)',
  'reports.suppliers.workorder.allocationPercentage': 'Allokaatio prosentti',
  'reports.suppliers.workorder.workOrderContactPeople': 'Työtilauksen kontaktihenkilö',
  'reports.suppliers.workorder.assignmentContactPeople': 'Toimeksiannon kontaktihenkilö',
  'reports.suppliers.workorder.supplierContactPeople': 'Toimittajan kontaktihenkilö',
  'reports.suppliers.workorder.rating': 'Suorituskykyarvio',
  'reports.suppliers.workOrder.decidedAt': 'Päätetty',
  'reports.suppliers.workorder.roleDescription': 'Roolin kuvaus',
  'reports.suppliers.workorder.startDate': 'Alkupäivä',
  'reports.suppliers.workorder.endDate': 'Päättymispäivä',
  'reports.suppliers.workorder.subcontractor': 'Alihankkija',
  'reports.suppliers.workorder.allocationStatus': 'Allokaation tila',
  'reports.suppliers.workorder.customer': 'Asiakas',
  'reports.suppliers.noData': 'Ei dataa verkostosta',
  'reports.hitRate.title': 'Hit rate',
  'reports.hitRate.role': 'Rooli',
  'reports.hitRate.organization': 'Toimittaja',
  'reports.hitRate.team': 'Tiimi',
  'reports.hitRate.value': 'Hit rate',
  'reports.hitRate.peopleCount': 'Henkilömäärä',
  'reports.hitRate.noData': 'Ei dataa verkostosta',
  'permissionDescriptions.companyAdmin': 'Täydet käyttöoikeudet (Organisaation ylläpitäjä)',
  'permissionDescriptions.companyAdmin.description': 'Hallitsee organisaation jäseniä ja pääsyoikeuksia',
  'permissionDescriptions.companyAdmin.userManagement': 'Hallitsee käyttäjiä',
  'permissionDescriptions.companyAdmin.permissionManagement': 'Hallitsee pääsyoikeuksia',
  'permissionDescriptions.companyAdmin.organizationManagement': 'Hallitsee organisaation asetuksia',
  'permissionDescriptions.companyAdmin.reportAccess': 'Kaikki raportit (PRO)',
  'permissionDescriptions.sales': 'Laajat käyttöoikeudet (Myynti)',
  'permissionDescriptions.sales.description': 'Myynin työkalut ja näkymät',
  'permissionDescriptions.sales.accessSearch': 'Hakutoiminnot',
  'permissionDescriptions.sales.userProfileManagement': 'Hallitsee käyttäjien tietoja',
  'permissionDescriptions.sales.accessOtherCvExport': 'Tulosta käyttäjien CV:t',
  'permissionDescriptions.sales.manageAssignments': 'Hallitsee toimeksiantoja',
  'permissionDescriptions.sales.manageAllocations': 'Hallitsee allokaatioita',
  'permissionDescriptions.sales.accessDashboard': 'Resursoinnin dashboard (PRO)',
  'permissionDescriptions.sales.teamManagement': 'Hallitsee tiimejä (PRO)',
  'permissionDescriptions.sales.contactPerson': 'Ovat organisaation kontaktihenkilöitä',
  'permissionDescriptions.sales.notifications': 'Saavat ilmoituksia verkostojen toimeksiannoista',
  'permissionDescriptions.teamLeader': 'Tiimin muokkausoikeudet (Tiiminvetäjä)',
  'permissionDescriptions.teamLeader.description': 'Tiiminvetäjän oikeudet',
  'permissionDescriptions.teamLeader.manageTeamMembers': 'Hallitsee tiimin jäseniä',
  'permissionDescriptions.teamLeader.userProfileManagement': 'Hallitsee tiimin jäsenten tietoja',
  'permissionDescriptions.teamLeader.accessOtherCvExport': 'Tulosta tiimin jäsenten CV:t',
  'permissionDescriptions.teamLeader.manageAllocations': 'Hallitsee tiimin jäsenten allokaatioita',
  'permissionDescriptions.allUsers': 'Suppeat käyttöoikeudet (Oletus)',
  'permissionDescriptions.allUsers.description': 'Oman profiilin hallinta',
  'permissionDescriptions.allUsers.manageProfile': 'Hallitse omaa CV:tä',
  'permissionDescriptions.allUsers.manageAllocations': 'Hallitse omia allokaatioita',
  'permissionDescriptions.allUsers.manageOwnCvExport': 'Tulosta oma CV',
  'permissionDescriptions.allUsers.accessOwnTeam': 'Näe omat tiimit ja tiimiläiset (PRO)',
  'permissionDescriptions.allUsers.accessCommonReports': 'Perusraportit (PRO)',
  'permissionDescriptions.allUsers.scoutSoft': 'Tutka (Soft-skills) (PRO)',
  'permissionDescriptions.tooltip': 'Näytä oikeuskuvaukset',
  'navigation.notifications': 'Ilmoitukset',
  'notifications.title': 'Ilmoitukset',
  'notifications.toAssignment': 'Toimeksiantoon',
  'notifications.toProfile': 'Profiiliin',
  'notifications.toProposed': 'Tarjouksiin',
  'notifications.toResponse': 'Vastaukseen',
  'notifications.toChat': 'Liity keskusteluun',
  'notifications.toProposal': 'Ehdotukseen',
  'notifications.status': 'Tila',
  'notifications.description': 'Kuvaus',
  'notifications.read': 'Luettu',
  'notifications.unread': 'Lukematon',
  'notifications.readAll': 'Merkitse kaikki luetuksi',
  'notifications.markedAsRead': 'Merkitty luetuksi',
  'admin.title.notifications': 'Ilmoitukset',
  'admin.title.addNotification': 'Lähetä ilmoitus',
  'admin.notifications.accounts': 'Aktivoidut tilit (käytä CTRL valitaksesi useamman)',
  'admin.notifications.message': 'Viesti',
  'admin.notifications.header': 'Otsikko',
  'admin.notifications.allActivated': 'Valitse kaikki',
  noRoleSet: 'Ei valittua roolia',
  'assignmentView.description': 'Kuvaus',
  'assignmentView.attachments.action': 'Lataa',
  'assignmentView.role': 'Rooli',
  'assignmentView.price': 'Hinta',
  'assignmentView.negotiable': 'Neuvoteltavissa',
  'assignmentView.allocation': 'Allokaatio',
  'assignmentView.propose.header': 'Tiedossa sopiva ehdokas rooliin?',
  'assignmentView.propose.header2': 'Ehdota tekijä rooliin Caleossa!',
  'assignmentView.login': 'Kirjaudu sisään',
  'assignmentView.noAccount': 'Rekisteröidy',
  'assignmentView.assignment': 'Toimeksianto',
  'assignmentView.assignmentName': 'Toimeksiannon nimi',
  'assignmentView.contactPerson': 'Yhteyshenkilö',
  'assignmentView.subcontractorAllowed': 'Alihankkijat sallittu',
  'assignmentView.customer': 'Asiakas',
  'assignmentView.location': 'Sijainti',
  'assignmentView.startDate': 'Aloitus päivä',
  'assignmentView.endDate': 'Lopetus päivä',
  'assignmentView.deadline': 'Määräaika',
  'assignmentView.remoteAllowed': 'Etätyö',
  'assignmentView.contactDetails': 'Yhteyshenkilöt',
  'assignmentView.contactName': 'Nimi',
  'assignmentView.telephone': 'Puhelin',
  'assignmentView.attachmentsTitle': 'Liitteet',
  'assignmentView.emptyRoles': 'Näytä tyhjät roolit',
  'allocationinputcomponent.personplaceholder': 'Valitse henkilö',
  'allocation.verified': 'Varmistettu',
  'allocation.tentative': 'Alustava',
  'allocation.otherWork': 'Muu työ',
  'allocation.assignment': 'Toimeksianto',
  'allocation.absence': 'Poissaolo',
  'allocation.free': '0%',
  'allocation.partial': '1-99%',
  'allocation.full': '100%+',
  'allocation.percentage': 'Varaustilanne',
  'allocation.overview.summary': 'Yhteenveto',
  'allocation.overview.edit': 'Muokkaus',
  'allocation.overview.timeScope': 'Aikajänne',
  'allocation.noResults': 'Ei tuloksia valituilla filtereillä.',
  'allocation.noResults.info': 'Valitse rajaus ja filtterit, jotta saat tuloksia.',
  'allocation.dragEditHeader': 'Muokkaa allokaatioita',
  'allocation.dragSubHeader': 'Pika muokkaus',
  'allocation.dragHelp': 'Voit muokata pituutta vetämällä palikan päistä.',
  'allocation.dragNoAllocations': 'No allocations within time scope!',
  'allocation.dragTableHeader': 'Allokaatiot',
  'allocation.endedTooltip': 'Allokaatio loppunut',
  'allocation.closeToEndTooltip': 'Allokaatio lähellä loppua',
  'allocation.assignmentName': 'Toimeksianto',
  'allocation.workorderName': 'Työtilaus',
  'allocation.roleName': 'Rooli',
  'allocation.supplierAllocations': 'Toimittajien allokaatiot',
  'allocation.person': 'Henkilö',
  'allocation.organization': 'Organisaatio',
  oneMonth: '1 kk',
  twoMonths: '2 kk',
  threeMonths: '3 kk',
  fourMonths: '4 kk',
  'network.registerModal.addTitle': 'Rekisteröi',
  'network.registerModal.freelancer': 'Freelancer',
  'network.registerModal.organization': 'Organisaatio',
  'network.registerModal.registerToCaleo': 'Rekisteröi Caleoon',
  'network.registerModal.joining': 'Liityen {{network}}',
  'network.registerModal.name': 'Nimi',
  'network.registerModal.companyIdentifier': 'Y-tunnus',
  'network.registerModal.address': 'Katuosoite',
  'network.registerModal.city': 'Kaupunki',
  'network.registerModal.postalCode': 'Postinumero',
  'network.registerModal.country': 'Maa',
  'network.registerModal.user': 'Käyttäjät',
  'network.registerModal.permissions': 'Oikeudet',
  'network.registerModal.firstName': 'Etunimi',
  'network.registerModal.lastName': 'Sukunimi',
  'network.registerModal.email': 'Sähköposti',
  'network.registerModal.submit': 'Lähetä',
  'network.registerModal.defaultLanguage': 'Käyttöliittymän oletuskieli',
  'network.registerModal.language': 'Käyttöliittymän kieli',
  'network.registerModal.telephone': 'Puhelinnumero',
  'network.registerUser.addTitle': 'Lisää käyttäjä',
  'network.registerUser.firstName': 'Etunimi',
  'network.registerUser.lastName': 'Sukunimi',
  'network.registerUser.telephone': 'Puhelinnumero',
  'network.registerUser.email': 'Sähköposti',
  'network.registerUser.notifications': 'Vastaanottaa ilmoituksia {{network}} verkosta',
  'network.registerUser.permissions': 'Lisä oikeudet',
  'network.registerUser.sales': 'Myynti',
  'network.registerUser.companyAdmin': 'Yrityshallinta',
  'network.registerUser.teamLeader': 'Tiimin vetäjä',
  'network.registerUser.add': 'Lisää',
  'network.registerUser.language': 'Käyttöliittymän kieli',
  'network.registerMember': 'Rekisteröi uusi jäsen',
  'network.organizationMembers': 'Organisaatiojäsenet',
  'network.accountMembers': 'Tilijäsenet',
  'cvHistory.header': 'CV:n päivityshistoria',
  'cvHistory.date': 'Päivämäärä',
  'cvHistory.editor': 'Muokkaaja',
  'cvHistory.section': 'Osio',
  'cvHistory.Employer': 'Työkokemukset',
  'cvHistory.Project': 'Projektit',
  'cvHistory.Education': 'Tutkinnot',
  'cvHistory.Course': 'Kurssit',
  'cvHistory.Certificate': 'Sertifikaatit',
  'cvHistory.skill': 'Taidot',
  'cvHistory.role': 'Roolit',
  'cvHistory.industry': 'Toimialat',
  'cvHistory.LanguageSkill': 'Kieliotaidot',
  'cvHistory.Reference': 'Suositukset',
  'cvHistory.Url': 'URL',
  'cvHistory.About': 'Profiili',
  'cvHistory.Pricing': 'Hinta',
  'cvHistory.Remote': 'Etätyö',
  'cvHistory.parsed': 'PDF parsinta',
  'admin.permissions.groups': 'Ryhmät',
  'admin.permissions.users': 'Käyttäjät',
  'admin.permission.user.firstName': 'Etunimi',
  'admin.permission.user.lastName': 'Sukunimi',
  'admin.permission.user.organization': 'Yritys',
  'admin.permission.editUser': 'Muokkaa käyttäjää',
  'admin.permission.user.edit': 'Muokkaa käyttäjän oikeuksia',
  'admin.permission.addGroup': 'Lisää ryhmä',
  'admin.permission.groupName': 'Ryhmän nimi',
  'admin.permission.groupOrganization': 'Ryhmän yritys',
  'assignment-count': 'Uusien toimeksiantojen määrä',
  'freelancer-count': 'Uusien freelancereiden määrä',
  'organization-count': 'Uusien organisaatioiden määrä',
  skillName: 'Taidon nimi',
  skillCount: 'Taitojen määrä',
  network: 'Verkko',
  startDate: 'Alkupäivä',
  endDate: 'Lopetuspäivä',
  skills: 'Taidot',
  languages: 'Kielet',
  export: 'Tulosta',
  import: 'Lisää trendit',
  'salesPool.title': 'Ehdotetut',
  'salesPool.allocations': 'Allokaatiot',
  'salesPool.itemExists': 'Myyntikori CV on jo valittu!',
  'salesPool.expiresIn': 'Päättyy {{date}} viikon kuluttua',
  'salesPool.anonymous': 'Anonyymi',
  'salesPool.anonymousFileInfo': 'Tiedostot eivät ole anonymisoituja',
  'salesPool.generateLink': 'Luo linkki',
  'salesPool.copyToClipboard': 'Kopioi leikepöydälle',
  'salesPool.itemAdded': 'Käyttäjä lisätty myyntikoriin',
  'salesPool.linkExpired': 'Linkki on vanhentunut!',
  'salesPool.contactCreator': 'Ota yhteyttä linkin tekijään saadaksesi uuden linkin.',
  'salesPool.allocations.legend.full': 'täysin varattu: ei saatavissa',
  'salesPool.allocations.legend.notFull': 'varattu: osittain saatavilla',
  'salesPool.allocations.legend.empty': 'saatavissa',
  'salesPool.allocations.legend.fullTentative': 'Alustava täysin varaus: mahdollisesti saatavissa',
  'salesPool.allocations.legend.notFullTentative': 'Alustava osittain varaus: mahdolisesti saadavissa',
  'salesPool.titleField': 'Otsikko',
  'salesPool.allocationsVisible': 'Näytä allokaatiot',
  'salespools.title': 'Myyntikorit',
  'salespool.table.delete': 'Poista myyntikori',
  'salesPool.itemEditing': 'Muokkaus avattu',
  'salespool.table.edit': 'Muokkaa myyntikoria',
  'salespool.table.copy': 'Kopioi myyntikori',
  'salespool.table.view': 'Katsele ehdotettuja',
  'salespool.table.items': 'Ehdotetut',
  'salespool.table.itemCount': 'Ehdotettujen määrä',
  'salespool.table.allocationsVisible': 'Allokaatiot näkyvissä',
  'salespool.table.anonymous': 'Anonyymi',
  'salespool.table.expiration': 'Umpeutuu',
  'salespool.table.created': 'Luotu',
  'salespool.table.title': 'Nimi',
  'salespool.table.owner': 'Omistaja',
  'salespool.view.title': 'Myyntikorin näkymä',
  'salesPool.updateSalesPool': 'Päivitä myyntikori',
  'salesPool.reset': 'Resetoi',
  'salesPool.itemCopied': 'Myyntikori kopioitu!',
  'salespool.delete.header': 'Haluatko varmasti poistaa myyntikorin?',
  'salespool.delete.confirmation': 'Nimi: {{name}}',
  file: 'Tiedosto',
  selection: 'Valikointi',
  'admin.title.parseCV': 'CV-parsiminen',
  'admin.parse.setup.addTitle': 'Parsimisasetukset',
  'admin.parse.setup.files': 'Lisätyt tiedostot:',
  'admin.parse.setup.organizationId': 'Organisaatio',
  'admin.parse.setup.siteId': 'Toimipiste',
  'admin.parse.setup.send': 'Lähetä',
  'admin.parse.setup.creditsLeft': 'Parsimiskredittejä jäljellä: {{credits}}.',
  'admin.parse.setup.contactSupport': 'Ota yhteyttä tukeen.',
  'admin.parse.setup.maxFiles': 'Tiedostoja maksimissaan per lähetys',
  'admin.parse.name': 'Nimi',
  'admin.parse.email': 'Email',
  'admin.parse.results.header': 'Tulokset',
  'admin.parse.results.save': 'Tallenna',
  'admin.parse.results.activation': 'Lähetä aktivointi sähköpostit',
  'admin.parse.results.networkVisible': 'Verkostonäkyvyys',
  'admin.parse.result.cvData': 'CV:n tiedot',
  'admin.parse.result.firstName': 'Etunimi',
  'admin.parse.result.lastName': 'Sukunimi',
  'admin.parse.result.email': 'Sähköposti',
  'admin.parse.result.info': 'Voit muokata tietoja luodussa profiilissa parsinnan jälkeen.',
  'admin.parse.result.role': 'Rooli',
  'admin.parse.result.about': 'Kuvaus',
  'admin.parse.result.skills': 'Taidot',
  'admin.parse.result.skill': 'Nimi',
  'admin.parse.result.lastUsed': 'Viimeksi käytössä',
  'admin.parse.result.experienceMonths': 'Kokemus',
  'admin.parse.result.workHistory': 'Työkokemus',
  'admin.parse.result.organization': 'Organisaatio',
  'admin.parse.result.startDate': 'Alkupäivä',
  'admin.parse.result.endDate': 'Loppupäivä',
  'admin.parse.result.description': 'Kuvaus',
  'admin.parse.result.education': 'Koulutus',
  'admin.parse.result.school': 'Koulu',
  'admin.parse.result.degree': 'Tutkinto',
  'admin.parse.result.languages': 'Kielitaito',
  'admin.parse.result.language': 'Kieli',
  'admin.parse.result.level': 'Taso',
  'admin.parse.accounts.header': 'Luodut tilit',
  'admin.parse.accounts.errors': 'Virheet',
  'admin.parse.accounts.back': 'Takaisin alkuun',
  'admin.parse.emailWarning': 'Sähköpostiosoite puuttuu tiedoista ',
  'admin.parse.firstNameWarning': 'Etunimi puuttuu tiedoista ',
  'admin.parse.lastNameWarning': 'Sukunimi puuttuu tiedoista',
  'email-error': 'email was already used for account',
  'file-already-added': 'Tiedosto on jo lsätty!',
  'admin.title.myNetworks': 'Verkostot',
  'admin.myNetworks.organization': 'Organisaatio',
  'admin.myNetworks.visiblePeople': 'Näkyvät käyttäjät',
  'admin.myNetworks.hiddenPeople': 'Piilotetut käyttäjät',
  'admin.myNetworks.networks': 'Verkostojäsenyydet',
  'admin.myNetworks.table.name': 'Nimi',
  'admin.myNetworks.table.description': 'Kuvaus',
  'admin.myNetworks.anonymouslySearchable':
    'Kaikki Caleon käyttäjät voivat etsiä sinun piiloittamattoman käyttäjän anonymisoidut tiedot. Katso anonyymit tulokset haku sivulta.',
  'admin.myNetworks.notAnonymouslySearchable':
    'Verkostomanagerien organisaatiot voi etsiä ja näkee sinun piiloittamattoman käyttäjän tiedot. Allokaatioiden tiedot on piilotettu.',
  'admin.myNetworks.organizationData': 'Organisaation tiedot',
  'profile.language.add': 'Lisää kieli',
  'profile.cvDelete': 'Poista tämä CV',
  'proposals.search': 'Hae tarjouksia',
  'proposals.search.showUnverified': 'Näytä varmistamattomat taidot',
  'proposals.search.networks': 'Haun kohdennus',
  'proposals.search.roleName': 'Roolin nimi',
  'proposals.search.startDate': 'Alkupäivä',
  'proposals.search.endDate': 'Loppupäivä',
  'proposals.search.clear': 'Tyhjennä',
  'proposals.search.search': 'Hae',
  'proposals.search.proposer': 'Tarjoaja',
  'proposals.search.createAt': 'Luotu',
  'proposals.search.requirements': 'Roolin vaatimukset',
  'proposals.search.description': 'Roolin kuvaus',
  'proposals.search.openAssignment': 'Toimeksiantoon',
  'proposals.search.proposalDetails': 'Tarjouksen tiedot',
  'proposals.search.additionalInfo': 'Lisätietoja',
  'assignments.search': 'Hae tarjouksia',
  'navigation.releases': 'Julkaisutiedotteet',
  'releaseNotes.title': 'Julkaisutiedotteet',
  'releaseNotes.version': 'Versio',
  'releaseNotes.noNotes': 'Ei julkaisutiedotteita',
  'admin.releaseNotes': 'Julkaisutiedotteet',
  'admin.releaseNotes.addTitle': 'Lisää julkaisutiedote',
  'admin.releaseNotes.editTitle': 'Muokkaa julkaisutiedotetta',
  'admin.releaseNotes.title': 'Otsikko',
  'admin.releaseNotes.version': 'Versio',
  'admin.releaseNotes.createdAt': 'Luotu',
  'admin.releaseNotes.note': 'Tiedote',
  'admin.releaseNotes.delete.confirmationText': 'Poistetaanko {{name}} julkaisutiedote?',
  'admin.title.systemConfigs': 'Jäjestelmäkonfiguraatiot',
  'admin.systemConfigs.key': 'Avain',
  'admin.systemConfigs.value': 'Arvo',
  'admin.systemConfigs.changer': 'Muokkaaja',
  'admin.systemConfigs.addConfig': 'Lisää konfiguraatio',
  'admin.systemConfigs.addTitle': 'Lisää konfiguraatio',
  'admin.systemConfigs.editConfig': 'Muokkaa konfiguraatiota',
  'admin.systemConfigs.editTitle': 'Muokkaa konfiguraatiota',
  'admin.systemConfigs.removeConfig': 'Poista konfiguraatio',
  'admin.title.cacheManagement': 'Välimuistihallinta',
  'admin.cacheManagement': 'Välimuistihallinta',
  'admin.cache.type': 'Tyyppi',
  'admin.cache.deleteByType': 'Poista {{type}} välimuisti',
  'admin.cache.deleteAll': 'Poista kaikki',
  'admin.cache.typeDeleted': 'Välimuistityyppi poistettu',
  'admin.cache.allDeleted': 'Kaikki välimuistit poistettu',
  'search.quickSearch': 'Pikahaku',
  'search.detailedSearch': 'Laaja haku',
  'search.from': 'Mistä',
  'search.keywords': 'Voit käyttää haussa useita hakusanoja.',
  'search.submit': 'Hae',
  'search.skill.name': 'Taito',
  'search.role.name': 'Rooli',
  'search.industry.name': 'Toimiala',
  'search.name': 'Nimi',
  'search.reset': 'Tyhjennä valinnat',
  'search.results': 'Tulokset',
  'search.lastFetched': 'Viimeksi haettu {{time}} sitten',
  'search.notFound': 'Etkö löytänyt etsimääsi?',
  'search.notFoundDescription': 'Pyydä ilmainen ehdokaslista!',
  'search.sendRequest': 'Lahetä pyyntö',
  'search.fullProfile': 'Täyteen profiiliin',
  'search.exactMatch': 'Täydellinen osuma',
  'search.criteria': 'Taito kriteerit',
  'search.language.criteria': 'Kielitaito kriteerit',
  'search.experience': 'Kokemus',
  'search.level': 'Taso',
  'search.requiredProjects': 'Tarvitaan projekteissa',
  'search.years': 'Vuotta',
  'search.unspecified': 'Määrittämätön',
  'search.projects': 'Projektia',
  'search.save': 'Tallenna',
  'search.remove': 'Poista',
  'search.proposeAssignment': 'Ehdota toimeksiantoa',
  'search.location': 'Paikkakunta',
  'search.availability': 'Saatavuus',
  'search.certificate': 'Sertifikaatti',
  'search.degree': 'Tutkinto',
  'search.course': 'Kurssi',
  'search.language': 'Kieli',
  'search.resultsCount': 'Tulokset {{from}}-{{to}}, {{of}} tuloksesta',
  workorder: 'Työtilaus',
  assignment: 'Toimeksianto',
  'navigation.workorders': 'Työtilaukset',
  'workorders.overview': 'Työtilaukset',
  'workorder.modal.addTitle': 'Uusi työtilaus',
  'workorder.modal.roles': 'Roolit',
  'workorder.modal.generalTitle': 'Yleinen',
  'workorder.modal.customerTitle': 'Asiakas',
  'workorder.modal.rolesTitle': 'Roolit',
  'workorder.modal.attachmentsTitle': 'Liitteet',
  'workorder.modal.editTitle': 'Muokkaa työtilausta',
  'workorder.modal.shareTitle': 'Kopioi ja jaa työtilaus',
  'workorder.modal.copyTitle': 'Kopioi työtilaus',
  'workorder.modal.share': 'Jaa verkostoihin',
  'workorder.modal.copy.save': 'Luo uusi työtilaus',
  'workorder.modal.share.save': 'Jaa ja tallenna',
  'workorder.modal.customerId': 'Asiakas',
  'workorder.modal.copy': 'Kopioi',
  'workorder.share.broker': 'Työtilaus jaetaan ja julkaistaan verkoistoihin.',
  'workorder.share.info': 'Työtilauksen jako odottaa verkoston ylläpitäjän hyväksyntää.',
  'workorder.internal': 'Sisäiset',
  'workorder.external': 'Ulkoiset',
  'workorder.shared': 'Jaetut',
  'workorder.outdated': 'Vanhentuneet',
  'workorder.favorite': 'Suosikit',
  'workorder.contactPeople': 'Filteröi kontaktihenkilön mukaan',
  'workorder.filterBySkill': 'Filteröi taitojen mukaan',
  'workorder.notifySelector': 'Valitse käyttäjät, joille ilmoitetaan',
  'workorder.internal.tooltip': 'Organisaation omistamat työtilaukset, joita ei ole jaettu.',
  'workorder.external.tooltip': 'Organisaatiolle verkon kautta jaetut työtilaukset.',
  'workorder.shared.tooltip': 'Organisaation omistamat jaetut työtilaukset.',
  'workorder.outdated.tooltip': 'Työtilaukset joiden määräaika on umpeutunut.',
  'workorder.favorite.tooltip': 'Työtilaukset jotka on asetettu suosikeiksi.',
  'workorder.description': 'Työtilauksen kuvaus',
  'workorder.autoRejectEmail': '(Automaattisesti sulkee tarjoukset ja lähettää sulkemisilmoitukset)',
  'workorder.modal.view': 'Katso työtilausta',
  'workorder.modal.viewPropose': 'Katsele ja tarjoa työtilaukseen',
  'workorder.modal.next': 'Seuraava',
  'workorder.modal.save': 'Tallenna',
  'workorder.modal.name': 'Työtilauksen nimi',
  'workorder.modal.startDate': 'Alku',
  'workorder.modal.endDate': 'Loppu',
  'workorder.modal.workloadEstimate': 'Työmäärä-arvio (htp)',
  'workorder.modal.country': 'Maa',
  'workorder.modal.city': 'Kaupunki',
  'workorder.modal.subcontractorAllowed': 'Alihankkijat sallittu',
  'workorder.modal.remoteAllowed': 'Etätyö sallittu',
  'workorder.modal.deadline': 'Määräaika',
  'workorder.modal.description': 'Kuvaus',
  'workorder.modal.customer': 'Asiakas',
  'workorder.modal.customerContactId': 'Yhteyshenkilö',
  'workorder.modal.additionalInfo': 'Lisätietoja',
  'workorder.modal.attachments.description': 'Kuvaus',
  'workorder.modal.location': 'Sijainti',
  'workorder.modal.attachments.upload-action': 'Lähetä',
  'workorder.modal.attachments.download-action': 'Lataa',
  'workorder.modal.roles.hourlyPrice': 'Tuntihinta',
  'workorder.modal.roles.allocation': 'Allokaatio',
  'workorder.modal.roles.createCopy': 'Luo uusi rooli kopiosta',
  'workorder.modal.personInCharge': 'Vastuuhenkilö',
  'workorder.modal.contactPeople': 'Yhteyshenkilöt',
  'workorder.modal.contactName': 'Nimi',
  'workorder.modal.publicView': 'Nähtävissä ilman kirjautumista',
  'workorder.modal.contactPersonId': 'Yhteyshenkilö',
  'workorder.modal.notifyTo': 'Lähetä sähköposti ilmotus verkkoihin',
  'workorder.modal.publicUrl': 'Julkinen URL',
  'workorder.modal.copyToClipboard': 'Kopioi leikepöydälle',
  'workorder.modal.lastChangedBy': 'Tilan viimeksi muokannut: {{name}}',
  'workorder.modal.statusBySystem': 'Järjestelmä',
  'workorder.modal.template': 'Malli',
  'workorder.modal.templateName': 'Mallin nimi',
  'workorder.modal.templateCreate': 'Luo',
  'workorder.modal.templateUpdate': 'Päivitä',
  'workorder.modal.fileProposalAllowed': 'Tiedostolla tarjoaminen sallittu',
  'workorder.modal.basicInfo': 'Perus tiedot',
  'workorder.modal.details': 'Lisätiedot',
  'workorder.modal.sharingOptions': 'Jako valinnat',
  'workorder.modal.moreDetails': 'Kuvaus',
  'workorder.modal.attachments': 'Liitteet',
  'workorder.modal.useTemplate': 'Käytä mallia',
  'workorder.modal.helperTextContactPerson': 'Aseta kontaktihenkilöt jako valinnoissa.',
  'workorder.modal.status': 'Tila',
  'workorder.modal.info': 'Tiedot',
  'workorder.modal.contactInfo': 'Yhteystiedot',
  'workorder.modal.people': 'Allokoidut',
  'workorder.table.name': 'Nimi',
  'workorder.table.customer': 'Asiakas',
  'workorder.table.status': 'Tila',
  'workorder.table.created': 'Vastaanotetaan tarjouksia',
  'workorder.table.inProgress': 'Tarjous käynnissä',
  'workorder.table.resolved': 'Päättynyt',
  'workorder.table.roles': 'Roolit',
  'workorder.table.inCharge': 'Vastuussa',
  'workorder.table.startDate': 'Alku',
  'workorder.table.endDate': 'Loppu',
  'workorder.table.attachments': 'Liitteet',
  'workorder.table.deadline': 'Määräaika',
  'workorder.table.roleTooltip.header': 'Hae hakuehdoilla',
  'workorder.table.roleTooltip.location': 'Sijainti',
  'workorder.table.roleTooltip.skills': 'Taidot',
  'workorder.table.createdAt': 'Luotu',
  'workorder.roles.table.name': 'Roolin nimi',
  'workorder.roles.add': 'Lisää rooli',
  'workorder.roles.applyChanges': 'Lisää muutokset',
  'workorder.roles.table.allocationPercentage': 'Allokaatio %',
  'workorder.roles.table.negotiable': 'Neuvoteltavissa',
  'workorder.roles.table.hourlyPrice': 'Tuntihinta €/h',
  'workorder.roles.table.skills': 'Taidot',
  'workorder.roles.table.languages': 'Kielitaidot',
  'workorder.roles.table.description': 'Kuvaus',
  'workorder.roles.table.assignedPeople': 'Allokoidut',
  'workorder.roles.table.assignedPeople.name': 'Nimi',
  'workorder.roles.table.assignedPeople.allocation': 'Allokaatio',
  'workorder.roles.table.assignedPeople.state': 'Tila',
  'workorder.roles.table.assignedPeople.startDate': 'Alkupäivä',
  'workorder.roles.table.assignedPeople.endDate': 'Loppupäivä',
  'workorder.roles.allocations': 'Roolin allokaatiot',
  'workorder.roles.allocation.helperText':
    'Roolia ei voida poistaa, koska sillä on olemassa olevia allokaatioita. Muokataksesi tai poistaaksesi roolin, poista siihen liittyvät allokaatiot.',
  'workorder.skill.name': 'Roolin nimi',
  'workorder.skill.allocationPercentage': 'Allokaatio %',
  'workorder.skill.hourlyPrice': 'Tuntihinta €/h',
  'workorder.skill.description': 'Kuvaus',
  'workorder.skill.editTitle': 'Roolin muokkaus',
  'workorder.skill.addTitle': 'Lisää rooli',
  'workorder.skill.role.view': 'Roolin tiedot',
  'workorder.role.requirement.skill.skillId': 'Taito',
  'workorder.role.requirement.skill.level': 'Taitotaso',
  'workorder.role.requirement.skill.experienceMonths': 'Kokemus',
  'workorder.role.requirement.industry.skillId': 'Toimiala',
  'workorder.role.requirement.industry.level': 'Taitotaso',
  'workorder.role.requirement.industry.experienceMonths': 'Kokemus',
  'workorder.role.requirement.role.skillId': 'Rooli',
  'workorder.role.requirement.role.level': 'Taitotaso',
  'workorder.role.requirement.role.experienceMonths': 'Kokemus',
  'workorder.role.requirement.language.languageCode': 'Kieli',
  'workorder.role.requirement.language.level': 'Taitotaso',
  'workorder.role.allocation.verified': 'Allokaatio vahvistettu',
  'workorder.role.allocation.multipleVerified': 'Monta vahvistettua tai alustavaa allokaatiota',
  'workorder.role.allocation.tentative': 'Monta alustavaa allokaatiota',
  'workorder.role.allocation.deleteConfirm': 'Haluatko varmasti poistaa allokaation?',
  'workorder.role.description': 'Roolin kuvaus',
  'workorder.propose.openProfile': 'Avaa profiili',
  'workorder.propose.noCandidates': 'Harmin paikka, Ei yhtään sopivaa kandidaattia.',
  'workorder.propose.noCandidates.description': 'Kandidaattien tulee olla saatavilla ja vaatimusten tulee täyttyä.',
  'workorder.propose.noCandidates.backButton': 'Takaisin työtilaukseen',
  'workorder.propose': 'Tarjoa kandidaatteja',
  'workorder.propose.proposed': 'Tarjotut',
  'workorder.propose.confirmed': 'Hyväksytty',
  'workorder.propose.addTitle': 'Luo tarjous',
  'workorder.propose.role': 'Rooli',
  'workorder.propose.criteria': 'Haku kriteerit',
  'workorder.propose.location': 'Paikka',
  'workorder.propose.remoteAllowed': 'Etätyö sallittu',
  'workorder.propose.start': 'Aloitus päivä',
  'workorder.propose.end': 'Lopetus päivä',
  'workorder.propose.allocationPercent': 'Allokointi prosentti',
  'workorder.propose.additionalInformation': 'Lisätiedot',
  'workorder.propose.minPrice': 'Minimi hinta €/h',
  'workorder.propose.maxPrice': 'Tavoitehinta €/h',
  'workorder.propose.added': 'Lisätty tarjoukseen',
  'workorder.propose.add': 'Lisää tarjoukseen',
  'workorder.propose.remove': 'Poista tarjouksesta',
  'workorder.propose.save': 'Lähetä tarjous',
  'workorder.propose.update': 'Päivitä tarjous',
  'workorder.propose.roleCandidates': 'Roolien kandidaatit',
  'workorder.propose.role.note.createdAt': 'Viimeksi päivittänyt: {{date}} ',
  'workorder.propose.role.note.showVersion': 'Näytä versio',
  'workorder.propose.role.note.versionHistory': 'Versiohistoria',
  'workorder.propose.role.note.version': 'Versio {{number}}',
  'workorder.propose.candidates': 'Näytä kandidaatit',
  'workorder.propose.role.header': 'Tarjoukset',
  'workorder.propose.role.requirements': 'Vaatimukset',
  'workorder.propose.role.location': 'Sijainti',
  'workorder.propose.role.remote': 'Etätyö sallittu',
  'workorder.propose.role.startDate': 'Alkupäivämäärä',
  'workorder.propose.role.endDate': 'Loppupäivämäärä',
  'workorder.propose.role.allocation': 'Allokaatio',
  'workorder.propose.role.downloadCV': 'Lataa CV',
  'workorder.propose.role.pricing': 'Hinta',
  'workorder.propose.role.match': 'Sopivuus',
  'workorder.propose.role.price': 'Tuntihinta',
  'workorder.propose.role.no-contact': 'Organisaatiolla ei ole kontaktihenkilöä ja tarjouksen tekijä on poistettu!',
  'workorder.propose.role.questions': 'Kysymykset ({{yes}}/{{count}})',
  'workorder.propose.role.status': 'Tila',
  'workorder.propose.role.createdAt': 'Luotu',
  'workorder.propose.role.additionalInfo': 'Lisätiedot',
  'workorder.propose.role.noEndDate': 'Ei loppua',
  'workorder.propose.role.contactPerson': 'Yhteyshenkilö',
  'workorder.propose.role.organization': 'Organisaatio',
  'workorder.propose.role.email': 'Sähköposti',
  'workorder.propose.role.telephone': 'Puhelin',
  'workorder.propose.price': 'Tuntihinta',
  'workorder.propose.bestOffer': 'paras tarjous',
  'workorder.propose.info':
    'tarjotuille käyttäjille luodaan alustava allokaatio työtilauksen perusteella. Työtilaaja on yhteydessä tilanteen edetessä.',
  'workorder.propose.downloadAttachment': 'Lataa liite',
  'workorder.propose.freelancerCopy': 'Lähetä tarjouksen kopio sähköpostiini',
  'workorder.propose.sendCopiesTo': 'Lähetä tarjouksen kopio',
  'workorder.proposed.proposeToOwn': 'Tarjoa toimittajan puolesta',
  'workorder.propose.available': 'Näytä saatavilla olevat kandidaatit',
  'workorder.propose.showAll': 'Näytä kaikki',
  'workorder.propose.filterName': 'Hae nimellä',
  'workorder.propose.status.proposed': 'Tarjottu',
  'workorder.propose.status.accepted': 'Hyväksytty',
  'workorder.propose.status.rejected': 'Hylätty',
  'workorder.propose.status.closed': 'Suljettu',
  'workorder.propose.reject': 'Hylkää',
  'workorder.propose.rejectConfirm': 'Hylkää tarjous',
  'workorder.propose.rejectMessage': 'Hylkäys viesti',
  'workorder.propose.assignmentDescription': 'Työtilauksen kuvaus',
  'workorder.propose.roleDescription': 'Roolin kuvaus',
  'workorder.propose.assingmentResolved': 'Työtilaus haku on päättynyt, tarjoaminen suljettu!',
  'workorder.propose.yesLabel': 'Hylkää',
  'workorder.propose.noLabel': 'Peruuta',
  'workorder.propose.details': 'Työtilauksen tiedot',
  'workorder.propose.roles': 'Roolit',
  'workorder.propose.role.note': 'Muistio',
  'workorder.attachments.name': 'Nimi',
  'workorder.attachments.size': 'Koko',
  'workorder.attachments.description': 'Kuvaus',
  'workorder.view.tabs.list': 'Lista',
  'workorder.view.tabs.people': 'Allokoidut',
  'workorders.proposals': 'tarjoukset',
  'workorders.proposals.outdated': 'Näytä vanhentuneet',
  'workorders.proposals.viewProposal': 'Katsele tarjousta',
  'workorders.proposals.openWorkOrder': 'Avaa työtilaus',
  'workorders.proposals.viewHeader': 'Tarjouksen tiedot',
  'workorders.proposals.pricing': 'Hinta',
  'workorders.proposals.additionalInfo': 'Lisätiedot',
  'workorders.proposals.noInfo': 'Ei lisätietoja',
  'workorders.proposals.edit': 'Muokkaa tarjousta',
  'workorders.proposals.joinChat': 'Liity chattiin',
  'proposals.table.workorder': 'Työtilaus',
  'workorders.title': 'Työtilaukset',
  'workorder.modal.AssignmentVisibilities': 'Jaa toimittajaverkostoille',
  'workorderView.description': 'Kuvaus',
  'workorderView.attachments.action': 'Lataa',
  'workorderView.role': 'Rooli',
  'workorderView.price': 'Hinta',
  'workorderView.negotiable': 'Neuvoteltavissa',
  'workorderView.allocation': 'Allokaatio',
  'workorderView.propose.header': 'Tiedossa sopiva ehdokas rooliin?',
  'workorderView.propose.header2': 'Ehdota tekijä rooliin Caleossa!',
  'workorderView.login': 'Kirjaudu sisään',
  'workorderView.noAccount': 'Rekisteröidy',
  'workorderView.workorder': 'Työtilaus',
  'workorderView.workorderName': 'Työtilauksen nimi',
  'workorderView.contactPerson': 'Yhteyshenkilö',
  'workorderView.subcontractorAllowed': 'Alihankkijat sallittu',
  'workorderView.customer': 'Asiakas',
  'workorderView.location': 'Sijainti',
  'workorderView.startDate': 'Aloitus päivä',
  'workorderView.endDate': 'Lopetus päivä',
  'workorderView.deadline': 'Määräaika',
  'workorderView.remoteAllowed': 'Etätyö',
  'workorderView.contactDetails': 'Yhteyshenkilöt',
  'workorderView.contactName': 'Nimi',
  'workorderView.telephone': 'Puhelin',
  'workorderView.attachmentsTitle': 'Liitteet',
  'workorderView.emptyRoles': 'Näytä tyhjät roolit',
  'proposals.search.openWorkOrder': 'Työtilaukseen',
  'workorders.search': 'Hae tarjouksia',
  'workorder.propose.workorderDescription': 'Työtilauksen kuvaus',
  'workorder.workOrders.assignment': 'Työtilaus',
  'workorderView.assignmentName': 'Työtilauksen nimi',
}

const fi = Object.freeze(fiValues)

export default fi
